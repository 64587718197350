import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'jspdf-autotable';
import './Paymentdetails.css';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';


export default function Payments() {
  const [data, setData] = useState([]);
  const [dataeslip, setDataeslip] = useState([]);
  const [genarateDate, setGenarateDate] = useState(''); 


  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/payments',{
          headers: {
            Authorization: token,
          }
          });
           setData(response.data);
         } catch (error) {
            console.log(error);
          }
        };
        fetchPayments();
      }, []);
      useEffect(() => {
        const fetchPayments = async () => {
          try {
            const token =Cookies.get('token');
            const response = await axios.get('https://backhris.talentfort.live/employeepayments/ereport',{
              headers: {
                Authorization: token,
              }
              });
              setDataeslip(response.data);
             } catch (error) {
                console.log(error);
              }
            };
            fetchPayments();
          }, []);

  
  const handleClose = async () => {
    try {
      const token =Cookies.get('token');
      
      if (!genarateDate ) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Closed Date" before Closing.');
        return;
      }
      const confirmed = window.confirm('Are you sure you want to close selected payment data?');
      if (confirmed) {
        await axios.post('https://backhris.talentfort.live/send-emails', {
          payments : dataeslip,
          headers: {
            Authorization: token,
          },
        });

        // Send a single DELETE request with selected payment data
        await axios.put('https://backhris.talentfort.live/payments/close', {
          generateDate: genarateDate,
          payments: data,
          headers: {
            Authorization: token,
          }
        });

        alert('Payment data closed successfully.');

        // Send a DELETE request to delete the payment data first
      await axios.delete('https://backhris.talentfort.live/all/allowance', {
        headers: {
          Authorization: token,
        }
      });
        window.location.href = '/paymentreport';
      } else {
        alert('Payment data not closed.');
      }
    } catch (error) {
      console.error('Error closiing payment data:', error);
    }
  };

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Payment Details Close</h1>
        <div className="row">
        <div className="col-lg-3 mb-2">
  <label htmlFor="genarateDate" className="form-label">
    Close Date
  </label>
  <input
    type="date"
    className="form-control"
    id="genarateDate"
    name="genarateDate"
    onChange={(e) => setGenarateDate(e.target.value)} 
  />
</div>
</div>
        <div>
        <table className="table">
            <thead>
              <tr>
                <th>Emp No</th>
                <th>Epf No</th>
                <th>Emp Name</th>
                <th>Basic Salary</th>
                <th>Bud. Allowance</th>
                <th>Consolidate Salary</th>
                <th>Nopay Rate</th>
                <th>Nopay Hours</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Gross Salary</th>
                <th>Total Allowance</th>
                <th>Total Earning</th>
                <th>Total Deduction</th>
                <th>Net Salary</th>
                <th>Bank Code</th>
                <th>Branch Code</th>
                <th>Account No</th>
              </tr>
            </thead>
            <tbody>
              {data.map((payment) => (
                <tr key={payment.empNo}>
                  <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.fullName}</td>
                  <td className="right-aligned">{payment.salary}</td>
                  <td className="right-aligned">{payment.budAllowance}</td>
                  <td className="right-aligned">{payment.consolidateSalary}</td>
                  <td className="right-aligned">{payment.nopayRate}</td>
                  <td className="right-aligned">{payment.nopay}</td>
                  <td className="right-aligned">{payment.nopayAmount}</td>
                  <td className="right-aligned">{payment.salaryArrears}</td>
                  <td className="right-aligned">{payment.grossAmount}</td>
                  <td className="right-aligned">{payment.totalAllowance}</td>
                  <td className="right-aligned">{payment.totalEarning}</td>
                  <td className="right-aligned">{payment.totalDeduction}</td>
                  <td className="right-aligned">{payment.netSalary}</td>
                  <td className="right-aligned">{payment.bankCode}</td>
                  <td className="right-aligned">{payment.branchCode}</td>
                  <td className="right-aligned">{payment.accountNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      
        <div className='buttonspaymentclose'> 
            <button className='closebutton' onClick={handleClose}>Close </button>
          </div>
        </div>
      </div>

  );
}