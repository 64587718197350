import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Addemployee.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';

export default function AddEmployee() {
  const [designationNames, setDesignationNames] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [nicExistsError, setNicExistsError] = useState(false);
  const [epfNoExistsError, setEpfNoExistsError] = useState(false);
  const [employee, setEmployee] = useState({});
  const [errorMessages, setErrorMessages] = useState([]);

  const checkNicExists = async (nic) => {
    try {
      const token = Cookies.get('token');
      const response = await axios.get(`https://backhris.talentfort.live/nic?nic=${nic}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data.exists; // Assuming your server sends { exists: true } or { exists: false }
    } catch (error) {
      return false; // Assume NIC does not exist in case of an error
    }
  };

  const checkEpfNoExists = async (epfNo) => {
    try {
      const token = Cookies.get('token');
      const response = await axios.get(`https://backhris.talentfort.live/epfNo?epfNo=${epfNo}`, {
        headers: {
          Authorization: token,
        },
      });
      return response.data.exists; // Assuming your server sends { exists: true } or { exists: false }
    } catch (error) {
      return false; // Assume EPF No does not exist in case of an error
    }
  };

  const handleChange = async (e) => {
    const { name, value, type } = e.target;

    if (name === 'nic') {
      setNicExistsError(false);
    }
    if (name === 'epfNo') {
      setEpfNoExistsError(false);
    }

    if (type === 'radio') {
      setEmployee((prev) => ({ ...prev, [name]: value }));
    } else {
      if (name === 'salary' || name === 'payGrade') {
        const salary = parseFloat(employee.salary) || 0;
        const payGrade = parseFloat(employee.payGrade) || 0;
        const grossSalary = salary + payGrade;
        setEmployee((prev) => ({ ...prev, [name]: value, grossSalary }));
      } else {
        setEmployee((prev) => ({ ...prev, [name]: value }));
      }
    }

    if (name === 'nic') {
      const nic = value?.trim();
      if (nic && nic !== '') {
        const exists = await checkNicExists(nic);
        if (exists) {
          setNicExistsError(true);
        }
      }
    }

    if (name === 'epfNo') {
      const epfNo = value?.trim();
      if (epfNo && epfNo !== '') {
        const exists = await checkEpfNoExists(epfNo);
        if (exists) {
          setEpfNoExistsError(true);
        }
      }
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();

    // List of required fields
    const requiredFields = [
      'fullName', 'title', 'displayName', 'dob', 'gender', 'nic', 'phone',
      'currentAddress', 'empNo', 'epfNo', 'designation', 'employeeType', 'joinedDate',
      'salary', 'contractstartDate', 'contractendDate', 'workLocation', 'payGrade'
    ];

    // Check if any required field is missing
    const missingFields = requiredFields.filter(field => !employee[field]);

    if (missingFields.length > 0) {
      setErrorMessages(missingFields);
      return;
    }

    try {
      const token = Cookies.get('token');
      await axios.post('https://backhris.talentfort.live/employees', employee, {
        headers: {
          Authorization: token,
        },
      });
      alert('Employee added successfully.');
      window.location.href = '/employee';
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchDesignations = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/designations', {
          headers: {
            Authorization: token,
          },
        });
        const designations = response.data;
        const designationsNames = designations.map(designation => designation.desName);
        setDesignationNames(designationsNames);
      } catch (error) {
        console.error('Error fetching Designations:', error);
      }
    };
    fetchDesignations();
  }, []);

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/companies', {
          headers: {
            Authorization: token,
          },
        });
        const companies = response.data;
        const companyNames = companies.map(company => company.workName);
        setCompanyNames(companyNames);
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    };
    fetchCompanies();
  }, []);

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className="addemployee">
        <h1>Add Employee</h1>
        <form>
          {/* Personal Details */}
          <div className='personal_details col-lg-10'>
            <h3>Personal Details</h3>
            <div className='row'>
              <div className="col-lg-12">
                <label htmlFor="fullName" className="form-label">Full Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="fullName"
                  name="fullName"
                  placeholder="Enter FullName"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className='row'>
              <div className="col-lg-6 mb-1">
                <label htmlFor="title" className="form-label">Title</label>
                <select
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Title</option>
                  <option value="Mr">Mr.</option>
                  <option value="Ms">Ms.</option>
                  <option value="Mrs">Mrs.</option>
                  <option value="Miss">Miss.</option>
                </select>
              </div>
              <div className="col-lg-6">
                <label htmlFor="displayName" className="form-label">Display Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="displayName"
                  name="displayName"
                  placeholder="Display Name in reports"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="dob" className="form-label">Date of Birth</label>
                <input
                  type="date"
                  className="form-control"
                  id="dob"
                  name="dob"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">Gender</label>
                <div className='gender_cat'>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="gender1"
                      name="gender"
                      value="M"
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="gender1">Male</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="gender2"
                      name="gender"
                      value="F"
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="gender2">Female</label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-1'>
              <div className='col-lg-6'>
                <label htmlFor='nic' className='form-label'>NIC Number</label>
                <input
                  type='text'
                  className={`form-control ${nicExistsError ? 'is-invalid' : ''}`}
                  id='nic'
                  name='nic'
                  placeholder='NIC Number'
                  onChange={handleChange}
                  required
                />
                {nicExistsError && (
                  <div className='invalid-feedback'>NIC Number already exists.</div>
                )}
              </div>
              <div className="col-lg-6">
                <label htmlFor="phone" className="form-label">Contact Number</label>
                <input
                  type="tel"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="(77) 123-4567"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="email" className="form-label">Email</label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  placeholder="example@gmail.com"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="col-lg-12 mb-1">
              <label htmlFor="currentAddress" className="form-label">Current Address</label>
              <textarea
                className="form-control"
                id="currentAddress"
                name="currentAddress"
                placeholder="Enter your permanent address"
                onChange={handleChange}
                required
              ></textarea>
            </div>
          </div>

          {/* Company Details */}
          <div className='company_details col-lg-10'>
            <h3>Company Details</h3>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="empNo" className="form-label">Emp Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="empNo"
                  name="empNo"
                  placeholder="####00000"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className='col-lg-6'>
                <label htmlFor='epfNo' className='form-label'>Epf No</label>
                <input
                  type='text'
                  className={`form-control ${epfNoExistsError ? 'is-invalid' : ''}`}
                  id="epfNo"
                  name="epfNo"
                  placeholder="00000"
                  onChange={handleChange}
                  required
                />
                {epfNoExistsError && (
                  <div className='invalid-feedback'>EpfNo already exists.</div>
                )}
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="designation" className="form-label">Designation</label>
                <select
                  className="form-control"
                  id="designation"
                  name="designation"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select Designation</option>
                  {designationNames.map((designationName, index) => (
                    <option key={index} value={designationName}>
                      {designationName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <label className="form-label">Employee Type</label>
                <div className="employeetype_cat">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="employeeType1"
                      name="employeeType"
                      value="Permanent"
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="employeeType1">Permanent</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      id="employeeType2"
                      name="employeeType"
                      value="Contract"
                      onChange={handleChange}
                      required
                    />
                    <label className="form-check-label" htmlFor="employeeType2">Contract</label>
                  </div>
                </div>
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6 mb-1">
                <label htmlFor="joinedDate" className="form-label">Joined Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="joinedDate"
                  name="joinedDate"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="salary" className="form-label">Basic Salary</label>
                <input
                  type="text"
                  className="form-control"
                  id="salary"
                  name="salary"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6 mb-1">
                <label htmlFor="contractstartDate" className="form-label">Contract Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="contractstartDate"
                  name="contractstartDate"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-6 mb-1">
                <label htmlFor="contractendDate" className="form-label">Contract End Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="contractendDate"
                  name="contractendDate"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-lg-6">
                <label htmlFor="workLocation" className="form-label">Work Location</label>
                <select
                  className="form-control"
                  id="workLocation"
                  name="workLocation"
                  onChange={handleChange}
                  required
                >
                  <option value="">Select work location</option>
                  {companyNames.map((companyName, index) => (
                    <option key={index} value={companyName}>
                      {companyName}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-6">
                <label htmlFor="location" className="form-label">Location</label>
                <input
                  type="text"
                  className="form-control"
                  id="location"
                  name="location"
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-lg-6">
                <label className="form-label">Bud. Allowance</label>
                <input
                  type="text"
                  className="form-control"
                  id="payGrade"
                  name="payGrade"
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="col-lg-6">
                <label className="form-label">Fix. Allowance</label>
                <input
                  type="text"
                  className="form-control"
                  id="fixAllowance"
                  name="fixAllowance"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Bank Details */}
          <div className='bank_details col-lg-10'>
            <h3>Bank Details</h3>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="accountNo" className="form-label">Account No</label>
                <input
                  type="text"
                  className="form-control"
                  id="accountNo"
                  name="accountNo"
                  placeholder="12-digit account number"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="bankName" className="form-label">Bank Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankName"
                  name="bankName"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="bankCode" className="form-label">Bank Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="bankCode"
                  name="bankCode"
                  placeholder="4-digit bank code"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='row mb-1'>
              <div className="col-lg-6">
                <label htmlFor="branchName" className="form-label">Branch Name</label>
                <input
                  type="text"
                  className="form-control"
                  id="branchName"
                  name="branchName"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-6">
                <label htmlFor="branchCode" className="form-label">Branch Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="branchCode"
                  name="branchCode"
                  placeholder="3-digit branch code"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          {/* Dependent Details */}
          <div className='dependant_details col-lg-10'>
            <h3>Dependent Details</h3>
            <div className='dependant_details'>
              <h4>Dependent 1:</h4>
              <div className='row'>
                <div className="col-lg-12">
                  <label htmlFor="dep_fullName" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_fullName"
                    name="dep_fullName"
                    placeholder="Enter Dep_FullName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_name" className="form-label">Name with Initials</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_name"
                    name="dep_name"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="relationship" className="form-label">Relationship</label>
                  <input
                    type="text"
                    className="form-control"
                    id="relationship"
                    name="relationship"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_nic" className="form-label">NIC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_nic"
                    name="dep_nic"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="dep_dob" className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="dep_dob"
                    name="dep_dob"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-1">
                <label htmlFor="dep_currentAddress" className="form-label">Current Address</label>
                <textarea
                  className="form-control"
                  id="dep_currentAddress"
                  name="dep_currentAddress"
                  placeholder="Enter dependent permanent address"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className='dependant_details'>
              <h4>Dependent 2:</h4>
              <div className='row'>
                <div className="col-lg-12">
                  <label htmlFor="dep_fullName1" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_fullName1"
                    name="dep_fullName1"
                    placeholder="Enter Dep_FullName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_name1" className="form-label">Name with Initials</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_name1"
                    name="dep_name1"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="relationship1" className="form-label">Relationship</label>
                  <input
                    type="text"
                    className="form-control"
                    id="relationship1"
                    name="relationship1"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_nic1" className="form-label">NIC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_nic1"
                    name="dep_nic1"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="dep_dob1" className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="dep_dob1"
                    name="dep_dob1"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-1">
                <label htmlFor="dep_currentAddress1" className="form-label">Current Address</label>
                <textarea
                  className="form-control"
                  id="dep_currentAddress1"
                  name="dep_currentAddress1"
                  placeholder="Enter dependent permanent address"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className='dependant_details'>
              <h4>Dependent 3:</h4>
              <div className='row'>
                <div className="col-lg-12">
                  <label htmlFor="dep_fullName2" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_fullName2"
                    name="dep_fullName2"
                    placeholder="Enter Dep_FullName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_name2" className="form-label">Name with Initials</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_name2"
                    name="dep_name2"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="relationship2" className="form-label">Relationship</label>
                  <input
                    type="text"
                    className="form-control"
                    id="relationship2"
                    name="relationship2"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_nic2" className="form-label">NIC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_nic2"
                    name="dep_nic2"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="dep_dob2" className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="dep_dob2"
                    name="dep_dob2"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-1">
                <label htmlFor="dep_currentAddress2" className="form-label">Current Address</label>
                <textarea
                  className="form-control"
                  id="dep_currentAddress2"
                  name="dep_currentAddress2"
                  placeholder="Enter dependent permanent address"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
            <div className='dependant_details'>
              <h4>Dependent 4:</h4>
              <div className='row'>
                <div className="col-lg-12">
                  <label htmlFor="dep_fullName3" className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_fullName3"
                    name="dep_fullName3"
                    placeholder="Enter Dep_FullName"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_name3" className="form-label">Name with Initials</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_name3"
                    name="dep_name3"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="relationship3" className="form-label">Relationship</label>
                  <input
                    type="text"
                    className="form-control"
                    id="relationship3"
                    name="relationship3"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className='row mb-1'>
                <div className="col-lg-6">
                  <label htmlFor="dep_nic3" className="form-label">NIC</label>
                  <input
                    type="text"
                    className="form-control"
                    id="dep_nic3"
                    name="dep_nic3"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-lg-6">
                  <label htmlFor="dep_dob3" className="form-label">Date of Birth</label>
                  <input
                    type="date"
                    className="form-control"
                    id="dep_dob3"
                    name="dep_dob3"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-lg-12 mb-1">
                <label htmlFor="dep_currentAddress3" className="form-label">Current Address</label>
                <textarea
                  className="form-control"
                  id="dep_currentAddress3"
                  name="dep_currentAddress3"
                  placeholder="Enter dependent permanent address"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          {errorMessages.length > 0 && (
            <div className="error-messages">
              <ul>
                {errorMessages.map((field, index) => (
                  <li key={index}>The field {field} is required.</li>
                ))}
              </ul>
            </div>
          )}
          <div className="add_buttons">
            <button type="submit" className="save-button" onClick={handleClick}>
              Save
            </button>
            <button type="reset" className="reset-button">
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
