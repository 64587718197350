import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Employeepaymentreport.css';
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';

export default function Employeepaymentreport() {
  const [searchResults, setSearchResults] = useState([]);
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token = Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employeepayments/report', {
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const empnoset = new Set();
        const monthSet = new Set();
        const yearSet = new Set();
        const activeSet = new Set();

        res.data.forEach((payment) => {
          empnoset.add(payment.empNo);
          monthSet.add(payment.uploadMonth);
          yearSet.add(payment.uploadYear);
          activeSet.add(payment.paymentType);
        });

        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          if (!isNaN(a) && !isNaN(b)) {
            return a - b;
          }
          return a.localeCompare(b);
        });

        setEmpnos(
          sortedEmpnos.map((empno) => ({ value: empno, label: empno }))
        );

        const monthsOrder = [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ];
        setMonths(
          Array.from(monthSet).sort(
            (a, b) => monthsOrder.indexOf(a) - monthsOrder.indexOf(b)
          )
        );
        setYears(Array.from(yearSet));
        setActive(Array.from(activeSet));
      } catch (error) {
        console.log(error);
      }
    };
    fetchPayments();
  }, []);

  const handleFilter = async () => {
    try {
      const token = Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/employeepayments/report', {
        headers: {
          Authorization: token,
        },
      });
      const payments = res.data;

      let filteredResults = payments;

      if (selectedEmployeeNo) {
        filteredResults = filteredResults.filter(
          (payment) => payment.empNo === selectedEmployeeNo
        );
      }

      if (selectedMonth) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadMonth === selectedMonth
        );
      }

      if (selectedYear) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadYear === selectedYear
        );
      }
      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (payment) => payment.paymentType === selectedActive
        );
      }

      setSearchResults(filteredResults);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEmployeeNoFilter = (selectedOption) => {
    setSelectedEmployeeNo(selectedOption ? selectedOption.value : '');
  };

  const handleMonthFilter = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearFilter = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };
  
const GeneratePDF = () => {
  const PDF = new jsPDF('landscape', 'px', [400, 220]);
  PDF.setFontSize(7);

  try {
    const filteredResults = searchResults.filter((payment) => payment.paymentType !== 'Intermediate');
    filteredResults.forEach((payment, index) => {
      const start = 10;
      const startX = 15;
      const startY = 25;
      const lineHeight = 8;
      const label1X = startX + 15;
      const label2X = startX + 25;
      const valueX = 100;
      const value1X = 140;
      const totalHeight =  25 * lineHeight;
      const uploadMonth = payment.uploadMonth;
      const monthname = uploadMonth;
      const uploadYear = payment.uploadYear // Use 'short' to get the abbreviated month name
      const yearMonthText = `${monthname}  ${uploadYear}`;
      

      if (index !== 0) {
        PDF.addPage(); // Add a new page for each payslip, except the first one
      }
      PDF.rect(start, startY - 15, 380, totalHeight);
      PDF.setFontSize(16);
      PDF.setFont('Arial');
      PDF.text('Employee Payslip', start + 145, startY -3);
      PDF.setFontSize(8);
      PDF.setFont('Helvetica'); 
      
      
      PDF.text(`Payment Month`, startX + 12 , startY + 1.8 * lineHeight);
      PDF.text(`${yearMonthText}`, valueX + 30, startY + 1.8 * lineHeight);
      PDF.text(`Employee Number`, startX + 12 , startY + 3 * lineHeight );
      PDF.text(`${payment.empNo}`, valueX + 30, startY + 3 * lineHeight);
      PDF.text(`Employee NIC`, startX + 12 , startY + 4.2 * lineHeight);
      PDF.text(`${payment.nic}`, valueX + 30, startY + 4.2 * lineHeight);

      PDF.text(`Designation`, startX +190 , startY + 1.8 * lineHeight);
      PDF.text(`${payment.designation}`, valueX +185 , startY + 1.8* lineHeight);
      PDF.text(`Employee Name`, startX +190, startY + 3 * lineHeight);
      PDF.text(`${payment.fullName}`, valueX +185, startY + 3 * lineHeight);
      PDF.text(`Work Location`, startX +190 , startY + 4.2 * lineHeight);
      PDF.text(`${payment.workLocation}`, valueX +185 , startY + 4.2 * lineHeight);

// Draw horizontal lines
PDF.setLineWidth(0.25);
PDF.line(startX + 5, startY + 2, startX + 360, startY + 2 ); // Above details
PDF.line(startX + 5, startY + 5* lineHeight, startX + 360, startY + 5 * lineHeight); // Below detail
PDF.setFontSize(10);
PDF.text(`Earnings`, label1X - 4, startY + 6.5 * lineHeight);
PDF.setFontSize(8);
      PDF.text(`Basic salary `, label1X, startY + 8 * lineHeight);
      PDF.text(`(+ ${payment.salary})`, value1X + 20, startY + 8 * lineHeight, { align: 'right' });
      PDF.text(`Bud Allowance `, label1X, startY + 9 * lineHeight);
      PDF.text(`(+ ${payment.budAllowance})`, value1X + 20, startY + 9 * lineHeight, { align: 'right' });
      PDF.text(`Nopay Amount `, label1X, startY + 10 * lineHeight);
      PDF.text(`(- ${payment.nopayAmount})`, value1X + 20, startY + 10 * lineHeight, { align: 'right' });
      PDF.text(`Salary Areears `, label1X, startY + 11 * lineHeight);
      PDF.text(`(+ ${payment.salaryArrears})`, value1X + 20, startY + 11 * lineHeight, { align: 'right' });
      PDF.text(`Gross Salary `, label1X, startY + 12 * lineHeight);
      PDF.text(` ${payment.grossAmount}`, value1X + 20, startY + 12 * lineHeight, { align: 'right' });

      PDF.text(`Additions`, label1X, startY + 13.5 * lineHeight);
      PDF.setFontSize(7);
      PDF.text(`OT Allowance`, label2X, startY + 14.5 * lineHeight);
      PDF.text(`${payment.overtimeAllowance}`, value1X + 20, startY + 14.5 * lineHeight, { align: 'right' });
      PDF.text(`DOT Allowance`, label2X, startY + 15.5 * lineHeight);
      PDF.text(`${payment.dotAllowance}`, value1X + 20, startY + 15.5 * lineHeight, { align: 'right', fontSize: 10 });
      PDF.text(`Performance Allowance`, label2X, startY + 16.5 * lineHeight);
      PDF.text(`${payment.totalPerformanceAllowance}`, value1X + 20, startY + 16.5 * lineHeight, { align: 'right' });
      PDF.text(`Sales Commission`, label2X, startY + 17.5 * lineHeight);
      PDF.text(`${payment.salesCommission}`, value1X + 20, startY + 17.5 * lineHeight, { align: 'right' });
      PDF.text(`Other Allowance`, label2X, startY + 18.5 * lineHeight);
      PDF.text(`${payment.totalotherAllowance}`, value1X + 20, startY + 18.5 * lineHeight, { align: 'right' });
      PDF.text(`Total Allowance`, label2X, startY + 19.5 * lineHeight);
      PDF.text(`(+ ${payment.totalAllowance})`, value1X + 20, startY + 19.5 * lineHeight, { align: 'right' });
      PDF.setFontSize(9);
      PDF.setFont('Helvetica-Bold');
      PDF.text(`Total Earning`, label1X, startY + 21.5 * lineHeight);
      PDF.text(`${payment.totalEarning}`, value1X + 20, startY + 21.5 * lineHeight, { align: 'right', fontWeight: 'bold'});
      PDF.setFontSize(10);
      PDF.setFont('Helvetica'); 
      PDF.text(`Deductions`, label1X + 175, startY + 6.5 * lineHeight);
      PDF.setFontSize(8);
      PDF.text(`Advance Deduction`, label2X + 169, startY + 8 * lineHeight);
      PDF.text(`${payment.advanceDeduction}`, value1X + 189, startY + 8 * lineHeight, { align: 'right' });
      PDF.text(`EPF(8%)`, label2X + 169, startY + 9 * lineHeight);
      PDF.text(`${payment.epf8}`, value1X + 189, startY + 9 * lineHeight, { align: 'right' });
      PDF.text(`Stamp Duty`, label2X + 169, startY + 10 * lineHeight);
      PDF.text(`${payment.stampDuty}`, value1X + 189, startY + 10 * lineHeight, { align: 'right' });
      PDF.text(`Tax`, label2X + 169, startY + 11 * lineHeight);
      PDF.text(`${payment.payeetax}`, value1X + 189, startY + 11 * lineHeight, { align: 'right' });
      PDF.setFontSize(9);
      PDF.setFont('Helvetica-Bold');
      PDF.text(`Total Deduction`, label2X + 169, startY + 17.5 * lineHeight);
      PDF.text(`( - ${payment.totalDeduction})`, value1X + 189, startY + 17.5 * lineHeight, { align: 'right' });

      PDF.text(`Net Paid`, label1X + 176, startY + 19 * lineHeight);
      PDF.text(`${payment.netSalary}`, value1X + 189, startY + 19 * lineHeight, { align: 'right' });

      // Add double line under Net Paid value
PDF.setLineWidth(0.75);
PDF.line(value1X + 64, startY + 19.25 * lineHeight, value1X + 189 , startY + 19.25 * lineHeight);
PDF.line(value1X + 64, startY + 19.45 * lineHeight, value1X + 189 , startY + 19.45 * lineHeight);


// Add single line under Deductions value
PDF.setLineWidth(0.75);
PDF.line(value1X  + 64, startY + 18 * lineHeight, value1X + 189, startY + 18 * lineHeight);
PDF.setFontSize(8);
PDF.setFont('Helvetica'); 
PDF.text(`EPF (12%)`, label2X + 169, startY + 20.5* lineHeight);
PDF.text(`${payment.epf12}`, value1X + 189, startY + 20.5* lineHeight, { align: 'right' });
PDF.text(`ETF`, label2X + 169, startY + 21.5 * lineHeight);
PDF.text(`${payment.etf3}`, value1X + 189, startY + 21.5 * lineHeight, { align: 'right' });

      PDF.save(`${payment.fullName}_${yearMonthText}_Payment_Report.pdf`);
    });

    

  } catch (error) {
    console.error('Error generating pay slip:', error);
  }
};


  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className='report'>
        <h1>Employee Monthly Payment Report</h1>
        <div className='filter_section'>
          <div className='row mb-3'>
            <div className='col-md-4'>
              <label htmlFor='employeeNoFilter'>Employee No:</label>
              <Select
                id='empNo'
                value={empnos.find(
                  (option) => option.value === selectedEmployeeNo
                )}
                options={empnos}
                isClearable
                onChange={handleEmployeeNoFilter}
                placeholder='Select or type Employee No'
                isSearchable
              />
            </div>
            <div className="col-md-4">
              <div className="month-filter">
                <label htmlFor="monthFilter">Filter by Month:</label>
                
                <select
                  id="monthFilter"
                  value={selectedMonth}
                  className="form-select"
                  onChange={handleMonthFilter}
                >
                  <option value="">All</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="year-filter">
                <label htmlFor="yearFilter">Filter by Year:</label>
                <select
                  id="yearFilter"
                  value={selectedYear}
                  className="form-select"
                  onChange={handleYearFilter}
                >
                  <option value="">All</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
              </div>
          </div>
          <div className="row mb-3">
          <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((activestatus) => (
                  <option key={activestatus} value={activestatus}>
                    {activestatus}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <button className="filter" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>
        <div>
        <table className="table">
            <thead>
              <tr>
              <th>Emp No</th>
              <th>Epf No</th>
                <th>Emp Name</th>
                <th>Basic Salary</th>
                <th>Bud. Allowance</th>
                <th>Consolidate Salary</th>
                <th>Nopay Rate</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Gross Salary</th>
                <th>OT Allowance</th>
                <th>DOT Allowance</th>
                <th>Performance Allowance</th>
                <th>sales Commission</th>
                <th>Other Allowance</th>
                <th>Total Allowance</th>
                <th>Total Earning</th>
                <th>Total Deduction</th>
                <th>Net Salary</th>
                <th>Bank Code</th>
                <th>Branch Code</th>
                <th>Account No</th>
                
              </tr>
            </thead>
            <tbody>
              {searchResults.map((payment) => (
                payment.paymentType !== 'Intermediate' && (
                <tr key={payment.id}>
                 <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.fullName}</td>
                  <td className="right-aligned">{payment.salary}</td>
                  <td className="right-aligned">{payment.budAllowance}</td>
                  <td className="right-aligned">{payment.consolidateSalary}</td>
                  <td className="right-aligned">{payment.nopayRate}</td>
                  <td className="right-aligned">{payment.nopayAmount}</td>
                  <td className="right-aligned">{payment.salaryArrears}</td>
                  <td className="right-aligned">{payment.grossAmount}</td>
                  <td className="right-aligned">{payment.overtimeAllowance}</td>
                  <td className="right-aligned">{payment.dotAllowance}</td>
                  <td className="right-aligned">{payment.totalPerformanceAllowance}</td>
                  <td className="right-aligned">{payment.salesCommission}</td>
                  <td className="right-aligned">{payment.totalotherAllowance}</td>
                  <td className="right-aligned">{payment.totalAllowance}</td>
                  <td className="right-aligned">{payment.totalEarning}</td>
                  <td className="right-aligned">{payment.totalDeduction}</td>
                  <td className="right-aligned">{payment.netSalary}</td>
                  <td className="right-aligned">{payment.bankCode}</td>
                  <td className="right-aligned">{payment.branchCode}</td>
                  <td className="right-aligned">{payment.accountNo}</td>
                
                </tr>
              )))}
            </tbody>
          </table>
        </div>
        <div className='reposemployeereport'>

<div>
  <button className='pdf' onClick={GeneratePDF}>Generate PDF</button>
  </div>
     </div>
      </div>
    
    </div>
  );
}
