import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Upload.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';


export default function Uploadterminatecsv() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    const fetchTerminateDetails = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/terminate/details',{
          headers: {
            Authorization: token,
          },
        });
        setData(response.data);
      } catch (error) {
        console.error('Error fetching terminate details:', error);
      }
    };

    fetchTerminateDetails();
  }, []);

  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        const companyNamesSet = new Set();

        res.data.forEach((payment) => {
          companyNamesSet.add(payment.workLocation);
        });

        setCompanyNames(Array.from(companyNamesSet));
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyNames();
  }, []);

  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
    setSuccessMessage(null);
  };

  const handleUpload = async () => {
    setError(null);
    setSuccessMessage(null);

    if (!file) {
      window.alert('Please select a CSV file to upload.');
      return;
    }

    if (!selectedCompany) {
      window.alert('Please select a Work Location.');
      return;
    }

    const confirmUpload = window.confirm('Are you sure you want to upload this file?');

    if (!confirmUpload) {
      setFile(null);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('csvFile', file);
      formData.append('workLocation', selectedCompany); // Include the selected work location
      const token =Cookies.get('token');
      const response = await axios.post(
        'https://backhris.talentfort.live/upload/terminatedata',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',Authorization: token
          },
        }
      );

      if (response.data.error === 'Data for this month and year already exists.') {
        window.alert('Data for this month and year already exists.');
      } else {
        setFile(null);
        window.alert('CSV file uploaded successfully.');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      window.alert('Error uploading file. Please try again later.');
    }
  };
  const handleClear = async () =>{
    const confirmDelete = window.confirm('Are you sure you want to clear this data?');
    if (confirmDelete) {
      try {
        const token =Cookies.get('token');
        await axios.delete(`https://backhris.talentfort.live/terminate/allowance`,{
          headers: {
            Authorization: token,
          },
        });
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="main-body">
      <div>
        <Sidebar />
      </div>

      <div className="uploadcsv">
        <div className="top">
          <h1>Upload Details (Terminate Inprogress)</h1>
          <div className="row mb-1">
            <div className="col-lg-4">
              <label htmlFor="uploadDate" className="form-label">
                Upload CSV
              </label>
              <input
                id="file-input"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="form-control"
              />

              {error && <div className="alert">{error}</div>}
              {successMessage && <div className="alert">{successMessage}</div>}
            </div>
            <div className="col-lg-4">
              <label htmlFor="uploadDate" className="form-label">
                Work Location
              </label>
              <select
                id="companyFilter"
                value={selectedCompany}
                className="form-select"
                onChange={handleCompanyFilter}
              >
                <option value="">All</option>
                {companyNames.map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>

            <div className="col-lg-1">
              <button onClick={handleUpload} className="uploadbutton1">
                Upload
              </button>
              </div>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Emp No</th>
                <th>Working Days</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>MotivationAllowance</th>
        <th>OvertimeAllowance</th>
        <th>DotArrears</th>
        <th>ExtraworkingPayment</th>
        <th>ActinAllowance</th>
        <th>TransportAllowance</th>
        <th>PerformanceAllowance</th>
        <th>SalesCommission</th>
        <th>CoveringupAllowance</th>
        <th>OvertimeArrears</th>
        <th>DotAllowance</th>
        <th>Advance</th>
        <th>AdvanceDeduction</th>
        <th>BDCommission</th>
        <th>Payeetax</th>
        <th>ExperienceAllowance</th>
        <th>FestivalAdvance</th>
        <th>AdminAllowance</th>
        <th>MobileAllowance</th>
        <th>Loan</th>
        <th>OtherAllowance</th>
        <th>FestivaladvanceDeduction</th>
        <th>LoanDeduction</th>
        <th>OtherDeduction</th>
        <th>SalesIncentive</th>
        <th>SalesdriveIncentive1</th>
        <th>SalesdriveIncentive2</th>
        <th>SalesdriveIncentive3</th>
        <th>Welfare</th>
        <th>WelfareDeduction</th>
        <th>MobileDeduction</th>
        <th>FuelAllowance</th>
        <th>MngSupervisorAllowance</th>
        <th>SupervisorCommission</th>
        <th>SalaCommission</th>
        <th>KlmCommission</th>
        <th>MobitelCommission</th>
        <th>PeoTvRemoteCommission</th>
        <th>MemoryTaxCommission</th>
        <th>GroomingTrainingDeduction</th>
        <th>AjCommission</th>
        <th>cpeArrearsCollectingCommission</th>
        <th>CpeCollectingCommission</th>
        <th>CommissionArrears</th>
        <th>StampDutyplus</th>
        <th>PreviousTax</th>       
<th>otherReimbursement</th>
<th>goyaPayment</th>
<th>internAllowance</th>
<th>internallowanceArrears</th>
<th>otherreimbursementArrears</th>
<th>salescommissionArrears</th>
<th>foodAllowance</th>
<th>specialAllowance</th>
<th>transportallowanceArrears</th>
<th>foodArrears</th>
<th>nightAlllowance</th>
<th>dataAllowance</th>
<th>performanceAllowance2</th>
<th>attendenceAllowance</th>
<th>holidayPayment</th>
<th>transportAllowance2</th>
<th>fullbataAllowance</th>
<th>Worklocation</th>
              </tr>
            </thead>
            <tbody>
              {data.map((allowance) => (
                <tr className="Dts" key={allowance.id}>
                  <td>{allowance.empNo}</td>
                  <td  style={{ textAlign: 'right' }}>{allowance.workingDays}</td>
                  <td  style={{ textAlign: 'right' }}>{allowance.nopayAmount}</td>
                  <td  style={{ textAlign: 'right' }}>{allowance.salaryArrears}</td>
                  <td  style={{ textAlign: 'right' }}>{allowance.motivationAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.overtimeAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.dotArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.extraworkingPayment}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.actinAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.transportAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.performanceAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salesCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.coveringupAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.overtimeArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.dotAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.advance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.advanceDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.bdCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.payeetax}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.experienceAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.festivalAdvance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.adminAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.mobileAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.loan}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.otherAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.festivaladvanceDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.loanDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.otherDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salesIncentive}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salesdriveIncentive1}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salesdriveIncentive2}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salesdriveIncentive3}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.welfare}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.welfareDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.mobileDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.fuelAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.mngSupervisorAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.supervisorCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salaCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.klmCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.mobitelCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.peoTvRemoteCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.memoryTaxCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.groomingTrainingDeduction}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.ajCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.cpeArrearsCollectingCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.cpeCollectingCommission}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.commissionArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.stampDutyplus}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.previousTax}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.otherReimbursement}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.goyaPayment}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.internAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.internallowanceArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.otherreimbursementArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.salescommissionArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.foodAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.specialAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.transportallowanceArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.foodArrears}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.nightAlllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.dataAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.performanceAllowance2}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.attendenceAllowance}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.holidayPayment}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.transportAllowance2}</td>
        <td  style={{ textAlign: 'right' }}>{allowance.fullbataAllowance}</td>
                  <td>{allowance.workLocation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="col-lg-1">
            <button className='clearbutton' onClick={handleClear}>Clear</button>
            </div>
      </div>
    </div>
  );
}
