import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './Updateworklocation.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';


export default function UpdateWorklocation() {
  const [worklocationData, setWorklocationData] = useState({
    workCode: '',
    workName: '',
    workDescription: '',
  });

  const location = useLocation();

  const worklocationId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchDesignation = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get(`https://backhris.talentfort.live/worklocations/${worklocationId}`,{
          headers: {
            Authorization: token,
          },
        });
        const { workCode, workName, workDescription } = response.data;
        setWorklocationData({
        workCode,
          workName,
          workDescription,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchDesignation();
  }, [worklocationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setWorklocationData((prevworklocationData) => ({
      ...prevworklocationData,
      [name]: value,
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token =Cookies.get('token');
      await axios.put(`https://backhris.talentfort.live/worklocations/${worklocationId}`, worklocationData,{
        headers: {
          Authorization: token,
        },
      });
      alert('Work Location updated successfully.');
      // Navigate to the previous page or handle redirection as needed
      window.history.back();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className="updatedesignations">
        <h1>Update Work Location</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="workCode" className="form-label">
            Work Location Code
            </label>
            <input
              type='text'
              className="form-control"
              id="workCode"
              name="workCode"
              value={worklocationData.workCode}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workName" className="form-label">
            Work Location Name
            </label>
            <input
              type="text"
              className="form-control"
              id="workName"
              name="workName"
              value={worklocationData.workName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workDescription" className="form-label">
            Work Location Description
            </label>
            <input
              type="text"
              className="form-control"
              id="workDescription"
              name="workDescription"
              value={worklocationData.workDescription}
              onChange={handleChange}
            />
          </div>

          <div className="buttonsupdatedesignation">
            <button
              type="submit"
              className="btn btn-primary update-button"
              onClick={handleClick}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
