
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Bankslip.css'
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';


export default function Payments() {
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [csvDataText, setCSVDataText] = useState('');
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [allowanceNames, setAllowanceNames] = useState([]);
  const [selectedAllowance, setSelectedAllowance] = useState('');


  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/payments/report',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const monthSet = new Set();
        const yearSet = new Set();
        const companyNames = new Set();
        const active = new Set();
        const allowance = new Set();

        res.data.forEach((payment) => {
          monthSet.add(payment.uploadMonth);
          yearSet.add(payment.uploadYear);
          companyNames.add(payment.workLocation);
          active.add(payment.paymentType);
          allowance.add(payment.allowance)
        });
        
        const monthsOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        setMonths(Array.from(monthSet).sort((a, b) => {
          return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
        }));
        setYears(Array.from(yearSet));
        setCompanyNames(Array.from(companyNames));
        setActive(Array.from(active));
        setAllowanceNames(Array.from(allowance))
         
      } catch (error) {
        console.log(error);
      }
    };
    fetchPayments();

  }, []);
  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/payments/report',{
        headers: {
          Authorization: token,
        },
      });
      const payments = res.data;
      let filteredResults = payments;
      if (selectedMonth) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadMonth === selectedMonth
        );
      }
      if (selectedYear) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadYear === selectedYear
        );
      }
    if (selectedCompany) {
      filteredResults = filteredResults.filter(
        (payment) => payment.workLocation === selectedCompany
      );
    }
    if (selectedActive) {
      filteredResults = filteredResults.filter(
        (payment) => payment.paymentType === selectedActive
      );
    }
    if (selectedAllowance) {
      filteredResults = filteredResults.filter(
        (payment) => payment.allowance === selectedAllowance
      );
    }
    setSearchResults(filteredResults);
    }catch (error) {
      console.log(error);
    }
  };
  const handleMonthFilter = (event) => {
    setSelectedMonth(event.target.value);
  };
  const handleYearFilter = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  }
  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };
  const handleallowanceFilter = (event) => {
    setSelectedAllowance(event.target.value);
  };
  useEffect(() => {
    const csvData = searchResults.map((payment) => {
      const generateDate = new Date(payment.generateDate);
      const month = (generateDate.getMonth() + 1).toString().padStart(2, '0'); // Month is 0-based
      const day = generateDate.getDate().toString().padStart(2, '0');
      const year2 = generateDate.getFullYear().toString().slice(-2);
      const uploadMonth = payment.uploadMonth;
      const monthname = uploadMonth.substr(0, 3);
      const uploadYear = payment.uploadYear

      const formattedDate = `${year2}${month}${day}`;
  
      const salary = (payment.netSalary * 100).toFixed(0).toString().padStart(12, '0');
      const reference = payment.epfNo.toString().padStart(15, '0');
      const fullName = payment.fullName.substring(0, 20).padEnd(20, ' ');
      
      return [
        "0000",
        payment.bankCode,
        payment.branchCode,
        payment.accountNo,
        fullName,
        '23',
        '00',
        '0',
        '000000',
        salary,
        'SLR',
        '7010',
        '038',
        '000082513163',
        'Talentfort (Pvt) Ltd',
        reference,
        `Salary_${monthname}-${uploadYear}`,
        formattedDate, // Formatted date
        '000000',
      ].join('');
    });
  
    // Join all the data lines into a single string with line breaks
    const csvContent = csvData.join('\n');
  
    // Set the CSV data as text in the state
    setCSVDataText(csvContent);
  }, [searchResults]);
  const copyToClipboard = () => {
    const textarea = document.createElement('textarea');
    textarea.value = csvDataText;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);

    // Show a browser-native alert
    alert('Data copied to clipboard successfully');
  };

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>BankSlip</h1>
        <div className="filter_section">
          <div className="row mb-3">
            <div className="col-md-4">
              <div className="company-filter">
                <label htmlFor="companyFilter">Filter by Work Location:</label>
                <select
                  id="companyFilter"
                  value={selectedCompany}
                  className="form-select"
                  onChange={handleCompanyFilter}
                  >
                    <option value="">All</option>
                    {companyNames.map((company, index) =>
                      <option key={index} value={company}>
                        {company}
                      </option>
                      )}
                  </select>
                  </div>
                  </div>
            <div className="col-md-4">
              <div className="month-filter">
                <label htmlFor="monthFilter">Filter by Month:</label>
                <select
                  id="monthFilter"
                  value={selectedMonth}
                  className="form-select"
                  onChange={handleMonthFilter}
                >
                  <option value="">All</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="year-filter">
                <label htmlFor="yearFilter">Filter by Year:</label>
                <select
                  id="yearFilter"
                  value={selectedYear}
                  className="form-select"
                  onChange={handleYearFilter}
                >
                  <option value="">All</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row mb-3">
          <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
              <label htmlFor="activeFilter">Allowance:</label>
              <select
                id="activeFilter"
                value={selectedAllowance}
                className="form-select"
                onChange={handleallowanceFilter}
              >
               <option value="">All</option>
    {allowanceNames
      .filter((allowance) => allowance !== null) // Filter out null values
      .map((allowance) => (
        <option key={allowance} value={allowance}>
          {allowance}
        </option>
                ))}
              </select>
            </div>
          </div>
          
          <button className="filter" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th >Emp No</th>
                <th>Account Name</th>
                <th >Account No</th>
                <th >Bank No</th>
                <th >Branch No</th>
                <th>Issue Date</th>
                <th >Net Paid</th>
                <th>Work Location</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.empNo}</td>
                  <td className="center-aligned">{payment.fullName}</td>
                  <td className="center-aligned">{payment.accountNo}</td>
                  <td className="center-aligned">{payment.bankCode}</td>
                  <td className="center-aligned">{payment.branchCode}</td>
                  <td className="center-aligned">{payment.generateDate}</td>
                  <td className="center-aligned">{payment.netSalary}</td>
                  <td className="center-aligned">{payment.workLocation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
 <div className="copy-textarea">
          
          <textarea
            rows="20"
            cols="195"
            value={csvDataText}
            readOnly
            className="csv-text"
          />
          <button className="copy-button" onClick={copyToClipboard}>
            Copy to Clipboard
          </button>
        </div>
      </div>
    </div>
  );
}
