import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar from '../component/Sidebar';
import { CSVLink } from 'react-csv';
import './csv.css';

export default function Csv() {
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const token = Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/employees', {
        headers: {
          Authorization: token,
        },
      });
      
      const uniqueCompanyNames = Array.from(new Set(res.data.map(payment => payment.workLocation)));
      setCompanyNames(uniqueCompanyNames);
    } catch (error) {
      console.log('Error fetching payments:', error);  
    }
  };
  const prepareCSVData = () => {
    // Define headers specific to each company along with their corresponding backend API values

    const companySpecificHeaders = {
      Direct: [
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'OTAllowance',
        'OTArrears',
        'DOTArrears',
        'DOTAllowance',
        'ExtraworkingPayment',
        'ActinAllowance',
        'TransportAllowance',
        'PerformanceAllowance',
        'MotivationAllowance',
        'ExperienceAllowance',
        'SalesCommission',
        'CoveringupAllowance',
        'Advance',
        'FestivalAdvance',
        'BDCommission',
        'AdminAllowance',
        'MobileAllowance',
        'Loan',
        'OtherAllowance',
        'PayeeTax',
        'AdvanceDeduction',
        'FestivalAdvanceDeduction' ,
        'LoanDeduction' ,
        // ... other headers for the 'direct' company
      ],
      'Edinborough Products (Pvt) Ltd': [
       
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'SalesIncentive',
        'SalesDriveIncentive1',
        'SalesDriveIncentive2',
        'SalesDriveIncentive3',
        'ExtraworkingPayment',
        'TransportAllowance',
        'PerformanceAllowance',
        'MotivationAllowance',
        'ExperienceAllowance',
        'SalesCommission',
        'Advance',
        'FestivalAdvance',
        'OtherAllowance',
        'PayeeTax',
        'AdvanceDeduction',
        'FestivalAdvanceDeduction' ,
        'Welfare',
        'WelfareDeduction',
        'MobileDeduction',
        'OtherDeduction',
        // ... other headers for the 'edingburugh' company
      ],
      'RDB Call Centre': [
        'EmpNo',
        'WorkingDays',
        'NoPayAmount',
        'SalaryArrears',
        'OTAllowance',
        'OTArrears',
        'DOTArrears',
        'DOTAllowance',
        'ExtraworkingPayment',
        'ActinAllowance',
        'TransportAllowance',
        'PerformanceAllowance',
        'MotivationAllowance',
        'ExperienceAllowance',
        'SalesCommission',
        'CoveringupAllowance',
        'Advance',
        'FestivalAdvance',
        'BDCommission',
        'Loan',
        'AdminAllowance',
        'MobileAllowance',
        'Loan' ,
        'OtherAllowance',
        'PayeeTax',
        'AdvanceDeduction',
        'FestivalAdvanceDeduction' ,
        'LoanDeduction' ,
        'OtherDeduction' ,
        // ... other headers for the 'edingburugh' company
      ],
      'Water Board Call Centre': [
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'OTAllowance',
        'OTArrears',
        'DOTArrears',
        'DOTAllowance',
        'ExtraworkingPayment',
        'ActinAllowance',
        'TransportAllowance',
        'PerformanceAllowance',
        'MotivationAllowance',
        'ExperienceAllowance',
        'SalesCommission',
        'CoveringupAllowance',
        'Advance',
        'FestivalAdvance',
        'BDCommission',
        'Loan',
        'AdminAllowance',
        'MobileAllowance',
        'Loan' ,
        'OtherAllowance',
        'PayeeTax',
        'AdvanceDeduction',
        'FestivalAdvanceDeduction' ,
        'LoanDeduction' ,
        'OtherDeduction' ,
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Sales Team': [
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',   
      'TransportAllowance',
        'PerformanceAllowance',
        'SalesCommission',
        'FestivalAdvance',
        'MobileAllowance',
        'MobitelCommission',
        'FuelAllowance',
        'MngSupervisorAllowance',
        'SupervisorCommission',
        'SalaCommission',
        'KlmCommission',
        'PeoTvRemoteCommission',
        'MemoryTaxCommission',
        'AjCommission',
        'CpeArrearsCollectingCommission',
        'CpeCollectingCommission',
        'CommissionArrears',
        'StampDutyPlus',
        'OtherAllowance',
        'FestivalAdvanceDeduction',
        'GroomingTrainingDeduction',
        'PayeeTax',
        'OtherDeduction',
        'PreviousTax',
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Franchise Operation': [
       
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'TransportAllowance',
      'PerformanceAllowance',
      'SalesCommission',
      'FestivalAdvance',
      'MobileAllowance',
      'MobitelCommission',
      'FuelAllowance',
      'MngSupervisorAllowance',
      'SupervisorCommission',
      'SalaCommission',
      'KlmCommission',
      'PeoTvRemoteCommission',
      'MemoryTaxCommission',
      'AjCommission',
      'CpeArrearsCollectingCommission',
      'CpeCollectingCommission',
      'CommissionArrears',
      'StampDutyPlus',
      'OtherAllowance',
      'FestivalAdvanceDeduction',
        'GroomingTrainingDeduction',
        'PayeeTax',
        'OtherDeduction',
        'PreviousTax'
        // ... other headers for the 'edingburugh' company
      ],
      'Regional Business Development Manager': [
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'TransportAllowance',
        'PerformanceAllowance',
        'SalesCommission',
        'FestivalAdvance',
        'MobileAllowance',
        'MobitelCommission',
        'FuelAllowance',
        'MngSupervisorAllowance',
        'SupervisorCommission',
        'SalaCommission',
        'KlmCommission',
        'PeoTvRemoteCommission',
        'MemoryTaxCommission',
        'AjCommission',
        'CpeArrearsCollectingCommission',
        'CpeCollectingCommission',
        'CommissionArrears',
        'StampDutyPlus',
        'OtherAllowance',
        'FestivalAdvanceDeduction',
        'GroomingTrainingDeduction',
        'PayeeTax',
        'OtherDeduction',
        'PreviousTax'
        // ... other headers for the 'edingburugh' company
      ],
      'Ayathi Trust': [
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'OTAllowance',
      'OTArrear',
      'DOTArrears',
      'DOTAllowance ',
      'ExtraworkingPayment',
      'ActinAllowance',
      'TransportAllowance',
      'PerformanceAllowance',
      'MotivationAllowance',
      'ExperienceAllowance',
      'SalesCommission',
      'CoveringupAllowance',
      'Advance',
      'FestivalAdvance',
      'BDCommission',
      'PayeeTax',
      'AdvanceDeduction',
      'FestivalAdvanceDeduction' ,
      ],
      'Hemas SR':[
   
        'EmpNo',
        'WorkingDays',
        'NoPayAmount',
        'SalaryArrears',
    'SalesCommission',
    'OtherReimbursement',
    'Goya Payment',
    'FuelAllowance',
    'FestivalAdvance',
    'Advance',
    'FestivalAdvanceDeduction',
    'AdvanceDeduction',
    'PayeeTax',

       
      ],
    'Hemas SR-Intern':
    [

      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'SalesCommission',
      'InternAllowance',
      'InternAllowanceArrears',
      'OtherReimbursement',
      'SalesCommissionArrears',
      'OtherReimbursementArrears',
      'FuelAllowance',
      'FestivalAdvance',
      'Advance',    
      'FestivalAdvanceDeduction',
      'AdvanceDeduction',
      'PayeeTax',

     
      ],
      'Hemas MA':[
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'PerformanceAllowance',
      'TransportAllowance',
      'FoodAllowance',
      'SpecialAllowance',
      'FestivalAdvance',
      'Advance',
      'FestivalAdvanceDeduction',
      'AdvanceDeduction',
      'PayeeTax',

      ],
      'Hemas MA-Intern':[
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'InternAllowance',
        'InternAllowanceArrears',
        'PerformanceAllowance',
        'TransportAllowance',
        'FoodAllowance',
        'SpecialAllowance',
        'TransportallowanceArrears',
        'FoodArrears',
        'FestivalAdvance',
        'Advance',
        'FestivalAdvanceDeduction',
        'AdvanceDeduction',
        'PayeeTax',
      ],
      'Hemas MC':[
        'EmpNo',
        'WorkingDays',
        'NoPayAmount',
        'SalaryArrears',
        'AttendenceAllowance',
        'PerformanceAllowance',
        'HolidayPayment',
        'TransportAllowance',
        'OtherReimbursement',
        'SpecialAllowance',
        'SalesCommission',
        'FestivalAdvance',
        'Advance',
        'FestivalAdvanceDeduction',
        'AdvanceDeduction',
        'PayeeTax',
      ],
      'Hemas MC-Intern':[
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'InternAllowance',
      'AttendenceAllowance',
      'PerformanceAllowance',
      'HolidayPayment',
      'TransportAllowance',
      'Transport Allowance2',
      'OtherReimbursement',
      'SalesCommission',
      'PayeeTax',
      ],
      'Hemas SPA':[
        'EmpNo',
        'WorkingDays',
        'NoPayAmount',
        'SalaryArrears',
        'AttendenceAllowance',
        'PerformanceAllowance',
        'Performance Allowance 2',
        'TransportAllowance',
        'OtherReimbursement',
        'SpecialAllowance',
        'PayeeTax',

       
      ],
      'Hemas SPA-Intern':[
      
        'EmpNo',
        'WorkingDays',
        'NoPayAmount',
        'SalaryArrears',
        'InternAllowance',
        'PerformanceAllowance',
        'Performance Allowance 2',
        'TransportAllowance',
        'SpecialAllowance',
        'AttendenceAllowance',
        'Stamp Duty',
        'PayeeTax',
      ],
      'Hemas FMCG':[
        
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'Data Allowance',
      'MobileAllowance',
      'PayeeTax',
      ],
      'Hemas Driver':[
       
      'EmpNo',
      'WorkingDays',
      'NoPayAmount',
      'SalaryArrears',
      'OTAllowance',
      'DOTArrears',
      'FullbataAllowance',
      'NightAlllowance',
      'MobileAllowance',
      'PayeeTax',
      ],
    };
  
  
     // Define headers specific to the selected company
  const selectedCompanyHeaders = companySpecificHeaders[selectedCompany] || [];

  // Combine common headers with company-specific headers
  const headers = [...selectedCompanyHeaders];
  
    return [headers];
  };
  

  return (
    <div className='main-body'>
      <Sidebar />
      <div className="uploadcsv">
        <div className="top">
          <h1>Upload Details CSV Template (Terminate Inprogress)</h1>
        </div>
        <div className="row mb-3">
            <div className="col-md-6">
            <select value={selectedCompany} className="form-select" onChange={(e) => setSelectedCompany(e.target.value)}>
              <option value="">Select Company</option>
              {companyNames.map((companyName, index) => (
                <option key={index} value={companyName}>{companyName}</option>
              ))}
            </select>
            </div>
            <div className="col-md-4">
            <CSVLink
              data={prepareCSVData()} 
              filename={`Terminate_data_upload_${selectedCompany}.csv`}
              className="csv1"
              target="_blank"
            >
              CSV Template
            </CSVLink>
            </div>
          </div>
        </div>
        </div>
  );
              }