
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Employee.css';
import { FaUserEdit, FaUserTimes } from 'react-icons/fa';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';


export default function Employee() {
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const activeSet = new Set();
        res.data.forEach((employee) => {
          activeSet.add(employee.employeeActive);
        });
        setActive(Array.from(activeSet));
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployees();
  }, []);

  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/employees',{
        headers: {
          Authorization: token,
        },
      });
      const employees = response.data;

      let filteredResults = employees;
      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (employee) => employee.employeeActive === selectedActive
        );
      }

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error retrieving employee data:', error);
    }
  };

  const handleCompanyFilter = (event) => {
    setSelectedActive(event.target.value);
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this employee?');
    if (confirmDelete) {
      try {
        const token =Cookies.get('token');
        await axios.delete(`https://backhris.talentfort.live/employees/${id}`,{
          headers: {
            Authorization: token,
          },
        });
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSearch = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/employees',{
        headers: {
          Authorization: token,
        },
      });
      const { data } = response;

      // Filter the employees based on active status and search term
      const filteredResults = data.filter((employee) => {
        const empNoLower = employee.empNo.toLowerCase();
        const empnicLower = employee.nic.toLowerCase();
        const empepfNo = employee.epfNo;
        const searchTermLower = searchTerm.toLowerCase();

        return (
          (employee.employeeActive === selectedActive || !selectedActive) &&
          (empNoLower.startsWith(searchTermLower) ||
            empepfNo.startsWith(searchTermLower) ||
            empnicLower.includes(searchTermLower))
        );
      });

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error performing search:', error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className="main-body">
      <div>
        <Sidebar />
      </div>
      <div className="employee">
        <h1>Employee</h1>
        <form onSubmit={(e) => { e.preventDefault(); handleFilter(); }}>
            <label htmlFor="activeFilter">Active/Inactive/Terminate Inprogress</label>
            <div className="row">
              <div className="col-lg-3">
                <select
                  id="activeFilter"
                  value={selectedActive}
                  className="form-select"
                  onChange={handleCompanyFilter}
                >
                  <option value="">All</option>
                  {active.map((activestatus) => (
                    <option key={activestatus} value={activestatus}>
                      {activestatus}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-lg-1">
                <button className="select" onClick={handleFilter}>
                  Select
                </button>
              </div>
            </div>
        </form>
        <div className="row">
              <div className="col-lg-3">
        <div className="srch">
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          </div>
          </div>
<div className="col-lg-1">
<button className="select" onClick={handleSearch}>
            Search
          </button>
</div>
          
        </div>
      
        <div className="table-container">
          <table className="table">
            <thead>
              <tr>
                <th>Emp No</th>
                <th>Epf No</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>Current Address</th>
                <th>NIC</th>
                <th>Contact No</th>
                <th>Joined Date</th>
                <th>Email</th>
                <th>Work Location</th>
                <th>Designation</th>
                <th>Employee Type</th>
                <th>Active</th>
                <th>bud</th>
                <th>fix</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((employee) => (
                <tr className="Dts" key={employee.id}>
                  <td>{employee.empNo}</td>
                  <td>{employee.epfNo}</td>
                  <td>{employee.fullName}</td>
                  <td>{employee.gender}</td>
                  <td>{employee.currentAddress}</td>
                  <td>{employee.nic}</td>
                  <td>{employee.phone}</td>
                  <td>{employee.joinedDate}</td>
                  <td>{employee.email}</td>
                  <td>{employee.workLocation}</td>
                  <td>{employee.designation}</td>
                  <td>{employee.employeeType}</td>
                  <td>{employee.employeeActive}</td>
                  <td>{employee.payGrade}</td>
                  <td>{employee.fixAllowance}</td>
                  <td className="action-btn">
                    <div className="actions">
                    {employee.employeeActive !== 'Inactive' && (
          <Link to={`/updateemployee/${employee.id}`}>
            <button className="upd">
              <FaUserEdit /> {/* Icon for updating employee */}
            </button>
          </Link>
        )}
                      <button className="del" onClick={() => handleDelete(employee.id)}>
                        <FaUserTimes /> {/* Icon for deleting employee */}
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
