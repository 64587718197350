import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function Graph1() {
  const [state, setState] = useState({
    series: [{
      name: 'Total Net Salary',
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 500,
      },
      plotOptions: {
        bar: {
          borderRadius: 0,
          dataLabels: {
            position: 'top',
          },
          barWidth: '20px', // Adjust the width of the bars in pixels
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ['#304758'],
        },
      },
      xaxis: {
        categories: [],
        position: 'bottom',
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          maxWidth: 200, // Limit the width of labels
          style: {
            fontSize: '12px',
          },
          formatter: function (value, timestamp, index) {
            // Implement your custom formatting logic here if needed
            return value;
          },
          rotate: -55, // Rotate labels for better readability
          rotateAlways: false,
          hideOverlappingLabels: true,
          trim: true,
          minHeight: undefined,
          maxHeight: 150,
          offsetX: 0,
          offsetY: 0,
          align: 'center',
          style: {
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            cssClass: 'apexcharts-xaxis-label',
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: true,
          formatter: function (val) {
            return val;
          },
        },
      },
      colors: ['#6495ED'], // Set the color of the bars
    },
  });
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/graph1', {
          headers: {
            Authorization: token,
          },
        });

        const data = response.data;

        const categories = data.map((item) => item.workLocation);
        const seriesData = data.map((item) => parseFloat(item.totalNetSalary).toFixed(2));
        // Convert to float

        setState((prevState) => ({
          ...prevState,
          series: [{
            name: 'Total Net Salary',
            data: seriesData,
          }],
          options: {
            ...prevState.options,
            xaxis: {
              ...prevState.options.xaxis,
              categories: categories,
            },
          },
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="chart">
      <ReactApexChart options={state.options} series={state.series} type="bar" height={480} />
    </div>
  );
}
