import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import './Dashboard.css';
import axios from 'axios';
import { useState, useEffect } from 'react';
import employe from '../assets/division.png'
import loans from '../assets/employee.png';
import leave from '../assets/work-life.png';
import payment from '../assets/temp-worker.png';
import Graph1 from '../component/Graph1.jsx';
import Graph2 from '../component/Graph2.jsx';
import Graph3 from '../component/Graph3.jsx';
import Graph4 from '../component/Graph4.jsx';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';


// eslint-disable-next-line import/no-anonymous-default-export
export default function Dash() {

  const [employeeCount, setEmployeeCount] = useState(0);
  const [workLocationAmount, setWorklocationAmount] = useState(0);
  const [designation, setDesignation] = useState(0);  
  const [salary, setSalary] = useState(0);
  const role = Cookies.get('role')

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/employeecount',{
          headers: {
            Authorization: token,
          },
        }); 
        const data = response.data;
        const count = data.employeeCount;

        setEmployeeCount(count);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  useEffect(()=> {
    const fetchAmount = async ()=> {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/worklocationcount',{
        headers: {
          Authorization: token,
        },
      });
      const data = response.data;
      const count =data.workLocationCount;
      setWorklocationAmount(count);
    } catch(error) {
      console.log(error);
    }
  };
    fetchAmount();  
  }, []);

  useEffect(()=> {
    const fetchdesignation = async ()=> {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/designationcount',{
        headers: {
          Authorization: token,
        },
      });
      const data = res.data;
      const count =data.designationCount;
      setDesignation(count);
    } catch(error) {
      console.log(error);

    }
  };
    fetchdesignation();
  }, []);

  useEffect(()=> {
    const fetchsalary = async ()=> {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/totalsalary',{
        headers: {
          Authorization: token,
        },
      });
      const data = res.data;
      const count =data.totalSalary;
      setSalary(count);
    } catch(error) {
      console.log(error);

    }
  };
    fetchsalary();
  }, []);


  return (
  <div className='main-body'>
    <div>
    <Sidebar />
    </div>
    <div className='dashboard'> 
    
    <h1>{role} Dashboard</h1>
         <div className="contant" >
          <div className="row">
           <div className="col-md-3">
            <div className="card card-employees"> 
            <h2>Employees</h2>
            <p className='details'>{employeeCount}</p>
            <img className='bg' src={employe} alt="" />
            </div>
          </div>

          <div className="col-md-3">
    <div className="card card-workLocations">
      <h2>Work Locations</h2>
      <p className='details'>{workLocationAmount}</p>
      <img className='bg' src={loans} alt="" />
    </div>
  </div>

  <div className="col-md-3">
    <div className="card card-designation">
      <h2>Designations</h2>
      <p className='details'>{designation}</p>
      <img className='bg' src={payment} alt="" />
    </div>
  </div>

  <div className="col-md-3">
    <div className="card card-salary">
      <h2>Salary</h2>
      <p className='details'>{salary}</p>
      <img className='bg' src={leave} alt="" />
    </div>
  </div>
</div>

    <div className="row">
    <div className="col-lg-12">
      <div className='graph1'>
      <h4>Salary Categaries</h4>
    <Graph1 />
    </div>
    </div>
    </div>

    <div className="row">
    <div className="col-lg-5 col-md-3">
      <div className='graph3'>
        <h4>Employee Status </h4>
      <Graph3/>
      </div>  
    </div> 
    <div className="col-lg-7 col-md-3">
      <div className='graph2'>
        <h4>Employees Categaries</h4>
      <Graph2/>
      </div>
    </div>
    </div>
    </div>
   </div>    
    </div>

  )
}
