import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function Graph2() {
  const [state, setState] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: [],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
      colors: [
        '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', 
        '#546E7A', '#26a69a', '#D10CE8', '#FF7F0E', '#1F77B4',
        '#2CA02C', '#FFBB78', '#BCBD22', '#17BECF', '#9EDAE5',
        '#7F7F7F', '#9467BD', '#8C564B', '#E377C2', '#FF9896'
      ],
      legend: {
        show: true,
        position: 'left',
        offsetX: 0,
        offsetY: 0,
        formatter: function(val) {
          return val
        },
      },
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token =Cookies.get('token')
        const response = await axios.get('https://backhris.talentfort.live/graph2',{
          headers: {
            Authorization: token,
          },
        }); 
        const data = response.data;

        const series = data.map((item) => item.employeeCount);
        const labels = data.map((item) => item.workLocation);

        setState((prevState) => ({
          ...prevState,
          series,
          options: {
            ...prevState.options,
            labels,
          },
        }));
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div id="chart" style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
      <style>{`
        .apexcharts-legend {
          overflow: auto !important;
        }
        .apexcharts-legend::-webkit-scrollbar {
          display: none;
        }
      `}</style>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="pie"
        width={680}
        height={380}
      />
    </div>
  );
}
