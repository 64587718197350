
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Designations.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function Designation() {
    const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchDesignation = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/designations',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDesignation();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this designation?");
  if (confirmDelete) {
    try {
      const token =Cookies.get('token');
      await axios.delete(`https://backhris.talentfort.live/designations/${id}`,{
        headers: {
          Authorization: token,
        },
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }
  };
  const handleSearch = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/designations',{
        headers: {
          Authorization: token,
        },
      });
      const { data } = response;

      // Filter the designations whose employee names start with the search term
      const filteredResults = data.filter((designation) => {
        const desNameLower = designation.desName.toLowerCase();
        const desCodeLower = designation.desCode.toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        
        return desNameLower.startsWith(searchTermLower) || desCodeLower.startsWith(searchTermLower);
      });

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error performing search:', error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
    <div className="designations">
      <h1>Designation</h1>
      
      <div className="search">
  <input
    type="text"
    placeholder="Search..."
    value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
    onKeyPress={handleKeyPress}
  />
  <button className="search-button" onClick={handleSearch}>Search</button>
</div>
      <div>
        <button className="Addldesignationhead">
          <Link className="Addldesignation" to="/adddesignation">
            Add Designation
          </Link>
        </button>

        <table className="table">
          <thead>
            <tr>
            <th>Designation Code</th>
              <th>Designation Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((designation) => (

              <tr key={designation.id}>
                <td>{designation.desCode}</td>
                <td>{designation.desName}</td> 
                <td>{designation.desDescription}</td>
                <td className="action-btn">
                <div className="actions">
        <Link to={`/updatedesignation/${designation.id}`}>
          <button className="update1">
            <FaEdit /> {/* Icon for updating employee */}
          </button>
        </Link>
        <button className="delete" onClick={() => handleDelete(designation.id)}>
          <FaTrash /> {/* Icon for deleting employee */}
        </button>
      </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
}
