import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'jspdf-autotable';
import './Paymentdetails.css';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';
import { CSVLink } from 'react-csv';


export default function Payments() {
  const [data, setData] = useState([]);
  const [generateMonth, setGenerateMonth] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [genarateDate, setGenarateDate] = useState('');  



  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/paymentdata/ayathitrust',{
          headers: {
            Authorization: token,
          },
        });
        setData(response.data); // Set data to the entire response
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchPayments();
  }, []);

  const handleCheckboxChange = (empNo) => {
    if (selectAll) {
      // If "Select All" is checked, toggle individual row selection
      const newSelectedRows = selectedRows.includes(empNo)
        ? selectedRows.filter((selectedEmpNo) => selectedEmpNo !== empNo)
        : [...selectedRows, empNo];
      setSelectedRows(newSelectedRows);
    } else {
      // Toggle individual row selection
      const newSelectedRows = [...selectedRows];
      const index = newSelectedRows.indexOf(empNo);
  
      if (index === -1) {
        newSelectedRows.push(empNo);
      } else {
        newSelectedRows.splice(index, 1);
      }
  
      setSelectedRows(newSelectedRows);
    }
  };
  
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((payment) => payment.empNo));
  };
  
  const handleSave = async () => {
    try {
      // Validate if generateDate and generateMonth are selected
      if (!genarateDate ) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Generate Date" before generating.');
        return;
      }
      if (!generateMonth) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Payment Month" before generating.');
        return;
      }
      const unselectedData = data.filter((payment) => !selectedRows.includes(payment.empNo));
      const token = Cookies.get('token');
      
      // Send a single POST request with unselected payment data
      await axios.post('https://backhris.talentfort.live/payments', {
        payments: unselectedData,
        generateMonth: generateMonth,
        generateDate: genarateDate,
      }, { 
        headers: { 
          Authorization: token, 
        },
      });
  
      // Display success alert before redirecting
      alert('Payment data saved successfully.');
      window.location.href = '/closepaymentdetails';
    } catch (error) {
      console.error('Error saving unselected payment data:', error);
    }
  };
  
  const handleUpdate = async () => {
    try {
       // Validate if generateDate and generateMonth are selected
       if (!genarateDate ) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Generate Date" before generating.');
        return;
      }
      if (!generateMonth) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Payment Month" before generating.');
        return;
      }
      const token =Cookies.get('token')
      // Filter out the selected rows from the data
      const unselectedData = data.filter((payment) => !selectedRows.includes(payment.empNo));
  
       await axios.put('https://backhris.talentfort.live/payments', {
        payments: unselectedData,
        generateMonth: generateMonth,
        generateDate: genarateDate,
      },{ headers: {
        Authorization: token,
      },
        });
          alert('Payment data updated successfully.');
          window.location.href = '/closepaymentdetails';
        
    } catch (error) {
      console.error('Error updating payment data:', error);
      alert('Failed to update payment data. Please check the console for details.');
    }
  };


  const prepareCSVData = () => {
    // Define headers for the CSV file
    const headers = [
      "Emp No",
      "Epf No",
      "Emp Name",
      "Basic Salary",
      "Bud. Allowance",
      "Fix. Allowance",
      "Consolidate Salary",
      "Nopay Rate",
      "Nopay Hours",
      "Nopay Amount",
      "Salary Arrears",
      "Gross Salary",
      "OT Allowance",
      "OT Arrears",
      "DOT Allowance",
      "DOT Arrears",
      "Extra working",
      "Actin Allowance",
      "Transport Allowance",
      "Performance Allowance",
      "Motivation Allowance",
      "Experience Allowance",
      "Sales Commission",
      "Covering up Allowance",
      "Salary Advance",
      "Festival Advance",
      "BD Commission",
      "Mobile Allowance",
      "Admin Allowance",
      "Loan",
      "Other Allowance",
      "Total Allowance",
      "Total Earning",
      "Salary Advance Deduction",
      "Festival Advance Deduction",
      "Loan Deduction",
      "Other Deduction",
      "EPF(8%)",
      "Stamp Duty",
      "Payee Tax",
      "Total Deduction",
      "Net Salary",
      "EPF(12%)",
      "ETF(3%)",
      "Account No"
    ];
  
    // Map the data to match the order of headers and extract relevant properties
    const csvData = data.map((payment) => {
      return [
        payment.empNo,
        payment.epfNo,
        payment.displayName,
        payment.salary,
        payment.payGrade,
        payment.fixAllowance,
        payment.consolidateSalary,
        payment.nopayRate,
        payment.noPay,
        payment.nopayAmount,
        payment.salaryArrears,
        payment.grossSalary,
        payment.overtimeAllowance,
        payment.overtimeArrears,
        payment.dotAllowance,
        payment.dotArrears,
        payment.extraworkingPayment,
        payment.actinAllowance,
        payment.transportAllowance,
        payment.performanceAllowance,
        payment.motivationAllowance,
        payment.experienceAllowance,
        payment.salesCommission,
        payment.coveringupAllowance,
        payment.advance,
        payment.festivalAdvance,
        payment.bdCommission,
        payment.mobileAllowance,
        payment.adminAllowance,
        payment.loan,
        payment.otherAllowance,
        payment.totalAllowance,
        payment.totalEarning,
        payment.advanceDeduction,
        payment.festivaladvanceDeduction,
        payment.loanDeduction,
        payment.otherDeduction,
        payment.epf8,
        payment.stampDuty,
        payment.payeetax,
        payment.totalDeduction,
        payment.netSalary,
        payment.epf12,
        payment.etf3,
        payment.accountNo
      ]
    });
  
    // Insert headers as the first row
    return [headers, ...csvData];
  };
  


  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Payment Details (Ayathi Trust)</h1>
        <div className="row">
<div className="col-lg-3 mb-2">
  <label htmlFor="generateMonth" className="form-label">
    Payment Month
  </label>
  <input
    type="month"
    className="form-control"
    id="generateMonth"
    name="generateMonth"
    onChange={(e) => setGenerateMonth(e.target.value)}
  />
</div>
<div className="col-lg-3 mb-2">
  <label htmlFor="genarateDate" className="form-label">
    Genarate Date
  </label>
  <input
    type="date"
    className="form-control"
    id="genarateDate"
    name="genarateDate"
    onChange={(e) => setGenarateDate(e.target.value)} 
  />
</div>
</div>
        <div>
          <table className="table">
            <thead>
              <tr>
              <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAllChange}
                    checked={selectAll}
                  />
                </th>
                <th>Emp No</th>
                <th>Epf No</th>
                <th>Emp Name</th>
                <th>Basic Salary</th>
                <th>Bud. Allowance</th>
                <th>Fix Allowance</th>
                <th>Consolidate Salary</th>
                <th>Nopay Rate</th>
                <th>Nopay Hours</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Gross Salary</th>
                <th>OT Allowance</th>
                <th>OT Arrears</th>
                <th>DOT Allowance</th>
                <th>DOT Arrears</th>
                <th>Extra working</th>
                <th>Actin Allowance</th>
                <th>Transport Allowance</th>
                <th>Performance Allowance</th>
                <th>Mortivation Allowance</th>
                <th>Experience Allowance</th>
                <th>Sales Commission</th>
                <th>Covering up Allowance</th>
                <th>Salary Advance</th>
                <th>Festival Advance</th>
                <th>BD Commission</th>
                <th>Total Allowance</th>
                <th>Total Earning</th>
                <th>Festival Advance Deduction</th>
                <th>Advance Deduction</th>
                <th>EPF(8%)</th>
                <th>Stamp Duty</th>
                <th>Payee Tax</th>
                <th>Total Deduction</th>
                <th>Net Salary</th>
                <th>EPF(12%)</th>
                <th>ETF(3%)</th>
                <th>Account No</th>

              
              </tr>
            </thead>
            <tbody>
              {data.map((payment) => (
                <tr key={payment.empNo}>
                <td>
                 <input
                   type="checkbox"
                   onChange={() => handleCheckboxChange(payment.empNo)}
                   checked={selectedRows.includes(payment.empNo)}
                 />
               </td>
                  <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.displayName}</td>
                  <td className="right-aligned">{payment.salary}</td>
                  <td className="right-aligned">{payment.payGrade}</td>
                  <td className="right-aligned">{payment.fixAllowance}</td>
                  <td className="right-aligned">{payment.consolidateSalary}</td>
                  <td className="right-aligned">{payment.nopayRate}</td>
                  <td className="right-aligned">{payment.noPay}</td>
                  <td className="right-aligned">{payment.nopayAmount}</td>       
                  <td className="right-aligned">{payment.salaryArrears}</td>
                  <td className="right-aligned">{parseFloat(payment.grossAmount).toFixed(2)}</td>
                  <td className="right-aligned">{payment.overtimeAllowance}</td>
                  <td className="right-aligned">{payment.overtimeArrears}</td>
                  <td className="right-aligned">{payment.dotAllowance}</td>
                  <td className="right-aligned">{payment.dotArrears}</td>
                  <td className="right-aligned">{payment.extraworkingPayment}</td>
                  <td className="right-aligned">{payment.actinAllowance}</td>
                  <td className="right-aligned">{payment.transportAllowance}</td>
                  <td className="right-aligned">{payment.performanceAllowance}</td>
                  <td className="right-aligned">{payment.motivationAllowance}</td>
                  <td className="right-aligned">{payment.experienceAllowance}</td>
                  <td className="right-aligned">{payment.salesCommission}</td>
                  <td className="right-aligned">{payment.coveringupAllowance}</td>
                  <td className="right-aligned">{payment.advance}</td>
                  <td className="right-aligned">{payment.festivalAdvance}</td>
                  <td className="right-aligned">{payment.bdCommission}</td>
                  <td className="right-aligned">{payment.totalAllowance}</td>
                  <td className="right-aligned">{payment.totalEarning}</td>
                  <td className="right-aligned">{payment.festivaladvanceDeduction}</td>
                  <td className="right-aligned">{payment.advanceDeduction}</td>
                  <td className="right-aligned">{payment.epf8}</td>
                  <td className="right-aligned">{payment.stampDuty}</td>
                  <td className="right-aligned">{payment.payeetax}</td>
                  <td className="right-aligned">{parseFloat(payment.totalDeduction).toFixed(2)}</td>
                  <td className="right-aligned">{parseFloat(payment.netSalary).toFixed(2)}</td>
                  <td className="right-aligned">{payment.epf12}</td>
                  <td className="right-aligned">{payment.etf3}</td>
                  <td className="right-aligned">{payment.accountNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='buttons'> 
        <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename={`Ayathitrust_check.csv`}
    className="checkcsv" // Add a className for styling
    target="_blank"
  >
    Check
  </CSVLink>
            <button className='update' onClick={handleUpdate}>Update </button>
            <button className='genarate' onClick={handleSave}>Generate </button>
          </div>
      </div>
    </div>
  );
}