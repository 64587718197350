import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './Worklocation.css';
import { FaEdit, FaTrash } from 'react-icons/fa';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function Worklocations() {
    const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);

  useEffect(() => {
    const fetchWorklovations = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/worklocations',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchWorklovations();
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this worklocation?");
  if (confirmDelete) {
    try {
      const token =Cookies.get('token');
      await axios.delete(`https://backhris.talentfort.live/worklocations/${id}`,{
        headers: {
          Authorization: token,
        },
      });
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  }
  };
  const handleSearch = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/worklocations',{
        headers: {
          Authorization: token,
        },
      });
      const { data } = response;

      // Filter the worklocation whose employee names start with the search term
      const filteredResults = data.filter((worklocation) => {
        const workNameLower = worklocation.workName.toLowerCase();
        const workCodeLower = worklocation.workCode.toLowerCase();
        const searchTermLower = searchTerm.toLowerCase();
        
        return workNameLower.startsWith(searchTermLower) || workCodeLower.startsWith(searchTermLower);
      });

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error performing search:', error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
    <div className="worklocations">
      <h1>Work Location</h1>

      
      <div className="search">
  <input
    type="text"
    placeholder="Search..."
    value={searchTerm}
    onChange={e => setSearchTerm(e.target.value)}
    onKeyPress={handleKeyPress}
  />
  <button className="search-button" onClick={handleSearch}>Search</button>
</div>
      <div>
        <button className="Addworklocationhead">
          <Link className="Addworklocation" to="/addworklocation">
            Add Worklocation
          </Link>
        </button>

        <table className="table">
          <thead>
            <tr>
            <th>Work Location Code</th>
              <th>Work Location Name</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {searchResults.map((worklocation) => (

              <tr key={worklocation.id}>
                <td>{worklocation.workCode}</td>
                <td>{worklocation.workName}</td> 
                <td>{worklocation.workDescription}</td>
                <td>
                <div className="actions">
        <Link to={`/updateworklocations/${worklocation.id}`}>
          <button className="update1">
            <FaEdit /> {/* Icon for updating employee */}
          </button>
        </Link>
        <button className="delete" onClick={() => handleDelete(worklocation.id)}>
          <FaTrash /> {/* Icon for deleting employee */}
        </button>
      </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    </div>
  );
}
