import React, { useState , useEffect} from 'react';

import Cookies from 'js-cookie';
import axios from 'axios';
import './Upload.css';
import Sidebar from '../component/Sidebar'

export default function Uploadterminatecsv() {
 const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [selectedAllowance, setSelectedAllowance] = useState('');
  const [allAllowances, setAllAllowances] = useState([
        'Motivation Allowance',
        'Overtime Allowance',
        'Dot Arrears',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commission',
        'Coveringup Allowance',
        'Overtime Arrears',
        'Dot Allowance',
        'Salary Advance',
        'Advance Deduction',
        'BD Commission',
        'Payee tax',
        'Experience Allowance',
        'Festival Advance',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan',
        'Other Allowance',
        'Festivaladvance Deduction',
        'Loan Deduction',
        'Other Deduction',
        'Sales Incentive',
        'Sales driveIncentive1',
        'Sales driveIncentive2',
        'Sales driveIncentive3',
        'Welfare',
        'Welfare Deduction',
        'Mobile Deduction',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'Klm Commission',
        'Mobitel Commission',
        'Peo Tv RemoteCommission',
        'Memory Tax Commission',
        'Grooming Training Deduction',
        'Aj Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'Commission Arrears',
        'Stamp Dutyplus',
        'Previous Tax',       
        'Other Reimbursement',
        'Goya Payment',
        'Intern Allowance',
        'Intern Allowance Arrears',
        'Other Reimbursement Arrears',
        'Sales Commission Arrears',
        'Food Allowance',
        'Special Allowance',
        'Transport Allowance Arrears',
        'Food Arrears',
        'Night Alllowance',
        'Data Allowance',
        'Performance Allowance2',
        'Attendence Allowance',
        'Holiday Payment',
        'Transport Allowance2',
        'Full Bata Allowance',
  ]); 
  useEffect(() => {
    const sortedAllowances = [...allAllowances].sort();
    setAllAllowances(sortedAllowances);
  }, []);
  
     useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/intermediate/details' ,{
          headers: {
            Authorization: token,
          },
        });
        setData(response.data); // Set data to the entire response
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchPayments();
  }, []); 
    
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        const companyNamesSet = new Set();

        res.data.forEach((payment) => {
          companyNamesSet.add(payment.workLocation);
        });

        setCompanyNames(Array.from(companyNamesSet));
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyNames();
  }, []);
  
  const handleAllowanceFilter = (event) => {
    setSelectedAllowance(event.target.value);
  };

  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
    setSuccessMessage(null);
  };

  const handleUpload = async () => {
    setError(null);
    setSuccessMessage(null);

    if (!file) {
      window.alert('Please select a CSV file to upload.');
      return;
    }

    if (!selectedCompany) {
      window.alert('Please select a Work Location.');
      return;
    }

    const confirmUpload = window.confirm('Are you sure you want to upload this file?');

    if (!confirmUpload) {
      setFile(null);
      return;
    }

    try {
      const formData = new FormData();
      formData.append('csvFile', file);
      formData.append('workLocation', selectedCompany); // Include the selected work location
      formData.append('allowance', selectedAllowance);
      const token =Cookies.get('token');
      const response = await axios.post(
        'https://backhris.talentfort.live/upload/intermediatedata',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',Authorization: token
          },
        }
      );

      if (response.data.error === 'Data for this month and year already exists.') {
        window.alert('Data for this month and year already exists.');
      } else {
        setFile(null);
        window.alert('CSV file uploaded successfully.');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      window.alert('Error uploading file. Please try again later.');
    }
  };

  const handleClear = async () =>{
    const confirmDelete = window.confirm('Are you sure you want to clear this data?');
    if (confirmDelete) {
      try {
        const token =Cookies.get('token');
        await axios.delete(`https://backhris.talentfort.live/intermediate/allowance`,{
          headers: {
            Authorization: token,
          },
        });
        window.location.reload();
      } catch (error) {
        console.log(error);
      }
    }
  }

  return (
    <div className="main-body">
      <div>
        <Sidebar />
      </div>

      <div className="uploadcsv">
        <div className="top">
          <h1>Upload Details (Intermediate)</h1>
          <div className="row mb-1">
            <div className="col-lg-3">
              <label htmlFor="uploadDate" className="form-label">
                Upload CSV
              </label>
              <input
                id="file-input"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="form-control"
              />

              {error && <div className="alert">{error}</div>}
              {successMessage && <div className="alert">{successMessage}</div>}
            </div>
            <div className="col-lg-3">
              <label htmlFor="uploadDate" className="form-label">
                Work Location
              </label>
              <select
                id="companyFilter"
                value={selectedCompany}
                className="form-select"
                onChange={handleCompanyFilter}
              >
                <option value="">All</option>
                {companyNames.map((company, index) => (
                  <option key={index} value={company}>
                    {company}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-3">
              <label htmlFor="uploadDate" className="form-label">
                Allowance
              </label>
              <select
                id="allowanceFilter"
                value={selectedAllowance}
                className="form-select"
                onChange={handleAllowanceFilter}
              >
                <option value="">All</option>
                {allAllowances.map((allowance, index) => (
                  <option key={index} value={allowance}>
                    {allowance}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-1">
              <button onClick={handleUpload} className="uploadbutton1">
                Upload
              </button>
              </div>
              
          </div>
        </div>
        <div>
        <table className="table">
          <thead>
          <tr>
          <th>Emp No</th>
                <th>Working Days</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Motivation Allowance</th>
        <th>Overtime Allowance</th>
        <th>Dot Arrears</th>
        <th>Extraworking Payment</th>
        <th>Actin Allowance</th>
        <th>Transport Allowance</th>
        <th>Performance Allowance</th>
        <th>Sales Commission</th>
        <th>Coveringup Allowance</th>
        <th>Overtime Arrears</th>
        <th>Dot Allowance</th>
        <th>Salary Advance</th>
        <th>Advance Deduction</th>
        <th>BD Commission</th>
        <th>Payee tax</th>
        <th>Experience Allowance</th>
        <th>Festival Advance</th>
        <th>Admin Allowance</th>
        <th>Mobile Allowance</th>
        <th>Loan</th>
        <th>Other Allowance</th>
        <th>Festivaladvance Deduction</th>
        <th>Loan Deduction</th>
        <th>Other Deduction</th>
        <th>Sales Incentive</th>
        <th>Sales driveIncentive1</th>
        <th>Sales driveIncentive2</th>
        <th>Sales driveIncentive3</th>
        <th>Welfare</th>
        <th>Welfare Deduction</th>
        <th>Mobile Deduction</th>
        <th>Fuel Allowance</th>
        <th>Mng Supervisor Allowance</th>
        <th>Supervisor Commission</th>
        <th>Sala Commission</th>
        <th>Klm Commission</th>
        <th>Mobitel Commission</th>
        <th>Peo Tv RemoteCommission</th>
        <th>Memory Tax Commission</th>
        <th>Grooming Training Deduction</th>
        <th>Aj Commission</th>
        <th>Cpe Arrears Collecting Commission</th>
        <th>Cpe Collecting Commission</th>
        <th>Commission Arrears</th>
        <th>Stamp Dutyplus</th>
        <th>Previous Tax</th>       
        <th>Other Reimbursement</th>
        <th>Goya Payment</th>
        <th>Intern Allowance</th>
<th>Intern Allowance Arrears</th>
<th>Other Reimbursement Arrears</th>
<th>Sales Commission Arrears</th>
<th>Food Allowance</th>
<th>Special Allowance</th>
<th>Transport Allowance Arrears</th>
<th>Food Arrears</th>
<th>Night Alllowance</th>
<th>Data Allowance</th>
<th>Performance Allowance2</th>
<th>Attendence Allowance</th>
<th>Holiday Payment</th>
<th>Transport Allowance2</th>
<th>Full Bata Allowance</th>
<th>Worklocation</th>
              </tr>
          </thead>
          <tbody>
              {data.map((allowance) => (
                <tr className="Dts" key={allowance.id}>
                  <td>{allowance.empNo}</td>
                  <td>{allowance.workingDays}</td>
                  <td>{allowance.nopayAmount}</td>
                  <td>{allowance.salaryArrears}</td>
                  <td>{allowance.motivationAllowance}</td>
        <td>{allowance.overtimeAllowance}</td>
        <td>{allowance.dotArrears}</td>
        <td>{allowance.extraworkingPayment}</td>
        <td>{allowance.actinAllowance}</td>
        <td>{allowance.transportAllowance}</td>
        <td>{allowance.performanceAllowance}</td>
        <td>{allowance.salesCommission}</td>
        <td>{allowance.coveringupAllowance}</td>
        <td>{allowance.overtimeArrears}</td>
        <td>{allowance.dotAllowance}</td>
        <td>{allowance.advance}</td>
        <td>{allowance.advanceDeduction}</td>
        <td>{allowance.bdCommission}</td>
        <td>{allowance.payeetax}</td>
        <td>{allowance.experienceAllowance}</td>
        <td>{allowance.festivalAdvance}</td>
        <td>{allowance.adminAllowance}</td>
        <td>{allowance.mobileAllowance}</td>
        <td>{allowance.loan}</td>
        <td>{allowance.otherAllowance}</td>
        <td>{allowance.festivaladvanceDeduction}</td>
        <td>{allowance.loanDeduction}</td>
        <td>{allowance.otherDeduction}</td>
        <td>{allowance.salesIncentive}</td>
        <td>{allowance.salesdriveIncentive1}</td>
        <td>{allowance.salesdriveIncentive2}</td>
        <td>{allowance.salesdriveIncentive3}</td>
        <td>{allowance.welfare}</td>
        <td>{allowance.welfareDeduction}</td>
        <td>{allowance.mobileDeduction}</td>
        <td>{allowance.fuelAllowance}</td>
        <td>{allowance.mngSupervisorAllowance}</td>
        <td>{allowance.supervisorCommission}</td>
        <td>{allowance.salaCommission}</td>
        <td>{allowance.klmCommission}</td>
        <td>{allowance.mobitelCommission}</td>
        <td>{allowance.peoTvRemoteCommission}</td>
        <td>{allowance.memoryTaxCommission}</td>
        <td>{allowance.groomingTrainingDeduction}</td>
        <td>{allowance.ajCommission}</td>
        <td>{allowance.cpeArrearsCollectingCommission}</td>
        <td>{allowance.cpeCollectingCommission}</td>
        <td>{allowance.commissionArrears}</td>
        <td>{allowance.stampDutyplus}</td>
        <td>{allowance.previousTax}</td>
        <td>{allowance.otherReimbursement}</td>
        <td>{allowance.goyaPayment}</td>
        <td>{allowance.internAllowance}</td>
        <td>{allowance.internallowanceArrears}</td>
        <td>{allowance.otherreimbursementArrears}</td>
        <td>{allowance.salescommissionArrears}</td>
        <td>{allowance.foodAllowance}</td>
        <td>{allowance.specialAllowance}</td>
        <td>{allowance.transportallowanceArrears}</td>
        <td>{allowance.foodArrears}</td>
        <td>{allowance.nightAlllowance}</td>
        <td>{allowance.dataAllowance}</td>
        <td>{allowance.performanceAllowance2}</td>
        <td>{allowance.attendenceAllowance}</td>
        <td>{allowance.holidayPayment}</td>
        <td>{allowance.transportAllowance2}</td>
        <td>{allowance.fullbataAllowance}</td>
                  <td>{allowance.workLocation}</td>
                </tr>
              ))}
            </tbody>
        </table>
        <div className="col-lg-1">
            <button className='clearbutton' onClick={handleClear}>Clear</button>
            </div>
        </div>
        </div>
        </div>
    );
            }