import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import './Paymentreport.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';
import Select from 'react-select';

export default function Epfreport() {
  const [searchResults, setSearchResults] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');
  const [dete, setDate] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/epfdata',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const empnoset = new Set();
        const DateSet = new Set();
        const companyNames = new Set();
        const active = new Set();
        res.data.forEach((payment) => {
          empnoset.add(payment.empNo);
          DateSet.add(payment.uploadDate);
          companyNames.add(payment.workLocation);
          active.add(payment.employeeActive);
        });
        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          if (!isNaN(a) && !isNaN(b)) {
            return a - b;
          }
          return a.localeCompare(b);
        });

        setEmpnos(
          sortedEmpnos.map((empno) => ({ value: empno, label: empno }))
        );
       
       
        setCompanyNames(Array.from(companyNames));
        setActive(Array.from(active));
        setDate(Array.from(DateSet));
      } catch (error) {
        console.log(error);
      }
    };

    fetchPayments();
  }, []);


  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/epfdata',{
        headers: {
          Authorization: token,
        },
      });
      const payments = res.data;

      let filteredResults = payments;
      if (selectedEmployeeNo) {
        filteredResults = filteredResults.filter(
          (payment) => payment.empNo === selectedEmployeeNo
        );
      }
      if (selectedCompany) {
        filteredResults = filteredResults.filter(
          (payment) => payment.workLocation === selectedCompany
        );
      }
      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (payment) => payment.employeeActive === selectedActive
        );
      }
      if (startDate && endDate) {
        filteredResults = filteredResults.filter(
          (payment) =>
            new Date(payment.uploadDate) >= new Date(startDate) &&
            new Date(payment.uploadDate) <= new Date(endDate)
        );
      }
      setSearchResults(filteredResults);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  };
  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };
  const handleEmployeeNoFilter = (selectedOption) => {
    setSelectedEmployeeNo(selectedOption ? selectedOption.value : '');
  };
  

  const prepareCSVData = () => {
    if (selectedEmployeeNo && selectedCompany) {
      const headers = [
        'Emp No',
        'Epf No',
        'Emp Name',
        'NIC',
        'Work Location',
        'Month',
        'Gross Amount',
        'EPF(8%)',
        'EPF(12%)',
        'ETF(3%)',
      ];
  
      // Generate rows for each payment
      const rows = searchResults.map((payment) => [
        payment.empNo,
        payment.epfNo,
        payment.fullName,
        payment.nic,
        payment.workLocation,
        payment.uploadDate,
        payment.grossAmount,
        payment.epf8,
        payment.epf12,
        payment.etf3,
      ]);
  
      // Combine headers and rows
      const csvData = [headers, ...rows];
  
      // Convert to CSV format
      const csv = csvData.map(row => row.join(',')).join('\n');
      return csv;
    }
  
    if (selectedCompany) {
      const headers = [
        'Emp No',
        'Epf No',
        'Emp Name',
        'NIC',
        'Month',
        'Gross Amount',
        'EPF(8%)',
        'EPF(12%)',
        'ETF(3%)',
      ];
  
      // Generate rows for each payment
      const rows = searchResults.map((payment) => [
        payment.empNo,
        payment.epfNo,
        payment.fullName,
        payment.nic,
        payment.uploadDate,
        payment.grossAmount,
        payment.epf8,
        payment.epf12,
        payment.etf3,
      ]);
  
      // Combine headers and rows
      const csvData = [headers, ...rows];
  
      // Convert to CSV format
      const csv = csvData.map(row => row.join(',')).join('\n');
      return csv;
    }
    
    // Return empty string if neither selectedEmployeeNo nor selectedCompany is set
    return '';
  };

  return (
    <div className="main-body">
      <Sidebar />
      <div className="report">
        <h1>EPF Report</h1>
        <div className="filter_section">
          <div className="row mb-3">
            <div className="col-md-4">
              <div className="company-filter">
                <label htmlFor="companyFilter">Filter by Work Location:</label>
                <select
                  id="companyFilter"
                  value={selectedCompany}
                  className="form-select"
                  onChange={handleCompanyFilter}
                >
                  <option value="">All</option>
                  {companyNames.map((company, index) => (
                    <option key={index} value={company}>
                      {company}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((activestatus) => (
                  <option key={activestatus} value={activestatus}>
                    {activestatus}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-4'>
              <label htmlFor='employeeNoFilter'>Employee No:</label>
              <Select
                id='empNo'
                value={empnos.find(
                  (option) => option.value === selectedEmployeeNo
                )}
                options={empnos}
                isClearable
                onChange={handleEmployeeNoFilter}
                placeholder='Select or type Employee No'
                isSearchable
              />
            </div>
          </div>
          <div className="row mb-3">
  <div className="col-md-4">
    <div className="row mb-3">
      <label htmlFor="dateRangeFilter">Start Date:</label>
      <div className="col-md-8">
        <div className="start-date-filter">
          <select
            id="startDateFilter"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="form-select"
          >
            <option value="">Select Start Date</option>
            {Array.from(dete).map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </div>
  <div className="col-md-4">
    <div className="row mb-3">
      <label htmlFor="dateRangeFilter">End Date:</label>
      <div className="col-md-8">
        <div className="end-date-filter">
          <select
            id="endDateFilter"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="form-select"
          >
            <option value="">Select End Date</option>
            {Array.from(dete).map((date, index) => (
              <option key={index} value={date}>
                {date}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  </div>
</div>

          <button className="filter" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>
        <div>
        <table className="table">
  <thead>
    <tr>
      <th>Emp No</th>
      <th>Epf No</th>
      <th>Emp Name</th>
      <th>NIC</th>
      <th>Work Location</th>
      <th>Month</th>
          <th>Gross Amount</th>
          <th>EPF(8%)</th>
          <th>EPF(12%)</th>
          <th>ETF(3%)</th>
    </tr>
  </thead>
  <tbody>
              {searchResults.map((payment, index) => (
                <tr key={index}>
                  <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.fullName}</td>
                  <td>{payment.nic}</td>
                  <td>{payment.workLocation}</td>
                  <td>{payment.uploadDate}</td>
                  <td>{payment.grossAmount}</td>
                  <td>{payment.epf8}</td>
                  <td>{payment.epf12}</td>
                  <td>{payment.etf3}</td>
                </tr>
              ))}
            </tbody>


</table>


        </div>
        <div className='repos'>
  <div>
    <CSVLink
      data={prepareCSVData(startDate, endDate)} // Pass startDate and endDate to prepareCSVData
      filename="Epf_report.csv"
      className="csv" // Add a className for styling
      target="_blank"
    >
      Genarate CSV
    </CSVLink>
  </div>
</div>
      </div>
    </div>
  );
}
