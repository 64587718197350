import React, { useState } from 'react';
import axios from 'axios';
import './Addworklocation.css';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function Addworklocationn() {
  const [worklocationData, setWorkLocationData] = useState({
    desCode: '',
    desName: '',
    desDescription: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWorkLocationData({
      ...worklocationData,
      [name]: value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token =Cookies.get('token');
      await axios.post('https://backhris.talentfort.live/worklocations', worklocationData,{
        headers: {
          Authorization: token,
        },
      });
      alert('Worklocation added successfully.');
      // Navigate to the previous page or handle redirection as needed
      window.history.back();
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setWorkLocationData({
      workCode: '',
      workName: '',
      workDescription: '',
    });
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className="addworklocation">
        <h1>Add Worklocation</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="workCode" className="form-label">
            Worklocation Code
            </label>
            <input
              type='text'
              className="form-control"
              id="workCode"
              name="workCode"
              value={worklocationData.workCode}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workName" className="form-label">
            Worklocation Name
            </label>
            <input
              type="text"
              className="form-control"
              id="workName"
              name="workName"
              value={worklocationData.workName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="workDescription" className="form-label">
            Worklocation Description
            </label>
            <input
              type="text"
              className="form-control"
              id="workDescription"
              name="workDescription"
              value={worklocationData.workDescription}
              onChange={handleChange}
            />
          </div>

          <div className="buttonsaddworklocation">
            <button
              type="submit"
              className="btn btn-primary shadow save-button"
              onClick={handleClick}
            >
              Save
            </button>
            <button
              type="reset"
              className="btn btn-danger reset-button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
