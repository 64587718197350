import React, { useState } from 'react';
import axios from 'axios';
import Sidebar from '../component/Sidebar';
import './Upload.css';

export default function TransactionSearch() {
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [empCode, setEmpCode] = useState('');
  const [pageSize, setPageSize] = useState('');
  const [department, setDepartment] = useState('');
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const params = new URLSearchParams();

      if (startTime) params.append('start_time', startTime);
      if (endTime) params.append('end_time', endTime);
      if (empCode) params.append('emp_code', empCode);
      if (pageSize) params.append('page_size', pageSize);
      if (department) params.append('department', department);

      const response = await axios.get(`https://backhris.talentfort.live/transaction?${params.toString()}`);
      
      // Sort results by punch_time in descending order
      const sortedResults = response.data.sort((a, b) => new Date(b.punch_time) - new Date(a.punch_time));

      setResults(sortedResults);
      setError(null);
    } catch (err) {
      setError('Error accessing transaction data');
      setResults(null);
      console.error(err);
    }
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className='uploadcsv'>
        <h1>Attendance Transactions</h1>
        <form onSubmit={handleSubmit}>
          <div className='row mb-3'>
            <div className="col-md-4">
              <label>Start Time:</label>
              <input type="date" value={startTime} onChange={(e) => setStartTime(e.target.value)} />
            </div>
            <div className="col-md-4">
              <label>End Time:</label>
              <input type="text" value={endTime} onChange={(e) => setEndTime(e.target.value)} />
            </div>
            <div className="col-md-4">
              <label htmlFor="employeeCode">Employee Code:</label>
              <input type="text" value={empCode} onChange={(e) => setEmpCode(e.target.value)} />
            </div>
          </div>
          <div className='row mb-3'>
            <div className="col-md-4">
              <label>Page Size:</label>
              <input type="number" value={pageSize} onChange={(e) => setPageSize(e.target.value)} />
            </div>
            <div className="col-md-4">
              <label>Department:</label>
              <input type="text" value={department} onChange={(e) => setDepartment(e.target.value)} />
            </div>
            <div className="col-md-2">
              <button className="searchbtn">Search</button>
            </div>
          </div>
        </form>

        {error && <p style={{ color: 'red' }}>{error}</p>}
        {results && (
          <div>
            <h2>Results</h2>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Employee Code</th>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Department</th>
                  <th>Punch Time</th>
                  <th>Punch State</th>
                  <th>Verify Type</th>
                  <th>Area Alias</th>
                  <th>Terminal Alias</th>
                  <th>Upload Time</th>
                </tr>
              </thead>
              <tbody>
                {results.map(result => (
                  <tr key={result.id}>
                    <td>{result.emp_code}</td>
                    <td>{result.first_name}</td>
                    <td>{result.last_name}</td>
                    <td>{result.department}</td>
                    <td>{result.punch_time}</td>
                    <td>{result.punch_state_display}</td>
                    <td>{result.verify_type_display}</td>
                    <td>{result.area_alias}</td>
                    <td>{result.terminal_alias}</td>
                    <td>{result.upload_time}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};
