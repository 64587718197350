
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './Updateemployee.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';

export default function UpdateEmployee() {
  const [designationNames, setDesignationNames] = useState([]);
  const [companyNames, setCompanyNames] = useState([]);
  const [employee, setEmployee] = useState({
    fullName: '',
    title: '',
    displayName:'',
    dob: '',
    gender: '',
    nic: '',
    phone: '',
    email: '',
    currentAddress: '',
    salary: '',
    employeeType: '',
    designation: '',
    joinedDate: '',
    empNo: '',
    epfNo: '',
    contractStartDate: '',
    contractEndDate: '',
    workLocation: '',
    accountNo: '',
    bankName: '',
    bankCode: '',
    branchName: '',
    branchCode: '',
    employeeActive: '',
  });

  const location = useLocation();

  const id = location.pathname.split('/')[2];

  const handleChange = (e) => {
    const { name, value, type } = e.target;
  
    if (type === 'radio') {
      // Handle radio buttons differently
      setEmployee((prev) => ({ ...prev, [name]: value }));
    } else {
      if (name === 'salary' || name === 'payGrade') {
        // Handle salary and payGrade inputs to calculate grossSalary
        const salary = parseFloat(employee.salary) || 0;
        const payGrade = parseFloat(employee.payGrade) || 0;
        const grossSalary = salary + payGrade;
        setEmployee((prev) => ({ ...prev, [name]: value, grossSalary }));
      } else {
        setEmployee((prev) => ({ ...prev, [name]: value }));
      }
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token =Cookies.get('token');
      await axios.put(`https://backhris.talentfort.live/employees/${id}`, employee,{
        headers: {
          Authorization: token,
        },
      });
      alert('Employee updated successfully.'); // You can show a success message if needed.
        
        // Navigate to the previous page with a reload
        window.location.href = '/employee';
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    try {
      const fetchDesignations = async () => {
        try {
          const token =Cookies.get('token');
          const response = await axios.get('https://backhris.talentfort.live/designations',{
            headers: {
              Authorization: token,
            },
          });
          const designations = response.data;
          const designationsNames = designations.map(designation => designation.desName); // Using 'companyName' property from API response
          setDesignationNames(designationsNames);
        } catch (error) {
          console.error('Error fetching Designations:', error);
        }
      };
      fetchDesignations();
    } catch (error) {
      console.error('Error fetching Designations:', error);
    }
  }, []);

  useEffect(() => {
    const fetchEmployee = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get(`https://backhris.talentfort.live/employees/${id}`,{
          headers: {
            Authorization: token,
          },
        });
        setEmployee(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchEmployee();
    }, [id]);
    useEffect(() => {
      try {
        const fetchCompanies = async () => {
          try {
            const token =Cookies.get('token');
            const response = await axios.get('https://backhris.talentfort.live/companies',{
              headers: {
                Authorization: token,
              },
            });
            const companies = response.data;
            const companyNames = companies.map(company => company.workName); // Using 'companyName' property from API response
            setCompanyNames(companyNames);
          } catch (error) {
            console.error('Error fetching companies:', error);
          }
        };
        fetchCompanies();
      } catch (error) {
        console.error('Error fetching companies:', error);
      }
    }, []);

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
    <div className="updateemployee">
      <h1>Update Employee</h1>
      <form>
 <div className='personal_details col-lg-10'>
  <h3>Personal Details</h3>     
<div className='row'>
<div className="col-lg-12">
  <label htmlFor="fullName" className="form-label">
    Full Name
  </label>
  <input
    type="text"
    className="form-control"
    id="fullName"
    name="fullName" 
    placeholder="Enter FullName"
    value={employee.fullName}
    onChange={handleChange}
    required
  />
</div>
</div>
<div className='row'>
<div className="col-lg-6 mb-1">
  <label htmlFor="title" className="form-label">
    Title       
  </label>
  <select
    className="form-control"
    id="title"
    name="title"
    onChange={handleChange}
    value={employee.title}
  >
    <option value="">Select Title</option>
    <option value="Mr">Mr.</option>
    <option value="Ms">Ms.</option>
    <option value="Mrs">Mrs.</option>
    <option value="Miss">Miss.</option>
  </select>
</div>
<div className="col-lg-6">
    <div className="mb-1">
      <label htmlFor="displayName" className="form-label">
        Display Name
      </label>
      <input
        type="text"
        className="form-control"
        id="displayName"
        name="displayName"
        onChange={handleChange}
        value={employee.displayName}
      />
    </div>
    </div>

</div>


<div className='row mb-1'>

<div className="col-lg-6">
  <label htmlFor="dob" className="form-label">
    Date of Birth
  </label>
  <input
    type="date"
    className="form-control"
    id="dob"
    name="dob"
    onChange={handleChange}
    value={employee.dob}
  />
</div>
<div className=" col-lg-6">
  <div className='gender_full'>
  <label className="form-label">Gender</label>
  <div className='gender_cat'>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      id="gender"
      name="gender"
      value="M"
      onChange={handleChange}
      checked={employee.gender === 'M'}
    />
    <label className="form-check-label" htmlFor="gender">
      Male
    </label>
  </div>
  <div className="form-check">
    <input
      className="form-check-input"
      type="radio"
      id="gender"
      name="gender"
      value="F"
      onChange={handleChange}
      checked ={employee.gender === 'F'}
    />
    <label className="form-check-label" htmlFor="gender">
      Female
    </label>
  </div>
  </div>
  
  </div>
  
</div>

</div>

        
<div className='row mb-1'>
<div className="col-lg-6">
  <label htmlFor="nic" className="form-label">
    NIC Number
  </label>
  <input
    type="text"
    className="form-control"
    id="nic"
    name="nic"
    onChange={handleChange}
    value={employee.nic}
  />
</div>
<div className="col-lg-6 mb-1">
      <label htmlFor="phone" className="form-label">Phone Number</label>
      <input
        type="tel"
        className="form-control"
        id="phone"
        name="phone"
        onChange={handleChange}
        value={employee.phone}
      />
    </div>
</div>



<div className='row mb-1'>
    <div className="col-lg-6">
  <label htmlFor="email" className="form-label">
    Email
  </label>
  <input
    type="text"
    className="form-control"
    id="email"
    name="email"
    value={employee.email}
    onChange={handleChange}
  />
</div>
    </div>
    <div className=" col-lg-12 mb-1">
  <label htmlFor="currentAddress" className="form-label">Current Address</label>
  <textarea
    className="form-control"
    id="currentAddress"
    name="currentAddress"
    onChange={handleChange}
    value={employee.currentAddress}
  ></textarea>
</div>
</div>

<div className='company_details col-lg-10'>
<h3>Company Details</h3>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="empNo" className="form-label">Emp Code</label>
      <input
        type="text"
        className="form-control"
        id="empNo"
        name="empNo"
        onChange={handleChange}
        value={employee.empNo}
        readOnly
      />
    </div>
    <div className="col-lg-6">
      <label htmlFor="epfNo" className="form-label">EPF No</label>
      <input
        type="text"
        className="form-control"
        id="epfNo"
        name="epfNo"
        onChange={handleChange}
        value={employee.epfNo}
        readOnly
      />
    </div>
</div>

<div className='row mb-1'>
  <div className="col-lg-6">
    <label htmlFor="designation" className="form-label">
      Designation
    </label>
    <select
      className="form-control"
      id="designation"
      name="designation" 
      onChange={handleChange}
      value={employee.designation} // Set this to the current value
    >
      <option value="">Select Designation</option>
      {designationNames.map((designationName, index) => (
        <option key={index} value={designationName}>
          {designationName}
        </option>
      ))}
    </select>
  </div>

  <div className="col-lg-6">
    <label htmlFor="employeeActive" className="form-label">
      Employee Active
    </label>
    <div className="employeetype_cat">
  <div className="form-check">
  <input
    className="form-check-input"
    type="radio"
    id="employeeActive"
    name="employeeActive"
    value="Active"
    onChange={handleChange}
    checked={employee.employeeActive === "Active"}
  />
  <label className="form-check-label" htmlFor="employeeActive">
    Active
  </label>
</div>
<div className="form-check">
  <input
    className="form-check-input"
    type="radio"
    id="employeeActive"
    name="employeeActive"
    value="Inactive"
    onChange={handleChange}
    checked={employee.employeeActive === "Inactive"}
  />
  <label className="form-check-label" htmlFor="employeeActive">
    Inactive
  </label>
</div>
<div className="form-check">
  <input
    className="form-check-input"
    type="radio"
    id="employeeActive"
    name="employeeActive"
    value="Terminate Inprogress"
    onChange={handleChange}
    checked={employee.employeeActive === "Terminate Inprogress"}
  />
  <label className="form-check-label" htmlFor="employeeActive">
  Terminate Inprogress
  </label>
</div>
</div>
  </div>
</div>


<div className='row mb-1'>
<div className="col-lg-6 mb-1">
  <label htmlFor="joinedDate" className="form-label">
    Joined Date
  </label>
  <input
    type="date"
    className="form-control"
    id="joinedDate"
    name="joinedDate"
    onChange={handleChange}
    value={employee.joinedDate}
  />
</div>
<div className="col-lg-6">
  <div className='employeetype_full'>
  <label className="form-label">Employee Type</label>
  <div className="employeetype_cat">
  <div className="form-check">
  <input
    className="form-check-input"
    type="radio"
    id="employeeType"
    name="employeeType"
    value="Permanent"
    onChange={handleChange}
    checked={employee.employeeType === "Permanent"}
  />
  <label className="form-check-label" htmlFor="employeeType">
    Permenant
  </label>
</div>
<div className="form-check">
  <input
    className="form-check-input"
    type="radio"
    id="employeeType"
    name="employeeType"
    value="Contract"
    onChange={handleChange}
    checked={employee.employeeType === "Contract"}
  />
  <label className="form-check-label" htmlFor="employeeType">
    Contract
  </label>
</div>
</div>
</div>
</div>
</div>
<div className='row mb-1'>
<div className="col-lg-6 mb-1">
  <label htmlFor="contractstartDate" className="form-label">
    Contract Start Date
  </label>
  <input
    type="date"
    className="form-control"
    id="contractstartDate"
    name="contractstartDate"
    onChange={handleChange}
    value={employee.contractstartDate}
  />
</div>
<div className="col-lg-6 mb-1">
  <label htmlFor="contractendDate" className="form-label">
    Contract End Date
  </label>
  <input
    type="date"
    className="form-control"
    id="contractendDate"
    name="contractendDate"
    onChange={handleChange}
    value={employee.contractendDate}
  />
</div>
</div>
<div className="row mb-1">
<div className="col-lg-6">
      <label htmlFor="workLocation" className="form-label">Work Location</label>
      <select
        className="form-control"
        id="workLocation"
        name="workLocation"
        onChange={handleChange}
        value={employee.workLocation}
      >
      <option value="">Select worklocation</option>
      {companyNames.map((companyName, index) => (
        <option key={index} value={companyName}>
          {companyName}
        </option>
      ))}
       </select>
    </div>
    <div className="col-lg-6">
      <label htmlFor="location" className="form-label">Location</label>
      <input
        type="text"
        className="form-control"
        id="location"
        name="location"
        onChange={handleChange}
        value={employee.location}
      />
    </div>

</div>
<div className="row mb-1">
<div className="col-lg-6">
      <label htmlFor="salary" className="form-label">Basic Salary</label>
      <input
        type="text"
        className="form-control"
        id="salary"
        name="salary"
        onChange={handleChange}
        value={employee.salary}
      />
    </div>
<div className="col-lg-6">
    <label className="form-label">Bud. Allowance</label>
      <input
        type="text"
        className="form-control"
        id="payGrade"
        name="payGrade"
        onChange={handleChange}
        value={employee.payGrade}
      />
</div>
</div>
<div className="row mb-1">
<div className="col-lg-6">
    <label className="form-label">Fix. Allowance</label>
      <input
        type="text"
        className="form-control"
        id="fixAllowance"
        name="fixAllowance"
        onChange={handleChange}
        value={employee.fixAllowance}
      />
</div>
<div className="col-lg-6 mb-1">
  <label htmlFor="endDate" className="form-label">
    End Date
  </label>
  <input
    type="date"
    className="form-control"
    id="endDate"
    name="endDate"
    onChange={handleChange}
    value={employee.endDate}
  />
</div>
</div>
</div>
<div className='bank_details col-lg-10'>
<h3>Bank Details</h3>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="accountNo" className="form-label">Account No</label>
      <input
        type="text"
        className="form-control"
        id="accountNo"
        name="accountNo"
        onChange={handleChange}
        value={employee.accountNo}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="bankName" className="form-label">Bank Name</label>
      <input
        type="text"
        className="form-control"
        id="bankName"
        name="bankName"
        onChange={handleChange}
        value={employee.bankName}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="bankCode" className="form-label">Bank Code</label>
      <input
        type="text"
        className="form-control"
        id="bankCode"
        name="bankCode"
        onChange={handleChange}
        value={employee.bankCode}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="branchName" className="form-label">Branch Name</label>
      <input
        type="text"
        className="form-control"
        id="branchName"
        name="branchName"
        onChange={handleChange}
        value={employee.branchName}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="branchCode" className="form-label">Branch Code</label>
      <input
        type="text"
        className="form-control"
        id="branchCode"
        name="branchCode"
        onChange={handleChange}
        value={employee.branchCode}
      />
    </div>
</div>
</div>
<div className='dependant_details col-lg-10'>
<h3>Dependent Details</h3>
<div className='dependant_details'>
<h4>Dependent 1:</h4>
<div className='row'>
<div className="col-lg-12">
  <label htmlFor="dep_fullName" className="form-label">
    Full Name
  </label>
  <input
    type="text"
    className="form-control"
    id="dep_fullName"
    name="dep_fullName" 
    placeholder="Enter Dep_FullName"
    onChange={handleChange}
    value={employee.dep_fullName}
  />
</div>
</div>


<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_name" className="form-label">Name with Initials</label>
      <input
        type="text"
        className="form-control"
        id="dep_name"
        name="dep_name"
        onChange={handleChange}
        value={employee.dep_name}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="relationship" className="form-label">Relationship</label>
      <input
        type="text"
        className="form-control"
        id="relationship"
        name="relationship"
        placeholder=""
        onChange={handleChange}
        value={employee.relationship}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_nic" className="form-label">NIC</label>
      <input
        type="text"
        className="form-control"
        id="dep_nic"
        name="dep_nic"
        onChange={handleChange}
        value={employee.dep_nic}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="dep_dob" className="form-label">Date of Birth</label>
      <input
         type="date"
        className="form-control"
        id="dep_dob"
        name="dep_dob"
        placeholder=""
        onChange={handleChange}
        value={employee.dep_dob}
      />
    </div>
</div>
<div className=" col-lg-12 mb-1">
  <label htmlFor="dep_currentAddress" className="form-label">Current Address</label>
  <textarea
    className="form-control"
    id="dep_currentAddress"
    name="dep_currentAddress"
    placeholder="Enter dependent permenant address"
    onChange={handleChange}
    value={employee.dep_currentAddress}
  ></textarea>
</div>
</div>
<div className='dependant_details'>
<h4>Dependent 2:</h4>
<div className='row'>
<div className="col-lg-12">
  <label htmlFor="dep_fullName1" className="form-label">
    Full Name
  </label>
  <input
    type="text"
    className="form-control"
    id="dep_fullName1"
    name="dep_fullName1" 
    placeholder="Enter Dep_FullName"
    onChange={handleChange}
    value={employee.dep_fullName1}
  />
</div>
</div>


<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_name1" className="form-label">Name with Initials</label>
      <input
        type="text"
        className="form-control"
        id="dep_name1"
        name="dep_name1"
        onChange={handleChange}
        value={employee.dep_name1}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="relationship1" className="form-label">Relationship</label>
      <input
        type="text"
        className="form-control"
        id="relationship1"
        name="relationship1"
        placeholder=""
        onChange={handleChange}
        value={employee.relationship1}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_nic1" className="form-label">NIC</label>
      <input
        type="text"
        className="form-control"
        id="dep_nic1"
        name="dep_nic1"
        onChange={handleChange}
        value={employee.dep_nic1}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="dep_dob1" className="form-label">Date of Birth</label>
      <input
         type="date"
        className="form-control"
        id="dep_dob1"
        name="dep_dob1"
        placeholder=""
        onChange={handleChange}
        value={employee.dep_dob1}
      />
    </div>
</div>
<div className=" col-lg-12 mb-1">
  <label htmlFor="dep_currentAddress1" className="form-label">Current Address</label>
  <textarea
    className="form-control"
    id="dep_currentAddress1"
    name="dep_currentAddress1"
    placeholder="Enter dependent permenant address"
    onChange={handleChange}
    value={employee.dep_currentAddress1}
  ></textarea>
</div>
</div>
<div className='dependant_details'>
<h4>Dependent 3:</h4>
<div className='row'>
<div className="col-lg-12">
  <label htmlFor="dep_fullName2" className="form-label">
    Full Name
  </label>
  <input
    type="text"
    className="form-control"
    id="dep_fullName2"
    name="dep_fullName2" 
    placeholder="Enter Dep_FullName"
    onChange={handleChange}
    value={employee.dep_fullName2}
  />
</div>
</div>


<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_name2" className="form-label">Name with Initials</label>
      <input
        type="text"
        className="form-control"
        id="dep_name2"
        name="dep_name2"
        onChange={handleChange}
        value={employee.dep_name2}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="relationship2" className="form-label">Relationship</label>
      <input
        type="text"
        className="form-control"
        id="relationship2"
        name="relationship2"
        placeholder=""
        onChange={handleChange}
        value={employee.relationship2}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_nic2" className="form-label">NIC</label>
      <input
        type="text"
        className="form-control"
        id="dep_nic2"
        name="dep_nic2"
        onChange={handleChange}
        value={employee.dep_nic2}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="dep_dob2" className="form-label">Date of Birth</label>
      <input
         type="date"
        className="form-control"
        id="dep_dob2"
        name="dep_dob2"
        placeholder=""
        onChange={handleChange}
        value={employee.dep_dob2}
      />
    </div>
</div>
<div className=" col-lg-12 mb-1">
  <label htmlFor="dep_currentAddress2" className="form-label">Current Address</label>
  <textarea
    className="form-control"
    id="dep_currentAddress2"
    name="dep_currentAddress2"
    placeholder="Enter dependent permenant address"
    onChange={handleChange}
    value={employee.dep_currentAddress2}
  ></textarea>
</div>
</div>
<div className='dependant_details'>
<h4>Dependent 4:</h4>
<div className='row'>
<div className="col-lg-12">
  <label htmlFor="dep_fullName3" className="form-label">
    Full Name
  </label>
  <input
    type="text"
    className="form-control"
    id="dep_fullName3"
    name="dep_fullName3" 
    placeholder="Enter Dep_FullName"
    onChange={handleChange}
    value={employee.dep_fullName3}
  />
</div>
</div>


<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_name3" className="form-label">Name with Initials</label>
      <input
        type="text"
        className="form-control"
        id="dep_name3"
        name="dep_name3"
        onChange={handleChange}
        value={employee.dep_name3}
      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="relationship3" className="form-label">Relationship</label>
      <input
        type="text"
        className="form-control"
        id="relationship3"
        name="relationship3"
        placeholder=""
        onChange={handleChange}
        value={employee.relationship3}
      />
    </div>
</div>
<div className='row mb-1'>
<div className="col-lg-6">
      <label htmlFor="dep_nic3" className="form-label">NIC</label>
      <input
        type="text"
        className="form-control"
        id="dep_nic3"
        name="dep_nic3"
        onChange={handleChange}
        value={employee.dep_nic3}

      />
    </div>
        <div className="col-lg-6">
      <label htmlFor="dep_dob3" className="form-label">Date of Birth</label>
      <input
         type="date"
        className="form-control"
        id="dep_dob3"
        name="dep_dob3"
        placeholder=""
        onChange={handleChange}
        value={employee.dep_dob3}
      />
    </div>
</div>
<div className=" col-lg-12 mb-1">
  <label htmlFor="dep_currentAddress3" className="form-label">Current Address</label>
  <textarea
    className="form-control"
    id="dep_currentAddress3"
    name="dep_currentAddress3"
    placeholder="Enter dependent permenant address"
    onChange={handleChange}
    value={employee.dep_currentAddress3}
  ></textarea>
</div>
</div>
</div>
<div className="">
<button type="submit" className="updt" onClick={handleClick}>
          Update
        </button>
</div>

      </form>
    </div>
    </div>
  );
}
