import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'jspdf-autotable';
import './Paymentdetails.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';
import { CSVLink } from 'react-csv';

export default function Paymentdetails() {
  const [data, setData] = useState([]);
  const [genarateDate, setGenarateDate] = useState(''); 
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [generateMonth, setGenerateMonth] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/paymentdata/terminate',{
          headers: {
            Authorization: token,
          },
        });
        setData(response.data); // Set data to the entire response
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchPayments();
  }, []);
  useEffect(() => {
    const fetchCompanyNames = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        const companyNamesSet = new Set();

        res.data.forEach((payment) => {
          companyNamesSet.add(payment.workLocation);
        });

        setCompanyNames(Array.from(companyNamesSet));
      } catch (error) {
        console.log(error);
      }
    };
    fetchCompanyNames();
  }, []);

  const handleCheckboxChange = (empNo) => {
    if (selectAll) {
      // If "Select All" is checked, toggle individual row selection
      const newSelectedRows = selectedRows.includes(empNo)
        ? selectedRows.filter((selectedEmpNo) => selectedEmpNo !== empNo)
        : [...selectedRows, empNo];
      setSelectedRows(newSelectedRows);
    } else {
      // Toggle individual row selection
      const newSelectedRows = [...selectedRows];
      const index = newSelectedRows.indexOf(empNo);
  
      if (index === -1) {
        newSelectedRows.push(empNo);
      } else {
        newSelectedRows.splice(index, 1);
      }
  
      setSelectedRows(newSelectedRows);
    }
  };
  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);
    setSelectedRows(selectAll ? [] : data.map((payment) => payment.empNo));
  };
  const handleSave = async () => {
    try {
       // Validate if generateDate and generateMonth are selected
       if (!genarateDate ) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Generate Date" before generating.');
        return;
      }
      if (!generateMonth) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Payment Month" before generating.');
        return;
      }
      if (!selectedCompany) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Work Location" before generating.');
        return;
      }
      const unselectedData = data.filter((payment) => !selectedRows.includes(payment.empNo));
      const token =Cookies.get('token');
      // Send a single POST request with unselected payment data
      await axios.post('https://backhris.talentfort.live/payments/terminate', {
        payments: unselectedData,
        generateMonth: generateMonth,
        generateDate: genarateDate,
      },{
        headers: {
          Authorization: token,
        }
      });

      // Display success alert before redirecting
      alert('Payment data saved successfully.');
      window.location.href = '/paymentreport';
    } catch (error) {
      console.error('Error saving unselected payment data:', error);
    }
  };
  const handleUpdate = async () => {
    try {
       // Validate if generateDate and generateMonth are selected
       if (!genarateDate ) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Generate Date" before generating.');
        return;
      }
      if (!generateMonth) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Payment Month" before generating.');
        return;
      }
      if (!selectedCompany) {
        // If either generateDate or generateMonth is not selected, display an error message
        alert('Please select "Work Location" before generating.');
        return;
      }
      const unselectedData = data.filter((payment) => !selectedRows.includes(payment.empNo));
      const token =Cookies.get('token');
      // Send a single PUT request with selected payment data
      await axios.put('https://backhris.talentfort.live/payments', {
        payments: unselectedData,
        generateMonth: generateMonth,
        generateDate: genarateDate,
      },{
        headers: {
          Authorization: token,
        }
      
      });
  
      alert('Payment data updated successfully.');
      window.location.href = '/closepaymentdetails';
    } catch (error) {
      console.error('Error updating payment data:', error);
    }
  };
  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  }
  const prepareCSVData = () => {
    // Define common headers
    const commonHeaders = [
      'Emp No',
      'Epf No',
      'Emp Name',
      'Bank Code',
      'Branch Code',
      'Account Number',
      
     
      
    ];
  
    // Define headers specific to each company along with their corresponding backend API values
    const headersMapping = {
      'Emp No': 'empNo',
      'Epf No': 'epfNo',
      'Emp Name': 'displayName',
      'Basic Salary': 'salary',
      'Budgetary Allowance': 'payGrade',
      'Consolidated Salary': 'consolidateSalary',
      'Working Days' : 'workingDays',
      'EPF(12%)': 'epf12',
      'EPF(8%)': 'epf8',
      'ETF(3%)': 'etf3',

      'Motivation Alllowance': 'motivationAllowance',
      'Salary Arrears': 'salaryArrears',
      'OT Allowance': 'overtimeAllowance',
      'DOT Arrears': 'dotArrears',
      'Extraworking Payment': 'extraworkingPayment',
      'Actin Allowance': 'actinAllowance',
      'Transport Allowance': 'transportAllowance',
      'Performance Allowance': 'performanceAllowance',
      'Sales Commision': 'salesCommission',
      'Coveringup Allowance': 'coveringupAllowance',
      'OT Arrears': 'overtimeArrears',
      'DOT Allowance': 'dotAllowance',
      'Salary Advance': 'advance',
      'Account Number': 'accountNo',
      'Bank Code': 'bankCode',
      'Salary Advance Deduction': 'advanceDeduction',
      'Branch Code': 'branchCode',
      'Nopay Amount': 'noPay',  
      'Gross Salary': 'grossAmount',
      'Total Allowance': 'totalAllowance',
      'Total Earning': 'totalEarning',
      'Stamp Duty': 'stampDuty',
      'Payee Tax': 'payeetax',
      'Total Deduction': 'totalDeduction',
      'Net Salary': 'netSalary',
      'Experience Allowance': 'experienceAllowance',
      'Festival Advance': 'festivalAdvance',
      'Admin Allowance': 'adminAllowance',
      'Mobile Allowance': 'mobileAllowance',
      'Fix Allowance': 'fixAllowance',
      'Loan' : 'loan',
      'Other Allowance' : 'otherAllowance',
      'Fes Advance Deduction' : 'festivaladvanceDeduction',
      'Loan Deduction' : 'loanDeduction',
      'Other Deduction' : 'otherDeduction',
      'Sales Incentive' : 'salesIncentive',
      'Sales Driver Inc1' : 'salesdriveIncentive1',
      'Sales Driver Inc2' : 'salesdriveIncentive2',
      'Sales Driver Inc3' : 'salesdriveIncentive3',
      'Welfare' : 'welfare',
      'Welfare Deduction' : 'welfareDeduction',
      'Mobile Deduction': 'mobileDeduction',
      'BD Commission': 'bdCommission',
      'Fuel Allowance':'fuelAllowance',
      'Mng Supervisor Allowance': 'mngSupervisorAllowance',
      'Supervisor Commission': 'supervisorCommission',
      'Sala Commission': 'salaCommission',
      'KLM Commission': 'klmCommission',
      'Mobitel Commission': 'mobitelCommission',
      'Peo Tv Remote Commission': 'peoTvRemoteCommission',
      'Memory Tax Commission': 'memoryTaxCommission',
      'Grooming Training Deduction': 'groomingTrainingDeduction',
      'AJ Commission': 'ajCommission',
      'Cpe Arrears Collecting Commission': 'cpeArrearsCollectingCommission',
      'Cpe Collecting Commission': 'cpeCollectingCommission',
      'commission Arrears': 'commissionArrears',
      'Stamp Dutyplus': 'stampDutyplus',
      'Previous Tax': 'previousTax',
      'Other Reimbursement': 'otherReimbursement',
      'Goya Payment':'goyaPayment',
      'InternAllowance':'internAllowance',
      'Intern Allowance Arrears':'internallowanceArrears',
      'Other Reimbursement Arrears':'otherreimbursementArrears',
      'Sales Commission Arrears':'salescommissionArrears',
      'Food Allowance':'foodAllowance',
      'Special Allowance':'specialAllowance',
      'Transport Allowance Arrears':'transportallowanceArrears',
      'Food Arrears':'foodArrears',
      'Night Alllowance':'nightAlllowance',
      'Data Allowance':'dataAllowance',
      'Performance Allowance 2':'performanceAllowance2',
      'Attendence Allowance':'attendenceAllowance',
      'Holiday Payment':'holidayPayment',
      'Transport Allowance2':'transportAllowance2',
      'Full Bata Allowance': 'fullbataAllowance'
      // Add more mappings as needed
    };
  
    // Define headers specific to each company
    const companySpecificHeaders = {
      Direct: [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
      'Nopay Amount',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',

        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,

        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'direct' company
      ],
      'Edinborough Products (Pvt) Ltd': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Sales Incentive',
        'Sales Driver Inc1',
        'Sales Driver Inc2',
        'Sales Driver Inc3',
        'Extraworking Payment',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Salary Advance',
        'Festival Advance',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Welfare',
        'Welfare Deduction',
        'Mobile Deduction',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
      
        // ... other headers for the 'edingburugh' company
      ],
      'RDB Call Centre': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Loan',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,
        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Water Board Call Centre': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Loan',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,
        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Sales Team': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Franchise Operation': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Regional Business Development Manager': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Ayathi Trust': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'direct' company
      ],
      'Hemas SR':[
    'Basic Salary',
    'Budgetary Allowance',
    'Nopay Amount',
    'Consolidated Salary',
    'Gross Salary',
    'Working Days' ,
    'Salary Arrears',
    'Sales Commision',
    'Other Reimbursement',
    'Goya Payment',
    'Fuel Allowance',
    'Festival Advance',
    'Salary Advance',
    'Total Allowance',
    'Total Earning',
    'Fes Advance Deduction',
    'Salary Advance Deduction',
    'EPF(8%)',
    'Stamp Duty',
    'Payee Tax',
    'Total Deduction',
    'Net Salary',
    'EPF(12%)',
    'ETF(3%)',
      ],
    'Hemas SR-Intern':
    [
      'Basic Salary',
      'Budgetary Allowance',
      'Nopay Amount',
      'Consolidated Salary',
      'Gross Salary',
      'Working Days',
      'Salary Arrears',
      'Sales Commision',
      'InternAllowance',
      'Intern Allowance Arrears',
      'Other Reimbursement',
      'Sales Commission Arrears',
      'Other Reimbursement Arrears',
      'Fuel Allowance',
      'Festival Advance',
      'Salary Advance',
      'Total Allowance',
      'Total Earning',
      'Fes Advance Deduction',
      'Salary Advance Deduction',
      'EPF(8%)',
      'Stamp Duty',
      'Payee Tax',
      'Total Deduction',
      'Net Salary',
      'EPF(12%)',
      'ETF(3%)'
      ],
      'Hemas MA':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Performance Allowance',
        'Transport Allowance',
        'Food Allowance',
        'Special Allowance',
        'Festival Advance',
        'Salary Advance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MA-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Intern Allowance Arrears',
        'Performance Allowance',
        'Transport Allowance',
        'Food Allowance',
        'Special Allowance',
        'Transport Allowance Arrears',
        'Food Arrears',
        'Festival Advance',
        'Salary Advance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MC':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Attendence Allowance',
        'Performance Allowance',
        'Holiday Payment',
        'Transport Allowance',
        'Other Reimbursement',
        'Special Allowance',
        'Sales Commision',
        'Festival Advance',
        'Salary Advance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MC-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Attendence Allowance',
        'Performance Allowance',
        'Holiday Payment',
        'Transport Allowance',
        'Transport Allowance2',
        'Other Reimbursement',
        'Sales Commision',
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas SPA':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Attendence Allowance',
        'Performance Allowance',
        'Performance Allowance 2',
        'Transport Allowance',
        'Other Reimbursement',
        'Special Allowance',
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas SPA-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Performance Allowance',
        'Performance Allowance 2',
        'Transport Allowance',
        'Special Allowance',
        'Attendence Allowance',
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas FMCG':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'OT Allowance',
        'DOT Allowance',
        'Data Allowance',
        'Mobile Allowance',
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas Driver':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Amount',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'OT Allowance',
        'DOT Allowance',
        'Full Bata Allowance',
        'Night Alllowance',
        'Mobile Allowance',
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
    };
  
    
    



    const selectedCompanyHeaders = companySpecificHeaders[selectedCompany] || [];
  
    // Combine common headers with company-specific headers
    const headers = [...commonHeaders, ...selectedCompanyHeaders];
  
    // Map headers to backend API values
    const apiValues = headers.map((header) => headersMapping[header]);
  
    const csvData = data.map((payment) => [
      ...apiValues.map((apiValue) => payment[apiValue] || ''),
    ]);
  
    return [headers, ...csvData];
  }; 

  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Payment Details (Terminate Inprogress)</h1>
        <div className="row">
<div className="col-lg-3 mb-2">
  <label htmlFor="generateMonth" className="form-label">
    Payment Month
  </label>
  <input
    type="month"
    className="form-control"
    id="generateMonth"
    name="generateMonth"
    onChange={(e) => setGenerateMonth(e.target.value)}
  />
</div>
<div className="col-lg-3 mb-2">
  <label htmlFor="genarateDate" className="form-label">
    Genarate Date
  </label>
  <input
    type="date"
    className="form-control"
    id="genarateDate"
    name="genarateDate"
    onChange={(e) => setGenarateDate(e.target.value)} 
  />
</div>
<div className="col-lg-3 mb-2">
<label htmlFor="genarateDate" className="form-label">
Work Location
  </label>
                <select
                  id="companyFilter"
                  value={selectedCompany}
                  className="form-control"
                  onChange={handleCompanyFilter}
                  >
                    <option value="">All</option>
                    {companyNames.map((company, index) =>
                      <option key={index} value={company}>
                        {company}
                      </option>
                      )}
                  </select>
                  </div>
</div>
        <div>
          <table className="table">
            <thead>
              <tr>
              <th>
                  <input
                    type="checkbox"
                    onChange={handleSelectAllChange}
                    checked={selectAll}
                  />
                </th>
                <th>Emp No</th>
                <th>Epf No</th>
                <th>Emp Name</th>
                <th>Basic Salary</th>
                <th>Bud. Allowance</th>
                <th>Consolidate Salary</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Gross Salary</th>
                <th>Total Allowance</th>
                <th>Total Earning</th>
                <th>EPF(8%)</th>
                <th>Stamp Duty</th>
                <th>Total Deduction</th>
                <th>Net Salary</th>
                <th>EPF(12%)</th>
                <th>ETF(3%)</th>
                <th>Account No</th>

              
              </tr>
            </thead>
            <tbody>
              {data.map((payment) => (
                <tr key={payment.empNo}>
                  <td>
                    <input
                      type="checkbox"
                      onChange={() => handleCheckboxChange(payment.empNo)}
                      checked={selectedRows.includes(payment.empNo)}
                    />
                  </td>
                  <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.displayName}</td>
                  <td className="right-aligned">{payment.salary}</td>
                  <td className="right-aligned">{payment.payGrade}</td>
                  <td className="right-aligned">{payment.consolidateSalary}</td>
                  <td className="right-aligned">{payment.nopayAmount}</td>       
                  <td className="right-aligned">{payment.salaryArrears}</td>
                  <td className="right-aligned">{parseFloat(payment.grossAmount).toFixed(2)}</td>
                  <td className="right-aligned">{parseFloat(payment.totalAllowance).toFixed(2)}</td>
                  <td className="right-aligned">{payment.totalEarning}</td>
                  <td className="right-aligned">{payment.epf8}</td>
                  <td className="right-aligned">{payment.stampDuty}</td>
                  <td className="right-aligned">{parseFloat(payment.totalDeduction).toFixed(2)}</td>
                  <td className="right-aligned">{parseFloat(payment.netSalary).toFixed(2)}</td>
                  <td className="right-aligned">{payment.epf12}</td>
                  <td className="right-aligned">{payment.etf3}</td>
                  <td className="right-aligned">{payment.accountNo}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='internediate_buttons'> 
        <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename={`Terminate_Payment_Check.csv`}
    className="checkcsv" // Add a className for styling
    target="_blank"
  >
    Check
  </CSVLink>
            <button className='ter_update' onClick={handleUpdate}>Update </button>
            <button className='ter_genarate' onClick={handleSave}>Generate </button>
          </div>
      </div>
    </div>
  );
}