import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Employeepaymentreport.css'
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function Bankdetailsreport() {
  const [searchResults, setSearchResults] = useState([]);
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const empnoset = new Set();
        const companyset = new Set();
        const active = new Set();

        res.data.forEach((employee) => {
          empnoset.add(employee.empNo);
          companyset.add(employee.workLocation);
          active.add(employee.employeeActive);
        });

        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          // Check if both `a` and `b` are numbers (employee numbers)
          if (!isNaN(a) && !isNaN(b)) {
            return a - b; // Sort numbers numerically
          }
          return a.localeCompare(b); // Sort strings alphabetically
        });

        setEmpnos(sortedEmpnos);
        setCompanyNames(Array.from(companyset));
        setActive(Array.from(active));
         
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployees();
  }, []);
  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/employees',{
        headers: {
          Authorization: token,
        },
      });
      const employees = res.data;

      let filteredResults = employees;

    if (selectedEmployeeNo) {
      filteredResults = filteredResults.filter(
        (employee) => employee.empNo === selectedEmployeeNo
      );
    }


    if (selectedCompany) {
      filteredResults = filteredResults.filter(
        (employee) => employee.workLocation === selectedCompany
      );
    }
    if (selectedActive) {
      filteredResults = filteredResults.filter(
        (employee) => employee.employeeActive === selectedActive
      );
    }

    setSearchResults(filteredResults);
    }catch (error) {
      console.log(error);
    }
    
  };

  const GeneratePDF = async () => {
    const PDF = new jsPDF('portrait', 'px', 'a3');
    PDF.setFontSize(7);
  
    try {
      const headers = [
        'Emp No',
        'Epf No',
        'Full Name',
        'Bank Name',
        'Bank Code',
        'Branch Name',
        'Branch Code',
        'Account No',
      ];
      const data = [];
  
      // Use the filtered data directly
      searchResults.forEach((employee) => {
        const rowData = [
          employee.empNo,
          employee.epfNo,
          employee.displayName,
          employee.bankName,
          employee.bankCode,
          employee.branchName,
          employee.branchCode,
          employee.accountNo,
        ];
        data.push(rowData);
      });
  
      PDF.setFontSize(14);
      const customTitle = 'Bank Details';
      const headerMargin = 13;
      const titleTopMargin = 20;
  
      PDF.text(customTitle, 10, titleTopMargin + headerMargin);
      PDF.autoTable({
        startY: titleTopMargin + headerMargin + 30,
        head: [headers],
        body: data,
        margin: { top: headerMargin },
      });
  
      PDF.save('Employee_bankdetails_report.pdf');
    } catch (error) {
      console.error('Error retrieving employee data:', error);
    }
  };
  
  
  const handleEmployeeNoFilter = (event) => {
    setSelectedEmployeeNo(event.target.value);
  };
  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  };

  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };
  const prepareCSVData = () => {

    const csvData = searchResults.map((employee) => ({

      'Emp No':employee.empNo,
      'Epf No':employee.epfNo,
      'Emp Name':employee.displayName,
      'Bank Name':employee.bankName,
      'Bank Code':employee.bankCode,
      'Branch Name':employee.branchName,
      'Branch Code':employee.branchCode,
      'Account Number':employee.accountNo,
    }));
  
    return [ ...csvData]; // Concatenate headers with the data rows
  };

  return (
<div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Bank Details Report</h1>
        <div className="filter_section">
                  <div className='row mb-3'>
                
            <div className="col-md-4">
              <div className="location-filter">
                <label htmlFor="locationFilter">Work Location:</label>
                <select
                  id="locationFilter"
                  value={selectedCompany}
                  className="form-select"
                  onChange={handleCompanyFilter}
                >
                  <option value="">All</option>
                  {companyNames.map((company, index) => (
                    <option key={index} value={company}>
                      {company}
                    </option>
                  ))}
                </select>
              </div>
              </div>
            <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((activestatus) => (
                  <option key={activestatus} value={activestatus}>
                    {activestatus}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4">
                <label htmlFor="employeeNoFilter">Employee No:</label>
                <select
                  id="empNo"
                  value={selectedEmployeeNo}
                  className="form-control"
                  onChange={handleEmployeeNoFilter}
                >
                  <option value="">All</option>
                  {empnos.map((empno, index) => (
                    <option key={index} value={empno}>
                      {empno}
                    </option>
                  ))}
                </select>
            </div>
          </div>
          <button className="filter" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>

        <div>
        <table className="table">
            <thead>
              <tr>
              <th>Emp No</th>
              <th>Epf No</th>
                <th>Emp Name</th>
                <th>Bank Name</th>
                <th>Bank Code</th>
                <th>Bank Name</th>
                <th>Branch Code</th>
                <th>Account No</th>
                
              </tr>
            </thead>
            <tbody>
              {searchResults.map((employee) => (
                
                <tr key={employee.id}>
                 <td>{employee.empNo}</td>
                  <td>{employee.epfNo}</td>
                  <td>{employee.displayName}</td>
                  <td className="right-aligned">{employee.bankName}</td>
                  <td className="right-aligned">{employee.bankCode}</td>
                  <td className="right-aligned">{employee.branchName}</td>
                  <td className="right-aligned">{employee.branchCode}</td>
                  <td className="right-aligned">{employee.accountNo}</td>
                
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='repos'>

<div>
  <button className='pdf' onClick={GeneratePDF}>Generate PDF</button>
  </div>
  <div>
  <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename="Employee_details_report.csv"
    className="csv"
    target="_blank"
  >
    Genarate CSV
  </CSVLink>
  </div>
     </div>
      </div>
    
    </div>
  );
}
