
import React, { useState } from 'react';
import axios from 'axios';
import './Adddesignations.css';
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function AddDesignation() {
  const [designationData, setDesignationData] = useState({
    desCode: '',
    desName: '',
    desDescription: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDesignationData({
      ...designationData,
      [name]: value,
    });
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token =Cookies.get('token');
      await axios.post('https://backhris.talentfort.live/designations', designationData,{
        headers: {
          Authorization: token,
        },
      });
      alert('Designation added successfully.');
      // Navigate to the previous page or handle redirection as needed
      window.history.back();
    } catch (error) {
      console.error(error);
    }
  };

  const handleReset = (e) => {
    e.preventDefault();
    setDesignationData({
      desCode: '',
      desName: '',
      desDescription: '',
    });
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className="adddesignation">
        <h1>Add Designation</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="desCode" className="form-label">
              Designation Code
            </label>
            <input
              type='text'
              className="form-control"
              id="desCode"
              name="desCode"
              value={designationData.desCode}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="desName" className="form-label">
              Designation Name
            </label>
            <input
              type="text"
              className="form-control"
              id="desName"
              name="desName"
              value={designationData.desName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="desDescription" className="form-label">
              Designation Description
            </label>
            <input
              type="text"
              className="form-control"
              id="desDescription"
              name="desDescription"
              value={designationData.desDescription}
              onChange={handleChange}
            />
          </div>

          <div className="buttonsadddesignation">
            <button
              type="submit"
              className="btn btn-primary shadow save-button"
              onClick={handleClick}
            >
              Save
            </button>
            <button
              type="reset"
              className="btn btn-danger reset-button"
              onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
