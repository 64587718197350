import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from '../component/Sidebar';
import './Updateuser.css';

export default function Passwordreset() {
  const [userData, setUserData] = useState({
    username: '',
    role: '',
    oldPassword: '',
    newPassword: '',
  });
  const [passwordMatchMessage, setPasswordMatchMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const userId = location.pathname.split('/')[2];
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = Cookies.get('token');
        const userId = Cookies.get('id');
        const response = await axios.get(`https://backhris.talentfort.live/users/${userId}`, {
          headers: {
            Authorization: token,
          },
        });
        const { username, role } = response.data;
        setUserData({
          username,
          role,
          oldPassword: '', // Initialize with empty values
          newPassword: '',
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, [userId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token = Cookies.get('token');
      const userId = Cookies.get('id');

      // Check if the entered old password matches the stored password in the backend
      const response = await axios.post(
        `https://backhris.talentfort.live/checkpassword`,
        {
          userId,
          oldPassword: userData.oldPassword,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      if (response.data.success) {
        // If the old password is correct, proceed with the update
        await axios.put(`https://backhris.talentfort.live/user/${userId}`, userData, {
          headers: {
            Authorization: token,
          },
        });
        Cookies.remove('username');
        Cookies.remove('role');
        Cookies.remove('token');
        Cookies.remove('id');
        navigate('/');
      } else {
        // If the old password is incorrect, display the error message
        setPasswordMatchMessage(response.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="main-body">
      <div>
        <Sidebar />
      </div>
      <div className="updateuser">
        <h1>User Password Reset</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="username" className="form-label">
              User Name
            </label>
            <input
              className="form-control"
              type="text"
              id="username"
              name="username"
              value={userData.username}
              onChange={handleChange}
              disabled
            />
          </div>
          <div className="mb-3">
            <label htmlFor="oldPassword" className="form-label">
              Old Password
            </label>
            <input
              className="form-control"
              type="password"
              id="oldPassword"
              name="oldPassword"
              value={userData.oldPassword}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="newPassword" className="form-label">
              New Password
            </label>
            <input
              className="form-control"
              type="password"
              id="newPassword"
              name="newPassword"
              value={userData.newPassword}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="role" className="form-label">
              Role
            </label>
            <select
              className="form-control"
              id="role"
              name="role"
              value={userData.role}
              onChange={handleChange}
              disabled
            >
              <option value="">Select Role</option>
              <option value="Admin">Admin</option>
              <option value="Super Admin">Super Admin</option>
              <option value="Finance Admin">Finance Admin</option>
            </select>
          </div>
          {passwordMatchMessage && <div className="mb-3">{passwordMatchMessage}</div>}
          <div className="buttonsupdateuser">
            <button type="submit" className="btn btn-primary update-button" onClick={handleClick}>
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
