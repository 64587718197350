
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Upload.css';
import Cookies from 'js-cookie';
import Sidebar from '../component/Sidebar'



export default function Uploadworkingdays() {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [data, setData] = useState([]);
 

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get('https://backhris.talentfort.live/direct/allowance',{
          headers: {
            Authorization: token,
          },
        });
        setData(response.data); // Set data to the entire response
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchPayments();
  }, []);
  

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setError(null);
    setSuccessMessage(null);
  };

  const handleUpload = async () => {
    setError(null);
    setSuccessMessage(null);

    if (!file) {
      window.alert('Please select a CSV file to upload.');
      return;
    }

    const confirmUpload = window.confirm('Are you sure you want to upload this file?');

    if (!confirmUpload) {
      setFile(null);
      return;
    }

    try {
const formData = new FormData();
      formData.append('csvFile', file);
      const token =Cookies.get('token');
      const response = await axios.post('https://backhris.talentfort.live/upload/workingDays', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',Authorization: token,
        },
      });

      if (response.data.error === 'Data for this month and year already exists.') {
        window.alert('Data for this month and year already exists.');
      } else {
        setFile(null);
        window.alert('CSV file uploaded successfully.');
        window.location.reload();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      window.alert('Error uploading file. Please try again later.');
    }
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>

      <div className="uploadcsv">
        <div className='top'>
          <h1>Working Days (Direct)</h1>
          <div className="row mb-1">
            <div className='col-lg-4'>
              <label htmlFor="uploadDate" className="form-label">
                Upload CSV
              </label>
              <input
                id="file-input"
                type="file"
                accept=".csv"
                onChange={handleFileChange}
                className="form-control"
              />

              {error && <div className="alert">{error}</div>}
              {successMessage && <div className="alert">{successMessage}</div>}
            </div>
           
            <div className="col-lg-3">
              <button onClick={handleUpload} className="uploadbutton">
                Upload
              </button>
            </div>
          </div>
        </div>
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>Emp No</th>
                <th>Allowance Amount</th>
              </tr>
            </thead>
            <tbody>
              {data.map((allowance) => (
                <tr className="Dts" key={allowance.id}>
                  <td>{allowance.empNo}</td>
                  <td>{allowance.workingDays}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
