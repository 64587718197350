import React,{useEffect} from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';



import Login from './pages/Login';
import Dash from './pages/Dashboard';

import Employee from './pages/Employee';
import Designation from './pages/Designations';
import User from './pages/Users';
import Worklocations from './pages/Worklocations';

import Addemployee from './pages/Addemployee';
import Adddesignations from './pages/Adddesignations';
import Adduser from './pages/Adduser'
import Addworklocation from './pages/Addworklocation';


import Updateemployee from './pages/Updateemployee';
import Updatedesignation from './pages/Updatedesignation';
import Updateuser from './pages/Updateuser';
import Updateworklocation from './pages/Updateworklocation';




import Employeereport from './pages/Employeereport';
import Paymentreport from './pages/Paymentreport';
import Employeepaymentreport from './pages/Employeepaymentreport';
import Cformreport from './pages/Employeecformreport';
import Bankslip from './pages/Bankslip';
import Bcardreport from './pages/Bcardreport';
import Dependentreport from './pages/Dependentreport';
import Bankdetailsreport from './pages/Bankdetailsreport';
import Epfreport from './pages/Epfreport';


import Uploadsalaryarrears from './pages/Uploadsalaryarrears'
import Uploadovertime from './pages/Uploadovertime';
import Uploadotarrears from './pages/Uploadotarrears'
import Uploaddotallowance from './pages/Uploaddotallowance';
import Uploaddotarrears from './pages/Uploaddotarrears';
import Uploadextraworkingpayment from './pages/Uploadextraworkingpayment'
import Uploadactinallowance from './pages/Uploadactinallowance'
import Uploadtransport from './pages/Uploadtransport';
import Uploadperformance from './pages/Uploadperformance';
import Uploadmotivation from './pages/Uploadmotivation';
import Uploadsalescommission from './pages/Uploadsalescommision'
import Uploadcoveringupallowance from './pages/Uploadcoveringupallowance'
import Uploadadvance from './pages/Uploadadvance'
import Uploadnopay from './pages/Uploadnopay';
import Uploadadvancededuction from './pages/Uploadadvancededuction';
import Uploademployeedetails from './pages/Uploademployeedetails';
import Uploadbdallowance from './pages/Bdcommission';
import Uploadpayeetax from './pages/Uploadpayeetax';
import Uploadfestivaladvance from './pages/Uploadfestivaladvance';
import Uploadexperienceallowance from './pages/Uploadexperience';
import Uploadworkingdays from './pages/Uploadworkingdays';
import Uploadadminallowance from './pages/Uploadadminallowance';
import Uploadmobileallowance from './pages/Uploadmobileallowance';
import Uploadloans from './pages/Uploadloans';
import Uploadotherallowance from './pages/Uploadotherallowance';
import Uploadfestivaladvancededuction from './pages/Uploadfestivaladvancededuction';
import Uploadloandeduction from './pages/Uploadloandeduction';
import Uploadotherdeduction from './pages/Uploadotherdeduction';
import UploadsalaryOverpayment from './pages/Uploadsalaryoverpayment';
import Paymentdetails from './pages/Paymentdetails';

import Uploadsalaryarrearsayathitrust from './ayathitrust/Uploadsalaryarrears';
import Uploadovertimeayathitrust from './ayathitrust/Uploadovertime';
import Uploadotarrearsayathitrust from './ayathitrust/Uploadotarrears'
import Uploaddotallowanceayathitrust from './ayathitrust/Uploaddotallowance';
import Uploaddotarrearsayathitrust from './ayathitrust/Uploaddotarrears';
import Uploadextraworkingpaymentayathitrust from './ayathitrust/Uploadextraworkingpayment'
import Uploadactinallowanceayathitrust from './ayathitrust/Uploadactinallowance'
import Uploadtransportayathitrust from './ayathitrust/Uploadtransport';
import Uploadperformanceayathitrust from './ayathitrust/Uploadperformance';
import Uploadmotivationayathitrust from './ayathitrust/Uploadmotivation';
import Uploadsalescommissionayathitrust from './ayathitrust/Uploadsalescommision'
import Uploadcoveringupallowanceayathitrust from './ayathitrust/Uploadcoveringupallowance'
import Uploadadvanceayathitrust from './ayathitrust/Uploadadvance'
import Uploadnopayayathitrust from './ayathitrust/Uploadnopay';
import Uploadadvancedeductionayathitrust from './ayathitrust/Uploadadvancededuction';
import Uploadbdallowanceayathitrust from './ayathitrust/Bdcommission';
import Uploadpayeetaxayathitrust from './ayathitrust/Uploadpayeetax';
import Uploadfestivaladvanceayathitrust from './ayathitrust/Uploadfestivaladvance';
import Uploadfestivaladvanceadeductionyathitrust  from './ayathitrust/Uploadfestivaladvancededuction'
import Uploadexperienceallowanceayathitrust from './ayathitrust/Uploadexperience';
import Uploadworkingdaysayathitrust from './ayathitrust/Uploadworkingdays';
import Paymentdetailsayathitrust from './ayathitrust/Paymentdetails';


import Uploadsalaryarrearsedinborough from './edinborough/Uploadsalaryarrears';
import Uploadotherallowanceedinborough from './edinborough/Uploadotherallowance';
import Uploadsalesdriveincentive3edinborough from './edinborough/Uploadsalesdriveincentive3'
import Uploadsalesdriveincentive1edinborough from './edinborough/Uploadsalesdriveincentive1';
import Uploadsalesdriveincentive2edinborough from './edinborough/Uploadsalesdriveincentive2';
import Uploadextraworkingpaymentedinborough from './edinborough/Uploadextraworkingpayment'
import Uploadasalesincentiveedinborough from './edinborough/Uploadsalesincentive'
import Uploadtransportedinborough from './edinborough/Uploadtransport';
import Uploadperformanceedinborough from './edinborough/Uploadperformance';
import Uploadmotivationedinborough from './edinborough/Uploadmotivation';
import Uploadsalescommissionedinborough from './edinborough/Uploadsalescommision'
import Uploadwelfarearrearsedinborough from './edinborough/Uploadwelfarearrears'
import Uploadadvanceedinborough from './edinborough/Uploadadvance'
import Uploadnopayedinborough from './edinborough/Uploadnopay';
import Uploadadvancedeductionedinborough from './edinborough/Uploadadvancededuction';
import Uploadwalfareedinborough from './edinborough/Uploadwalfare';
import Uploadpayeetaxedinborough from './edinborough/Uploadpayeetax';
import Uploadfestivaladvanceedinborough from './edinborough/Uploadfestivaladvance';
import Uploadexperienceallowanceedinborough from './edinborough/Uploadexperience';
import Uploadworkingdaysedinborough from './edinborough/Uploadworkingdays';
import Uploadfestivaladvancedeductionedinborough from './edinborough/Uploadfestivaladvancededuction';
import Uploadmobiledeductionedinborough from './edinborough/Uploadmobilededuction';
import Uploadotherdeductionedinborough from './edinborough/uploadotherdeduction';
import Paymentdetailsedinborough from './edinborough/Paymentdetails';


import Uploadsalaryarrearsrdbcallcenter from './rdbcallcenter/Uploadsalaryarrears';
import Uploadovertimerdbcallcenter from './rdbcallcenter/Uploadovertime';
import Uploadotarrearsrdbcallcenter from './rdbcallcenter/Uploadotarrears'
import Uploaddotallowancerdbcallcenter from './rdbcallcenter/Uploaddotallowance';
import Uploaddotarrearsrdbcallcenter from './rdbcallcenter/Uploaddotarrears';
import Uploadextraworkingpaymentrdbcallcenter from './rdbcallcenter/Uploadextraworkingpayment'
import Uploadactinallowancerdbcallcenter from './rdbcallcenter/Uploadactinallowance'
import Uploadtransportrdbcallcenter from './rdbcallcenter/Uploadtransport';
import Uploadperformancerdbcallcenter from './rdbcallcenter/Uploadperformance';
import Uploadmotivationrdbcallcenter from './rdbcallcenter/Uploadmotivation';
import Uploadsalescommissionrdbcallcenter from './rdbcallcenter/Uploadsalescommision'
import Uploadcoveringupallowancerdbcallcenter from './rdbcallcenter/Uploadcoveringupallowance'
import Uploadadvancerdbcallcenter from './rdbcallcenter/Uploadadvance'
import Uploadnopayrdbcallcenter from './rdbcallcenter/Uploadnopay';
import Uploadadvancedeductionrdbcallcenter from './rdbcallcenter/Uploadadvancededuction';
import Uploadbdallowancerdbcallcenter from './rdbcallcenter/Bdcommission';
import Uploadpayeetaxrdbcallcenter from './rdbcallcenter/Uploadpayeetax';
import Uploadfestivaladvancerdbcallcenter from './rdbcallcenter/Uploadfestivaladvance';
import Uploadexperienceallowancerdbcallcenter from './rdbcallcenter/Uploadexperience';
import Uploadworkingdaysrdbcallcenter from './rdbcallcenter/Uploadworkingdays';
import Uploadadminallowancerdbcallcenter from './rdbcallcenter/Uploadadminallowance';
import Uploadmobileallowancerdbcallcenter from './rdbcallcenter/Uploadmobileallowance';
import Uploadloansrdbcallcenter from './rdbcallcenter/Uploadloans';
import Uploadotherallowancerdbcallcenter from './rdbcallcenter/Uploadotherallowance';
import Uploadfestivaladvancedeductionrdbcallcenter from './rdbcallcenter/Uploadfestivaladvancededuction';
import Uploadloandeductionrdbcallcenter from './rdbcallcenter/Uploadloandeduction';
import Uploadotherdeductionrdbcallcenter from './rdbcallcenter/Uploadotherdeduction';

import Paymentdetailsrdbcallcenter from './rdbcallcenter/Paymentdetails';


import Uploadsalaryarrearswbcallcenter from './wbcallcenter/Uploadsalaryarrears';
import Uploadovertimewbcallcenter from './wbcallcenter/Uploadovertime';
import Uploadotarrearswbcallcenter from './wbcallcenter/Uploadotarrears'
import Uploaddotallowancewbcallcenter from './wbcallcenter/Uploaddotallowance';
import Uploaddotarrearswbcallcenter from './wbcallcenter/Uploaddotarrears';
import Uploadextraworkingpaymentwbcallcenter from './wbcallcenter/Uploadextraworkingpayment'
import Uploadactinallowancewbcallcenter from './wbcallcenter/Uploadactinallowance'
import Uploadtransportwbcallcenter from './wbcallcenter/Uploadtransport';
import Uploadperformancewbcallcenter from './wbcallcenter/Uploadperformance';
import Uploadmotivationwbcallcenter from './wbcallcenter/Uploadmotivation';
import Uploadsalescommissionwbcallcenter from './wbcallcenter/Uploadsalescommision'
import Uploadcoveringupallowancewbcallcenter from './wbcallcenter/Uploadcoveringupallowance'
import Uploadadvancewbcallcenter from './wbcallcenter/Uploadadvance'
import Uploadnopaywbcallcenter from './wbcallcenter/Uploadnopay';
import Uploadadvancedeductionwbcallcenter from './wbcallcenter/Uploadadvancededuction';
import Uploadbdallowancewbcallcenter from './wbcallcenter/Bdcommission';
import Uploadpayeetaxwbcallcenter from './wbcallcenter/Uploadpayeetax';
import Uploadfestivaladvancewbcallcenter from './wbcallcenter/Uploadfestivaladvance';
import Uploadexperienceallowancewbcallcenter from './wbcallcenter/Uploadexperience';
import Uploadworkingdayswbcallcenter from './wbcallcenter/Uploadworkingdays';
import Uploadadminallowancewbcallcenter from './wbcallcenter/Uploadadminallowance';
import Uploadmobileallowancewbcallcenter from './wbcallcenter/Uploadmobileallowance';
import Uploadloanswbcallcenter from './wbcallcenter/Uploadloans';
import Uploadotherallowancewbcallcenter from './wbcallcenter/Uploadotherallowance';
import Uploadfestivaladvancedeductionwbcallcenter from './wbcallcenter/Uploadfestivaladvancededuction';
import Uploadloandeductionwbcallcenter from './wbcallcenter/Uploadloandeduction';
import Uploadotherdeductionwbcallcenter from './wbcallcenter/Uploadotherdeduction';
import Paymentdetailswbcallcenter from './wbcallcenter/Paymentdetails';



import Uploadsalaryarrearssales from './sales/Uploadsalaryarrears';
import Uploadsalescommissionsales from './sales/Uploadsalescommision';
import Uploadfuelallowancesales from './sales/Uploadfuelallowance';
import Uploadmngsupervisorallowancesales from './sales/Uploadmngsupervisorallowance';
import Uploadmobileallowancesales from './sales/Uploadmobileallowance';
import Uploadtransportsales from './sales/Uploadtransport';
import Uploadperformancesales from './sales/Uploadperformance';
import Uploadsupervisorcommissionsales from './sales/Uploadsupervisorcommission';
import Uploadsalacommissionsales from './sales/Uploadsalacommission';
import Uploadklmcommissionsales from './sales/Uploadklmcommission';
import Uploadmobitelcommissionales from './sales/Uploadmobitelcommission';
import Uploadpeotvremotecommissionsales from './sales/Uploadpeotvremotecommission';
import Uploadmemorytaxcommissionsales from './sales/Uploadmemorytexcommission';
import Uploadajcommissionsales from './sales/Uploadajcommission';
import Uploadcpearrearscollectingcommissionsales from './sales/Uploadcpearrearscollectingcommission';
import Uploadcpecollectingcommissionsales from './sales/Uploadcpecollectingcommission';
import Uploadcommissionarrearssales from './sales/Uploadcommissionarrears'
import Uploadstampdutysales from './sales/Uploadstampduty';
import Uploadotherallowancesales from './sales/Uploadotherallowance';
import Uploadnopaysales from './sales/Uploadnopay';
import Uploadfestivaladvancedeductionsales from './sales/Uploadfestivaladvancededuction';
import Uploadfestivaladvancesales from './sales/Uploadfestivaladvance';
import Uploadgroomingtrainingdeductionsales from './sales/Uploadgroomingtrainingdeduction';
import Uploadpreviousmonthtaxsales from './sales/Uploadpreviousmonthtax';
import Uploadpayeetaxsales from './sales/Uploadpayeetax';
import Uploadotherdeductionsales from './sales/Uploadotherdeduction'
import Uploadworkingdayssales from './sales/Uploadworkingdays';
import Paymentdetailshemassales from './sales/Paymentdetails';


//SR
import UploadsalaryarrearshemasSR from './hemas/SR/Uploadsalaryarrears';
import UploadgoyapaymenthemasSR from './hemas/SR/Uploadgoyapayment';
import UploadfuelallowancehemasSR from './hemas/SR/Uploadfuelallowance';
import UploadsalescommisionhemasSR from './hemas/SR/Uploadsalescommision';
import UploadotherreimbursementhemasSR from './hemas/SR/Uploadotherreimbursement';
import UploadadvancehemasSR from './hemas/SR/Uploadadvance';
import UploadadvancedeductionhemasSR from './hemas/SR/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasSR from './hemas/SR/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasSR from './hemas/SR/Uploadfestivaladvance';
import UploadnopayhemasSR from './hemas/SR/Uploadnopay';
import UploadpayeetaxhemasSR from './hemas/SR/Uploadpayeetax';
import UploadworkingdayshemasSR from './hemas/SR/Uploadworkingdays';
import UploadperformancehemasSR from './hemas/SR/Uploadperformanceallowance';
import UploadotherarrearshemasSR from './hemas/SR/Uploadotherarrears';
import UploadotheradditionhemasSR from './hemas/SR/Uploadotheraddition';
import UploadotherdeductionhemasSR from './hemas/SR/Uploadotherdeduction';


import PaymentdetailshemasSR from './hemas/SR/Paymentdetails';

// SR-intern
import UploadsalaryarrearshemasSRintern from './hemas/SRintern/Uploadsalaryarrears';
import UploadotherreimbursementSRintern from './hemas/SRintern/Uploadotherreimbursement';
import UploadfuelallowancehemasSRintern from './hemas/SRintern/Uploadfuelallowance';
import UploadinternallowancehemasSRintern from './hemas/SRintern/Uploadinternallowance';
import UploadinternallowancearrearshemasSRintern from './hemas/SRintern/Uploadinternallowancearrears';
import UploadsalescommisionhemasSRintern from './hemas/SRintern/Uploadsalescommision';
import UploadsalescommissionarrearshemasSRintern from './hemas/SRintern/Uploadsalescommissionarrears';
import UploadotherreimbursementarrearshemasSRintern from './hemas/SRintern/Uploadotherreimbursementarrears';
import UploadadvancehemasSRintern from './hemas/SRintern/Uploadadvance';
import UploadadvancedeductionhemasSRintern from './hemas/SRintern/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasSRintern from './hemas/SRintern/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasSRintern from './hemas/SRintern/Uploadfestivaladvance';
import UploadnopayhemasSRintern from './hemas/SRintern/Uploadnopay';
import UploadpayeetaxhemasSRintern from './hemas/SRintern/Uploadpayeetax';
import UploadworkingdayshemasSRintern from './hemas/SRintern/Uploadworkingdays';
import UploadotherarrearshemasSRintern from './hemas/SRintern/Uploadotherarrears';
import UploadotheradditionhemasSRintern from './hemas/SRintern/Uploadotheraddition';
import UploadotherdeductionhemasSRintern from './hemas/SRintern/Uploadotherdeduction';


import PaymentdetailshemasSRintern from './hemas/SRintern/Paymentdetails';

//MA
import UploadsalaryarrearshemasMA from './hemas/MA/Uploadsalaryarrears';
import UploadperformmanceallownacehemasMA from './hemas/MA/Uploadperformanceallowance'
import UploadadvancehemasMA from './hemas/MA/Uploadadvance';
import UploadadvancedeductionhemasMA from './hemas/MA/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasMA from './hemas/MA/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasMA from './hemas/MA/Uploadfestivaladvance';
import UploadnopayhemasMA from './hemas/MA/Uploadnopay';
import UploadpayeetaxhemasMA from './hemas/MA/Uploadpayeetax';
import UploadworkingdayshemasMA from './hemas/MA/Uploadworkingdays';
import UploadfoodallowancehemasMA from './hemas/MA/Uploadfoodallowance';
import UploadspecialallowancehemasMA from './hemas/MA/Uploadspecialallowance';
import UploadtransporthemasMA from './hemas/MA/Uploadtransport';
import UploadotherarrearshemasMA from './hemas/MA/Uploadotherarrears';
import UploadotheradditionhemasMA from './hemas/MA/Uploadotheraddition';
import UploadotherdeductionhemasMA from './hemas/MA/Uploadotherdeduction';


import PaymentdetailshemasMA from './hemas/MA/Paymentdetails';

//MA intern
import UploadsalaryarrearshemasMAintern from './hemas/MAintern/Uploadsalaryarrears';
import UploadinternallowancehemasMAintern from './hemas/MAintern/Uploadinternallowance';
import UploadinternallowancearrearshemasMAintern from './hemas/MAintern/Uploadinternallowancearrears';
import UploadadvancehemasMAintern from './hemas/MAintern/Uploadadvance';
import UploadadvancedeductionhemasMAintern from './hemas/MAintern/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasMAintern from './hemas/MAintern/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasMAintern from './hemas/MAintern/Uploadfestivaladvance';
import UploadnopayhemasMAintern from './hemas/MAintern/Uploadnopay';
import UploadpayeetaxhemasMAintern from './hemas/MAintern/Uploadpayeetax';
import UploadworkingdayshemasMAintern from './hemas/MAintern/Uploadworkingdays';
import UploadperformanceallowancehemasMAintern from './hemas/MAintern/Uploadperformanceallowance';
import UploadspecialallowancehemasMAintern from './hemas/MAintern/Uploadspecialallowance';
import UploadtransporthemasMAintern from './hemas/MAintern/Uploadtransport';
import UploadfoodallowancehemasMAintern from './hemas/MAintern/Uploadfoodallowance';
import UploadfoodallowancearrearshemasMAintern from './hemas/MAintern/Uploadfoodallowancearrears';
import UploadtransportallowancearrearshemasMAintern from './hemas/MAintern/Uploadtransportallowancearrears';
import UploadotherarrearshemasMAintern from './hemas/MAintern/Uploadotherarrears';
import UploadotheradditionhemasMAintern from './hemas/MAintern/Uploadotheraddition';
import UploadotherdeductionhemasMAintern from './hemas/MAintern/Uploadotherdeduction';


import PaymentdetailshemasMAintern from './hemas/MAintern/Paymentdetails';

//Mc
import UploadsalaryarrearshemasMC from './hemas/MC/Uploadsalaryarrears';
import UploadotherreimbursementhemasMC from './hemas/MC/Uploadotherreimbursement';
import UploadsalescommisionhemasMC from './hemas/MC/Uploadsalescommision';
import UploadperformanceallowancehemasMC from './hemas/MC/Uploadperformanceallowance';
import UploadadvancehemasMC from './hemas/MC/Uploadadvance';
import UploadadvancedeductionhemasMC from './hemas/MC/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasMC from './hemas/MC/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasMC from './hemas/MC/Uploadfestivaladvance';
import UploadnopayhemasMC from './hemas/MC/Uploadnopay';
import UploadpayeetaxhemasMC from './hemas/MC/Uploadpayeetax';
import UploadworkingdayshemasMC from './hemas/MC/Uploadworkingdays';
import UploadattendenceallowancehemasMC from './hemas/MC/Uploadattendenceallowance';
import UploadholidaypaymenthemasMC from './hemas/MC/Uploadholidaypayment';
import UploadspecialallowancehemasMC from './hemas/MC/Uploadspecialallowance';
import UploadtransporthemasMC from './hemas/MC/Uploadtransport';
import UploadotherarrearshemasMC from './hemas/MC/Uploadotherarrears';
import UploadotheradditionhemasMC from './hemas/MC/Uploadotheraddition';
import UploadotherdeductionhemasMC from './hemas/MC/Uploadotherdeduction';


import PaymentdetailshemasMC from './hemas/MC/Paymentdetails';

//MC intern
import UploadsalaryarrearshemasMCintern from './hemas/MCintern/Uploadsalaryarrears';
import UploadotherreimbursementMCintern from './hemas/MCintern/Uploadotherreimbursement';
import UploadinternallowancehemasMCintern from './hemas/MCintern/Uploadinternallowance';
import UploadsalescommisionhemasMCintern from './hemas/MCintern/Uploadsalescommision';
import UploadperformmanceallownacehemasMCintern from './hemas/MCintern/Uploadperformanceallowance';
import UploadnopayhemasMCintern from './hemas/MCintern/Uploadnopay';
import UploadpayeetaxhemasMCintern from './hemas/MCintern/Uploadpayeetax';
import UploadworkingdayshemasMCintern from './hemas/MCintern/Uploadworkingdays';
import UploadattendenceallowancehemasMCintern from './hemas/MCintern/Uploadattendenceallowance';
import UploadholidaypaymenthemasMCintern from './hemas/MCintern/Uploadholidaypayment';
import Uploadtransport2hemasMCintern from './hemas/MCintern/Uploadtransport2';
import UploadtransporthemasMCintern from './hemas/MCintern/Uploadtransport';
import UploadotherarrearshemasMCintern from './hemas/MCintern/Uploadotherarrears';
import UploadotheradditionhemasMCintern from './hemas/MCintern/Uploadotheraddition';
import UploadotherdeductionhemasMCintern from './hemas/MCintern/Uploadotherdeduction';


import PaymentdetailshemasMCintern from './hemas/MCintern/Paymentdetails';


//SPA
import UploadsalaryarrearshemasSPA from './hemas/SPA/Uploadsalaryarrears';
import UploadotherreimbursementSPA from './hemas/SPA/Uploadotherreimbursement';
import UploadperformmanceallownacehemasSPA from './hemas/SPA/Uploadperformanceallowance'
import UploadnopayhemasSPA from './hemas/SPA/Uploadnopay';
import UploadpayeetaxhemasSPA from './hemas/SPA/Uploadpayeetax';
import UploadworkingdayshemasSPA from './hemas/SPA/Uploadworkingdays';
import UploadattendenceallowancehemasSPA from './hemas/SPA/Uploadattendenceallowance';
import Uploadperformanceallowance2hemasSPA from './hemas/SPA/Uploadperformanceallowance2';
import UploadspecialallowancehemasSPA from './hemas/SPA/Uploadspecialallowance';
import UploadtransporthemasSPA from './hemas/SPA/Uploadtransport';
import UploadotherarrearshemasSPA from './hemas/SPA/Uploadotherarrears';
import UploadotheradditionhemasSPA from './hemas/SPA/Uploadotheraddition';
import UploadotherdeductionhemasSPA from './hemas/SPA/Uploadotherdeduction';


import PaymentdetailshemasSPA from './hemas/SPA/Paymentdetails';


 //SPA intern
import UploadsalaryarrearshemasSPAintern from './hemas/SPAintern/Uploadsalaryarrears';
import UploadotherreimbursementSPAintern from './hemas/SPAintern/Uploadotherreimbursement';
import UploadperformmanceallownacehemasSPAintern from './hemas/SPAintern/Uploadperformanceallowance'
import UploadnopayhemasSPAintern from './hemas/SPAintern/Uploadnopay';
import UploadpayeetaxhemasSPAintern from './hemas/SPAintern/Uploadpayeetax';
import UploadworkingdayshemasSPAintern from './hemas/SPAintern/Uploadworkingdays';
import UploadattendenceallowancehemasSPAintern from './hemas/SPAintern/Uploadattendenceallowance';
import Uploadperformanceallowance2hemasSPAintern from './hemas/SPAintern/Uploadperformanceallowance2';
import UploadspecialallowancehemasSPAintern from './hemas/SPAintern/Uploadspecialallowance';
import UploadtransporthemasSPAintern from './hemas/SPAintern/Uploadtransport';
import UploadinternallowancehemasSPAintern from './hemas/SPAintern/Uploadinternallowance';
import UploadotherarrearshemasSPAintern from './hemas/SPAintern/Uploadotherarrears';
import UploadotheradditionhemasSPAintern from './hemas/SPAintern/Uploadotheraddition';
import UploadotherdeductionhemasSPAintern from './hemas/SPAintern/Uploadotherdeduction';

import PaymentdetailshemasSPAintern from './hemas/SPAintern/Paymentdetails';


//FMCG
import UploadsalaryarrearshemasFMCG from './hemas/FMCG/Uploadsalaryarrears';
import UploadnopayhemasFMCG from './hemas/FMCG/Uploadnopay';
import UploadpayeetaxhemasFMCG from './hemas/FMCG/Uploadpayeetax';
import UploadworkingdayshemasFMCG from './hemas/FMCG/Uploadworkingdays';
import UploaddataallowancehemasFMCG from './hemas/FMCG/Uploaddataallowance';
import UploadmobileallowanchemasFMCG from './hemas/FMCG/Uploadmobileallowanc';
import UploadotallowancehemasFMCG from './hemas/FMCG/Uploadovertime';
import UploaddotallowancehemasFMCG from './hemas/FMCG/Uploaddotallowance';
import UploadotherarrearshemasFMCG from './hemas/FMCG/Uploadotherarrears';
import UploadotheradditionhemasFMCG from './hemas/FMCG/Uploadotheraddition';
import UploadotherdeductionhemasFMCG from './hemas/FMCG/Uploadotherdeduction';


import PaymentdetailshemasFMCG from './hemas/FMCG/Paymentdetails';


//driver
import UploadadvancehemasDriver from './hemas/Driver/Uploadadvance';
import UploadadvancedeductionhemasDriver from './hemas/Driver/Uploadadvancededuction';
import UploadfestivaladvancedeductionhemasDriver from './hemas/Driver/Uploadfestivaladvancededuction';
import UploadfestivaladvancehemasDriver from './hemas/Driver/Uploadfestivaladvance';
import UploadnopayhemasDriver from './hemas/Driver/Uploadnopay';
import UploadpayeetaxhemasDriver from './hemas/Driver/Uploadpayeetax';
import UploadworkingdayshemasDriver from './hemas/Driver/Uploadworkingdays';
import UploaddotallowancehemasDriver from './hemas/Driver/Uploaddotallowance';
import UploadfullbataallowancehemasDriver from './hemas/Driver/Uploadfullbataallowance';
import UploadmobileallowanchemasDriver from './hemas/Driver/Uploadmobileallowanc';
import UploadnightallowancehemasDriver from './hemas/Driver/Uploadnightallowance';
import UploadotherarrearshemasDriver from './hemas/Driver/Uploadotherarrears';
import UploadotheradditionhemasDriver from './hemas/Driver/Uploadotheraddition';
import UploadotherdeductionhemasDriver from './hemas/Driver/Uploadotherdeduction';

import UploadovertimehemasDriver from './hemas/Driver/Uploadovertime';
import PaymentdetailshemasDriver from './hemas/Driver/Paymentdetails';



import Closepaymentdetails from './close/Closepaymentdetails';



import Uploadterminatecsv from './terminate/Uploadterminatecsv';
import Paymentdetailsterminate from './terminate/Paymentdetails';


import Uploadtimmediatecsv from './intermediate/Uploadintermediatecsv';
import Paymentdetailsintermediate from './intermediate/Paymentdetails';

import CsvTemplate from './csvtemplate/csv';
import CsvTemplateIntermediate from './csvtemplate/csvintermediate';

import Employeecsv from './csvtemplate/employeecsv';
import Allowancecsv from './csvtemplate/allowancecsv'

import Passwordreset from './pages/Passwordreset';

import Attendance from './Attendence/attendence'

import Clearpayroll from './pages/TruncateTables';
function App() {

  const ProtectedRoute = ({ element, allowedRoles }) => {
    const token = Cookies.get('token');
    const navigate = useNavigate();
  
    useEffect(() => {
      const checkRole = () => {
        if (!token) {
          navigate('/');
          return false;
        }
        try {
          const decodedToken = jwt_decode(token);
          const decodedRole = decodedToken.role;
          return decodedRole;
        } catch (error) {
          console.error('Error decoding token:', error);
          navigate('/');
          return false;
        }
      };
  
      const checkAuthorization = (decodedRole) => {
        if (!token || !allowedRoles.includes(decodedRole)) {
          alert('Unauthorized Access');
          navigate('/dash');
          return false;
        }
        return true;
      };
  
      const role = checkRole();
      if (role && checkAuthorization(role)) {
        const decodedToken = jwt_decode(token);
        const currentTime = Date.now() / 1000;
  
        if (decodedToken.exp < currentTime) {
          alert('Token expired. Logging out...');
          Cookies.remove('username');
          Cookies.remove('role');
          Cookies.remove('token');
          navigate('/');
        }
      }
    }, [token, allowedRoles, navigate]);
  
    return element;
  };
       
  return (
    <BrowserRouter>
  <Routes>
          <Route path="/" element={<Login/>}/>
          <Route path="/dash" element={<ProtectedRoute element={<Dash/>} allowedRoles={['Admin', 'Super Admin','Finance Admin']} />}></Route>
          <Route path="/employee" element={<ProtectedRoute element={<Employee />} allowedRoles={['Admin', 'Super Admin']} />}></Route>
          <Route path="/addemployee" element={<ProtectedRoute element={<Addemployee />} allowedRoles={['Admin', 'Super Admin']} />}></Route>
          <Route path="/updateemployee/:id" element={<ProtectedRoute element={<Updateemployee />} allowedRoles={['Admin', 'Super Admin']} />}></Route>
          
          <Route path="/designation" element={<ProtectedRoute element={<Designation/>} allowedRoles={['Admin', 'Super Admin']}/>}></Route>
          <Route path="/adddesignation" element={<ProtectedRoute element={<Adddesignations/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
          <Route path="/updatedesignation/:id" element={<ProtectedRoute element={<Updatedesignation/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

          <Route path="/designation" element={<ProtectedRoute element={<Designation/>} allowedRoles={['Admin', 'Super Admin']}/>}></Route>
          <Route path="/adddesignation" element={<ProtectedRoute element={<Adddesignations/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
          <Route path="/updatedesignation/:id" element={<ProtectedRoute element={<Updatedesignation/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

          <Route path="/users" element={<ProtectedRoute element={<User/>}allowedRoles={['Super Admin']}/>}></Route>
          <Route path="/adduser" element={<ProtectedRoute element={<Adduser/>}allowedRoles={[ 'Super Admin']}/>}></Route>
          <Route path="/updateuser/:id" element={<ProtectedRoute element={<Updateuser/>}allowedRoles={[ 'Super Admin']}/>}></Route>

          <Route path="/worklocations" element={<ProtectedRoute element={<Worklocations/>}allowedRoles={['Super Admin', 'Admin']}/>}></Route>
          <Route path="/addworklocation" element={<ProtectedRoute element={<Addworklocation/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
          <Route path="/updateworklocations/:id" element={<ProtectedRoute element={<Updateworklocation/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

          <Route path="/passwordreset" element={<ProtectedRoute element={<Passwordreset/>}allowedRoles={['Super Admin', 'Admin', 'Finance Admin']}/>}></Route>

          
          

          <Route path="/employeereport" element={<ProtectedRoute element={<Employeereport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="/paymentreport" element={<ProtectedRoute element={<Paymentreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="/employeepaymentreport" element={<ProtectedRoute element={<Employeepaymentreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="/employeecformreport" element={<ProtectedRoute element={<Cformreport />}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="/bankslip" element={<ProtectedRoute element={<Bankslip/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="bcardreport" element={<ProtectedRoute element={<Bcardreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path="/dependentreport" element={<ProtectedRoute element={<Dependentreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path='/bankdetailsreport' element={<ProtectedRoute element={<Bankdetailsreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>
          <Route path='/epfreport' element={<ProtectedRoute element={<Epfreport/>}allowedRoles={['Admin', 'Super Admin', 'Finance Admin']}/>}></Route>


        <Route path='/uploademployeedetails' element={<ProtectedRoute element={<Uploademployeedetails/>}allowedRoles={['Super Admin', 'Admin']}/>}></Route>


        <Route path="/uploadsalaryarrears" element={<ProtectedRoute element ={<Uploadsalaryarrears/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadovertime' element={<ProtectedRoute element={<Uploadovertime />}allowedRoles={['Super Admin']}/>} ></Route>
        <Route path="/uploadotarrears" element={<ProtectedRoute element={<Uploadotarrears/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploaddotallowance" element={<ProtectedRoute element={<Uploaddotallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploaddotarrears" element={<ProtectedRoute element={<Uploaddotarrears/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadextraworkingpayment" element={<ProtectedRoute element={<Uploadextraworkingpayment/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadactinallowance" element={<ProtectedRoute element={<Uploadactinallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadtransport' element={<ProtectedRoute element={<Uploadtransport />}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadperformance' element={<ProtectedRoute element={<Uploadperformance />}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadmotivation" element={<ProtectedRoute element={<Uploadmotivation/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadsalescommission" element={<ProtectedRoute element={<Uploadsalescommission/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadcoveringupallowance" element={<ProtectedRoute element={<Uploadcoveringupallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadadvance" element={<ProtectedRoute element={<Uploadadvance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadtransport" element={<ProtectedRoute element={<Uploadtransport/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadperformance" element={<ProtectedRoute element={<Uploadperformance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadovertime" element={<ProtectedRoute element={<Uploadovertime/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/paymentdetails" element={<ProtectedRoute element={<Paymentdetails/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadnopay" element={<ProtectedRoute element={<Uploadnopay/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadadvancededuction" element={<ProtectedRoute element={<Uploadadvancededuction/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadbdallowance" element={<ProtectedRoute element={<Uploadbdallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path="/uploadpayeetax" element={<ProtectedRoute element={<Uploadpayeetax/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvance' element={<ProtectedRoute element={<Uploadfestivaladvance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadexperienceallowance' element={<ProtectedRoute element={<Uploadexperienceallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadworkingdays' element={<ProtectedRoute element={<Uploadworkingdays/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadadminallowance' element={<ProtectedRoute element={<Uploadadminallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowance' element={<ProtectedRoute element={<Uploadmobileallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadloans' element={<ProtectedRoute element={<Uploadloans/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadotherallowance' element={<ProtectedRoute element={<Uploadotherallowance/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancededuction' element={<ProtectedRoute element={<Uploadfestivaladvancededuction/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadloandeduction' element={<ProtectedRoute element={<Uploadloandeduction/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadotherdeduction' element={<ProtectedRoute element={<Uploadotherdeduction/>}allowedRoles={['Super Admin']}/>}></Route>
        <Route path='/uploadsalaryOverpayment' element={<ProtectedRoute element={<UploadsalaryOverpayment/>}allowedRoles={['Super Admin']}/>}></Route>
        


        <Route path="/uploadsalaryarrearsayathitrust" element={<ProtectedRoute element ={<Uploadsalaryarrearsayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadovertimeayathitrust' element={<ProtectedRoute element={<Uploadovertimeayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadotarrearsayathitrust" element={<ProtectedRoute element={<Uploadotarrearsayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotallowanceayathitrust" element={<ProtectedRoute element={<Uploaddotallowanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotarrearsayathitrust" element={<ProtectedRoute element={<Uploaddotarrearsayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadextraworkingpaymentayathitrust" element={<ProtectedRoute element={<Uploadextraworkingpaymentayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadactinallowanceayathitrust" element={<ProtectedRoute element={<Uploadactinallowanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportayathitrust' element={<ProtectedRoute element={<Uploadtransportayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceayathitrust' element={<ProtectedRoute element={<Uploadperformanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadmotivationayathitrust" element={<ProtectedRoute element={<Uploadmotivationayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalescommissionayathitrust" element={<ProtectedRoute element={<Uploadsalescommissionayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadcoveringupallowanceayathitrust" element={<ProtectedRoute element={<Uploadcoveringupallowanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvanceayathitrust" element={<ProtectedRoute element={<Uploadadvanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadtransportayathitrust" element={<ProtectedRoute element={<Uploadtransportayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadperformanceayathitrust" element={<ProtectedRoute element={<Uploadperformanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadovertimeayathitrust" element={<ProtectedRoute element={<Uploadovertimeayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadnopayayathitrust" element={<ProtectedRoute element={<Uploadnopayayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancedeductionayathitrust" element={<ProtectedRoute element={<Uploadadvancedeductionayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadbdallowanceayathitrust" element={<ProtectedRoute element={<Uploadbdallowanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadpayeetaxayathitrust" element={<ProtectedRoute element={<Uploadpayeetaxayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvanceayathitrust' element={<ProtectedRoute element={<Uploadfestivaladvanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadexperienceallowanceayathitrust' element={<ProtectedRoute element={<Uploadexperienceallowanceayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvanceadeductionyathitrust' element={<ProtectedRoute element = {<Uploadfestivaladvanceadeductionyathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdaysayathitrust' element={<ProtectedRoute element={<Uploadworkingdaysayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path="/uploadsalaryarrearsedinborough" element={<ProtectedRoute element ={<Uploadsalaryarrearsedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherallowanceedinborough' element={<ProtectedRoute element={<Uploadotherallowanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalesdriveincentive3edinborough" element={<ProtectedRoute element={<Uploadsalesdriveincentive3edinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalesdriveincentive1edinborough" element={<ProtectedRoute element={<Uploadsalesdriveincentive1edinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalesdriveincentive2edinborough" element={<ProtectedRoute element={<Uploadsalesdriveincentive2edinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadextraworkingpaymentedinborough" element={<ProtectedRoute element={<Uploadextraworkingpaymentedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadasalesincentiveedinborough" element={<ProtectedRoute element={<Uploadasalesincentiveedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportedinborough' element={<ProtectedRoute element={<Uploadtransportedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceedinborough' element={<ProtectedRoute element={<Uploadperformanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadmotivationedinborough" element={<ProtectedRoute element={<Uploadmotivationedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalescommissionedinborough" element={<ProtectedRoute element={<Uploadsalescommissionedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadwelfarearrearsedinborough" element={<ProtectedRoute element={<Uploadwelfarearrearsedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvanceedinborough" element={<ProtectedRoute element={<Uploadadvanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadtransportedinborough" element={<ProtectedRoute element={<Uploadtransportedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadperformanceedinborough" element={<ProtectedRoute element={<Uploadperformanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadnopayedinborough" element={<ProtectedRoute element={<Uploadnopayedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancedeductionedinborough" element={<ProtectedRoute element={<Uploadadvancedeductionedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadwalfareedinborough" element={<ProtectedRoute element={<Uploadwalfareedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadpayeetaxedinborough" element={<ProtectedRoute element={<Uploadpayeetaxedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvanceedinborough' element={<ProtectedRoute element={<Uploadfestivaladvanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadexperienceallowanceedinborough' element={<ProtectedRoute element={<Uploadexperienceallowanceedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionedinborough' element={<ProtectedRoute element={<Uploadfestivaladvancedeductionedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdaysedinborough' element={<ProtectedRoute element={<Uploadworkingdaysedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobiledeductionedinborough' element={<ProtectedRoute element={<Uploadmobiledeductionedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherdeductionedinborough' element={<ProtectedRoute element={<Uploadotherdeductionedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path="/uploadsalaryarrearsrdbcallcenter" element={<ProtectedRoute element ={<Uploadsalaryarrearsrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadovertimerdbcallcenter' element={<ProtectedRoute element={<Uploadovertimerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadotarrearsrdbcallcenter" element={<ProtectedRoute element={<Uploadotarrearsrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotallowancerdbcallcenter" element={<ProtectedRoute element={<Uploaddotallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotarrearsrdbcallcenter" element={<ProtectedRoute element={<Uploaddotarrearsrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadextraworkingpaymentrdbcallcenter" element={<ProtectedRoute element={<Uploadextraworkingpaymentrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadactinallowancerdbcallcenter" element={<ProtectedRoute element={<Uploadactinallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportrdbcallcenter' element={<ProtectedRoute element={<Uploadtransportrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadmotivationrdbcallcenter" element={<ProtectedRoute element={<Uploadmotivationrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalescommissionrdbcallcenter" element={<ProtectedRoute element={<Uploadsalescommissionrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadcoveringupallowancerdbcallcenter" element={<ProtectedRoute element={<Uploadcoveringupallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancerdbcallcenter" element={<ProtectedRoute element={<Uploadadvancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadtransportrdbcallcenter" element={<ProtectedRoute element={<Uploadtransportrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadperformancerdbcallcenter" element={<ProtectedRoute element={<Uploadperformancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadovertimerdbcallcenter" element={<ProtectedRoute element={<Uploadovertimerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadnopayrdbcallcenter" element={<ProtectedRoute element={<Uploadnopayrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancedeductionrdbcallcenter" element={<ProtectedRoute element={<Uploadadvancedeductionrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadbdallowancerdbcallcenter" element={<ProtectedRoute element={<Uploadbdallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadpayeetaxrdbcallcenter" element={<ProtectedRoute element={<Uploadpayeetaxrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancerdbcallcenter' element={<ProtectedRoute element={<Uploadfestivaladvancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadexperienceallowancerdbcallcenter' element={<ProtectedRoute element={<Uploadexperienceallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdaysrdbcallcenter' element={<ProtectedRoute element={<Uploadworkingdaysrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadminallowancerdbcallcenter' element={<ProtectedRoute element={<Uploadadminallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowancerdbcallcenter' element={<ProtectedRoute element={<Uploadmobileallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadloansrdbcallcenter' element={<ProtectedRoute element={<Uploadloansrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherallowancerdbcallcenter' element={<ProtectedRoute element={<Uploadotherallowancerdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionrdbcallcenter' element={<ProtectedRoute element={<Uploadfestivaladvancedeductionrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadloandeductionrdbcallcenter' element={<ProtectedRoute element={<Uploadloandeductionrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherdeductionrdbcallcenter' element={<ProtectedRoute element={<Uploadotherdeductionrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path="/uploadsalaryarrearswbcallcenter" element={<ProtectedRoute element ={<Uploadsalaryarrearswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadovertimewbcallcenter' element={<ProtectedRoute element={<Uploadovertimewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadotarrearswbcallcenter" element={<ProtectedRoute element={<Uploadotarrearswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotallowancewbcallcenter" element={<ProtectedRoute element={<Uploaddotallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploaddotarrearswbcallcenter" element={<ProtectedRoute element={<Uploaddotarrearswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadextraworkingpaymentwbcallcenter" element={<ProtectedRoute element={<Uploadextraworkingpaymentwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadactinallowancewbcallcenter" element={<ProtectedRoute element={<Uploadactinallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportwbcallcenter' element={<ProtectedRoute element={<Uploadtransportwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadmotivationwbcallcenter" element={<ProtectedRoute element={<Uploadmotivationwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadsalescommissionwbcallcenter" element={<ProtectedRoute element={<Uploadsalescommissionwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadcoveringupallowancewbcallcenter" element={<ProtectedRoute element={<Uploadcoveringupallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancewbcallcenter" element={<ProtectedRoute element={<Uploadadvancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadtransportwbcallcenter" element={<ProtectedRoute element={<Uploadtransportwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadperformancewbcallcenter" element={<ProtectedRoute element={<Uploadperformancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadovertimewbcallcenter" element={<ProtectedRoute element={<Uploadovertimewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadnopaywbcallcenter" element={<ProtectedRoute element={<Uploadnopaywbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadadvancedeductionwbcallcenter" element={<ProtectedRoute element={<Uploadadvancedeductionwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadbdallowancewbcallcenter" element={<ProtectedRoute element={<Uploadbdallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/uploadpayeetaxwbcallcenter" element={<ProtectedRoute element={<Uploadpayeetaxwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancewbcallcenter' element={<ProtectedRoute element={<Uploadfestivaladvancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadexperienceallowancewbcallcenter' element={<ProtectedRoute element={<Uploadexperienceallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayswbcallcenter' element={<ProtectedRoute element={<Uploadworkingdayswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadminallowancewbcallcenter' element={<ProtectedRoute element={<Uploadadminallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowancewbcallcenter' element={<ProtectedRoute element={<Uploadmobileallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadloanswbcallcenter' element={<ProtectedRoute element={<Uploadloanswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherallowancewbcallcenter' element={<ProtectedRoute element={<Uploadotherallowancewbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionwbcallcenter' element={<ProtectedRoute element={<Uploadfestivaladvancedeductionwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadloandeductionwbcallcenter' element={<ProtectedRoute element={<Uploadloandeductionwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherdeductionwbcallcenter' element={<ProtectedRoute element={<Uploadotherdeductionwbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/uploadsalaryarrearssales' element={<ProtectedRoute element ={<Uploadsalaryarrearssales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalescommissionsales' element={<ProtectedRoute element ={<Uploadsalescommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfuelallowancesales' element={<ProtectedRoute element ={<Uploadfuelallowancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmngsupervisorallowancesales' element={<ProtectedRoute element ={<Uploadmngsupervisorallowancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowancesales' element={<ProtectedRoute element ={<Uploadmobileallowancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportsales' element={<ProtectedRoute element ={<Uploadtransportsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformancesales' element={<ProtectedRoute element ={<Uploadperformancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsupervisorcommissionsales' element={<ProtectedRoute element ={<Uploadsupervisorcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalacommissionsales' element={<ProtectedRoute element ={<Uploadsalacommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadklmcommissionsales' element={<ProtectedRoute element ={<Uploadklmcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route> 
        <Route path='/uploadmobitelcommissionales' element={<ProtectedRoute element ={<Uploadmobitelcommissionales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadpeotvremotecommissionsales' element={<ProtectedRoute element ={<Uploadpeotvremotecommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route> 
         <Route path='/uploadmemorytaxcommissionsales' element={<ProtectedRoute element ={<Uploadmemorytaxcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route> 
         <Route path='/uploadajcommissionsales' element={<ProtectedRoute element ={<Uploadajcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadcpearrearscollectingcommissionsales' element={<ProtectedRoute element ={<Uploadcpearrearscollectingcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadcpecollectingcommissionsales' element={<ProtectedRoute element ={<Uploadcpecollectingcommissionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadcommissionarrearssales' element={<ProtectedRoute element ={<Uploadcommissionarrearssales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadstampdutysales' element={<ProtectedRoute element ={<Uploadstampdutysales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadotherallowancesales' element={<ProtectedRoute element ={<Uploadotherallowancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadnopaysales' element={<ProtectedRoute element ={<Uploadnopaysales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadfestivaladvancedeductionsales' element={<ProtectedRoute element ={<Uploadfestivaladvancedeductionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadfestivaladvancesales' element={<ProtectedRoute element ={<Uploadfestivaladvancesales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadgroomingtrainingdeductionsales' element={<ProtectedRoute element ={<Uploadgroomingtrainingdeductionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadpreviousmonthtaxsales' element={<ProtectedRoute element ={<Uploadpreviousmonthtaxsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadpayeetaxsales' element={<ProtectedRoute element ={<Uploadpayeetaxsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadotherdeductionsales' element={<ProtectedRoute element ={<Uploadotherdeductionsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
         <Route path='/uploadnopaysales' element={<ProtectedRoute element ={<Uploadnopaysales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayssales' element={<ProtectedRoute element={<Uploadworkingdayssales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportsales' element={<ProtectedRoute element={<Uploadtransportsales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path='/uploadsalescommisionhemassr' element={<ProtectedRoute element={ <UploadsalescommisionhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancehemassr' element={<ProtectedRoute element={<UploadadvancehemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemassr' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemassr' element={<ProtectedRoute element={<UploadfestivaladvancehemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemassr' element={<ProtectedRoute element={<UploadadvancedeductionhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemassr' element={<ProtectedRoute element={<UploadnopayhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementhemassr' element={<ProtectedRoute element={ <UploadotherreimbursementhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemassr' element={<ProtectedRoute element={<UploadpayeetaxhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadgoyapaymenthemassr' element={<ProtectedRoute element={ <UploadgoyapaymenthemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemassr' element={<ProtectedRoute element={<UploadworkingdayshemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemassr' element={<ProtectedRoute element={<UploadsalaryarrearshemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfuelallowancehemassr' element={<ProtectedRoute element={<UploadfuelallowancehemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemassr' element={<ProtectedRoute element={<UploadperformancehemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemassr' element={<ProtectedRoute element={<UploadotherarrearshemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothradditionhemassr' element={<ProtectedRoute element={<UploadotheradditionhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemassr' element={<ProtectedRoute element={<UploadotherdeductionhemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        
        <Route path='/paymentdetailshemassr' element={<ProtectedRoute element={<PaymentdetailshemasSR/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


       
        <Route path='/uploadadvancehemasma' element={<ProtectedRoute element={<UploadadvancehemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemasma' element={<ProtectedRoute element={<UploadperformmanceallownacehemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemasma' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemasma' element={<ProtectedRoute element={<UploadfestivaladvancehemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemasma' element={<ProtectedRoute element={<UploadadvancedeductionhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemasma' element={<ProtectedRoute element={<UploadnopayhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasma' element={<ProtectedRoute element={<UploadpayeetaxhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasma' element={<ProtectedRoute element={<UploadworkingdayshemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasma' element={<ProtectedRoute element={<UploadsalaryarrearshemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfoodallowancehemasma' element={<ProtectedRoute element={<UploadfoodallowancehemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadspecialallowancehemasma' element={<ProtectedRoute element={<UploadspecialallowancehemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasma' element={<ProtectedRoute element={<UploadtransporthemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasma' element={<ProtectedRoute element={<UploadotherarrearshemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasma' element={<ProtectedRoute element={<UploadotheradditionhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasma' element={<ProtectedRoute element={<UploadotherdeductionhemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasma' element={<ProtectedRoute element={<PaymentdetailshemasMA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        

 
        <Route path='/uploadsalescommisionhemassrintern' element={<ProtectedRoute element={ <UploadsalescommisionhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancehemassrintern' element={<ProtectedRoute element={<UploadadvancehemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemassrintern' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemassrintern' element={<ProtectedRoute element={<UploadfestivaladvancehemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemassrintern' element={<ProtectedRoute element={<UploadadvancedeductionhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemassrintern' element={<ProtectedRoute element={<UploadnopayhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementsrintern' element={<ProtectedRoute element={ <UploadotherreimbursementSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemassrintern' element={<ProtectedRoute element={<UploadpayeetaxhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemassrintern' element={<ProtectedRoute element={<UploadworkingdayshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemassrintern' element={<ProtectedRoute element={<UploadsalaryarrearshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfuelallowancehemassrintern' element={<ProtectedRoute element={<UploadfuelallowancehemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadinternallowancehemassrintern' element={<ProtectedRoute element={<UploadinternallowancehemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadinternallowancearrearshemassrintern' element={<ProtectedRoute element={<UploadinternallowancearrearshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalescommissionarrearshemassrintern' element={<ProtectedRoute element={<UploadsalescommissionarrearshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementarrearshemassrintern' element={<ProtectedRoute element={<UploadotherreimbursementarrearshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemassrintern' element={<ProtectedRoute element={<UploadotherarrearshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemassrintern' element={<ProtectedRoute element={<UploadotheradditionhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemassrintern' element={<ProtectedRoute element={<UploadotherdeductionhemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemassrintern' element={<ProtectedRoute element={<PaymentdetailshemasSRintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

    

        <Route path='/uploadadvancehemasmaintern' element={<ProtectedRoute element={<UploadadvancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemasmaintern' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemasmaintern' element={<ProtectedRoute element={<UploadfestivaladvancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemasmaintern' element={<ProtectedRoute element={<UploadadvancedeductionhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemasmaintern' element={<ProtectedRoute element={<UploadnopayhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasmaintern' element={<ProtectedRoute element={<UploadpayeetaxhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasmaintern' element={<ProtectedRoute element={<UploadsalaryarrearshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasmaintern' element={<ProtectedRoute element={<UploadworkingdayshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadinternallowancehemasmaintern' element={<ProtectedRoute element={<UploadinternallowancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadinternallowancearrearshemasmaintern' element={<ProtectedRoute element={<UploadinternallowancearrearshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfoodallowancehemasmaintern' element={<ProtectedRoute element={<UploadfoodallowancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfoodallowancearrearshemasmaintern' element={<ProtectedRoute element={<UploadfoodallowancearrearshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemasmaintern' element={<ProtectedRoute element={<UploadperformanceallowancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadspecialallowancehemasmaintern' element={<ProtectedRoute element={<UploadspecialallowancehemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasmaintern' element={<ProtectedRoute element={<UploadtransporthemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransportallowancearrearshemasmaintern' element={<ProtectedRoute element={<UploadtransportallowancearrearshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasmaintern' element={<ProtectedRoute element={<UploadotherarrearshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasmaintern' element={<ProtectedRoute element={<UploadotheradditionhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasmaintern' element={<ProtectedRoute element={<UploadotherdeductionhemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasmaintern' element={<ProtectedRoute element={<PaymentdetailshemasMAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>




        <Route path='/uploadsalescommisionhemasmc' element={<ProtectedRoute element={ <UploadsalescommisionhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancehemasmc' element={<ProtectedRoute element={<UploadadvancehemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemasmc' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemasmc' element={<ProtectedRoute element={<UploadfestivaladvancehemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemasmc' element={<ProtectedRoute element={<UploadadvancedeductionhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemasmc' element={<ProtectedRoute element={<UploadnopayhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementmc' element={<ProtectedRoute element={ <UploadotherreimbursementhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasmc' element={<ProtectedRoute element={<UploadpayeetaxhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasmc' element={<ProtectedRoute element={<UploadworkingdayshemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasmc' element={<ProtectedRoute element={<UploadsalaryarrearshemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadattendenceallowancehemasmc' element={<ProtectedRoute element={<UploadattendenceallowancehemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadholidaypaymenthemasmc' element={<ProtectedRoute element={<UploadholidaypaymenthemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemasmc' element={<ProtectedRoute element={<UploadperformanceallowancehemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadspecialallowancehemasmc' element={<ProtectedRoute element={<UploadspecialallowancehemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasmc' element={<ProtectedRoute element={<UploadtransporthemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasmc' element={<ProtectedRoute element={<UploadotherarrearshemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasmc' element={<ProtectedRoute element={<UploadotheradditionhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasmc' element={<ProtectedRoute element={<UploadotherdeductionhemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasmc' element={<ProtectedRoute element={<PaymentdetailshemasMC/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        


        <Route path='/uploadsalescommisionhemasmcintern' element={<ProtectedRoute element={ <UploadsalescommisionhemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemasmcintern' element={<ProtectedRoute element={<UploadnopayhemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementmcintern' element={<ProtectedRoute element={ <UploadotherreimbursementMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasmcintern' element={<ProtectedRoute element={<UploadpayeetaxhemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>       
        <Route path='/uploadworkingdayshemasmcintern' element={<ProtectedRoute element={<UploadworkingdayshemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasmcintern' element={<ProtectedRoute element={<UploadsalaryarrearshemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>       
        <Route path='/uploadinternallowancehemasmcintern' element={<ProtectedRoute element={<UploadinternallowancehemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>       
        <Route path='/uploadattendenceallowancehemasmcintern' element={<ProtectedRoute element={<UploadattendenceallowancehemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadholidaypaymenthemasmcintern' element={<ProtectedRoute element={<UploadholidaypaymenthemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasmcintern' element={<ProtectedRoute element={<UploadtransporthemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformmanceallownacehemasmcintern' element={<ProtectedRoute element={ <UploadperformmanceallownacehemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransport2hemasmcintern' element={<ProtectedRoute element={ <Uploadtransport2hemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasmcintern' element={<ProtectedRoute element={<UploadotherarrearshemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasmcintern' element={<ProtectedRoute element={<UploadotheradditionhemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasmcintern' element={<ProtectedRoute element={<UploadotherdeductionhemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasmcintern' element={<ProtectedRoute element={<PaymentdetailshemasMCintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        

        
        <Route path='/uploadnopayhemasspa' element={<ProtectedRoute element={<UploadnopayhemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementhemasspa' element={<ProtectedRoute element={ <UploadotherreimbursementSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasspa' element={<ProtectedRoute element={<UploadpayeetaxhemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasspa' element={<ProtectedRoute element={<UploadworkingdayshemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasspa' element={<ProtectedRoute element={<UploadsalaryarrearshemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadattendenceallowancehemasspa' element={<ProtectedRoute element={<UploadattendenceallowancehemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemasspa' element={<ProtectedRoute element={<UploadperformmanceallownacehemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowance2hemasspa' element={<ProtectedRoute element={<Uploadperformanceallowance2hemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadspecialallowancehemasspa' element={<ProtectedRoute element={<UploadspecialallowancehemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasspa' element={<ProtectedRoute element={<UploadtransporthemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasspa' element={<ProtectedRoute element={<UploadotherarrearshemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasspa' element={<ProtectedRoute element={<UploadotheradditionhemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasspa' element={<ProtectedRoute element={<UploadotherdeductionhemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasspa' element={<ProtectedRoute element={<PaymentdetailshemasSPA/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>



        <Route path='/uploadnopayhemasspaintern' element={<ProtectedRoute element={<UploadnopayhemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherreimbursementhemasspaintern' element={<ProtectedRoute element={ <UploadotherreimbursementSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasspaintern' element={<ProtectedRoute element={<UploadpayeetaxhemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasspaintern' element={<ProtectedRoute element={<UploadworkingdayshemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasspaintern' element={<ProtectedRoute element={<UploadsalaryarrearshemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadattendenceallowancehemasspaintern' element={<ProtectedRoute element={<UploadattendenceallowancehemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowancehemasspaintern' element={<ProtectedRoute element={<UploadperformmanceallownacehemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadperformanceallowance2hemasspaintern' element={<ProtectedRoute element={<Uploadperformanceallowance2hemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadspecialallowancehemasspaintern' element={<ProtectedRoute element={<UploadspecialallowancehemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadtransporthemasspaintern' element={<ProtectedRoute element={<UploadtransporthemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadinternallowancehemasspaintern' element={<ProtectedRoute element={<UploadinternallowancehemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route> 
        <Route path='/uploadotherarrearshemasspaintern' element={<ProtectedRoute element={<UploadotherarrearshemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasspaintern' element={<ProtectedRoute element={<UploadotheradditionhemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasspaintern' element={<ProtectedRoute element={<UploadotherdeductionhemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>

        <Route path='/paymentdetailshemasspaintern' element={<ProtectedRoute element={<PaymentdetailshemasSPAintern/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


       
        <Route path='/uploadnopayhemasfmcg' element={<ProtectedRoute element={<UploadnopayhemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasfmcg' element={<ProtectedRoute element={<UploadpayeetaxhemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasfmcg' element={<ProtectedRoute element={<UploadworkingdayshemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadsalaryarrearshemasfmcg' element={<ProtectedRoute element={<UploadsalaryarrearshemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploaddataallowancehemasfmcg' element={<ProtectedRoute element={<UploaddataallowancehemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowancehemasfmcg' element={<ProtectedRoute element={<UploadmobileallowanchemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotallowancehemasfmcg' element={<ProtectedRoute element={<UploadotallowancehemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploaddotallowancehemasfmcg' element={<ProtectedRoute element={<UploaddotallowancehemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>        
        <Route path='/uploadotherarrearshemasfmcg' element={<ProtectedRoute element={<UploadotherarrearshemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasfmcg' element={<ProtectedRoute element={<UploadotheradditionhemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasfmcg' element={<ProtectedRoute element={<UploadotherdeductionhemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path='/paymentdetailshemasfmcg' element={<ProtectedRoute element={<PaymentdetailshemasFMCG/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route> 



        <Route path='/uploadadvancehemasdriver' element={<ProtectedRoute element={<UploadadvancehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancedeductionhemasdriver' element={<ProtectedRoute element={<UploadfestivaladvancedeductionhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfestivaladvancehemasdriver' element={<ProtectedRoute element={<UploadfestivaladvancehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadadvancedeductionhemasdriver' element={<ProtectedRoute element={<UploadadvancedeductionhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnopayhemasdriver' element={<ProtectedRoute element={<UploadnopayhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadpayeetaxhemasdriver' element={<ProtectedRoute element={<UploadpayeetaxhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadworkingdayshemasdriver' element={<ProtectedRoute element={<UploadworkingdayshemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploaddotallowancehemasdriver' element={<ProtectedRoute element={<UploaddotallowancehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadfullbataallowancehemasdriver' element={<ProtectedRoute element={<UploadfullbataallowancehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadmobileallowancehemasdriver' element={<ProtectedRoute element={<UploadmobileallowanchemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadnightallowancehemasdriver' element={<ProtectedRoute element={<UploadnightallowancehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadovertimehemasdriver' element={<ProtectedRoute element={<UploadovertimehemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotherarrearshemasdriver' element={<ProtectedRoute element={<UploadotherarrearshemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadotheradditionhemasdriver' element={<ProtectedRoute element={<UploadotheradditionhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadothrdeductionhemasdriver' element={<ProtectedRoute element={<UploadotherdeductionhemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path='/paymentdetailshemasdriver' element={<ProtectedRoute element={<PaymentdetailshemasDriver/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>


        <Route path='/uploadimmediatecsv' element={<ProtectedRoute element={<Uploadtimmediatecsv/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/uploadterminatecsv' element={<ProtectedRoute element={<Uploadterminatecsv/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>



        <Route path='/immediatecsvtemplate' element={<ProtectedRoute element={<CsvTemplateIntermediate/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/terminatecsvtemplate' element={<ProtectedRoute element={<CsvTemplate/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/employeecsvtemplate' element={<ProtectedRoute element={<Employeecsv/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/allowancecsvtemplate' element={<ProtectedRoute element={<Allowancecsv/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>




       
        <Route path='/paymentdetails' element={<ProtectedRoute element={<Paymentdetails/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path="/paymentdetailsayathitrust" element={<ProtectedRoute element={<Paymentdetailsayathitrust/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentdetailsedinborough' element={<ProtectedRoute element={<Paymentdetailsedinborough/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentdetailsrdbcallcenter' element={<ProtectedRoute element={<Paymentdetailsrdbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentdetailswbcallcenter' element={<ProtectedRoute element={<Paymentdetailswbcallcenter/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentsetailssales' element={<ProtectedRoute element={<Paymentdetailshemassales/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentdetailsterminate' element={<ProtectedRoute element={<Paymentdetailsterminate/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/paymentdetailsintermediate' element={<ProtectedRoute element={<Paymentdetailsintermediate/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
        <Route path='/attendence' element={<ProtectedRoute element={<Attendance/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>



        <Route path='/closepaymentdetails' element={<ProtectedRoute element= {<Closepaymentdetails/>}allowedRoles={['Finance Admin']}/>}></Route>


{/* clear payroll */}

<Route path='/clearpayroll' element={<ProtectedRoute element={<Clearpayroll/>}allowedRoles={['Admin', 'Super Admin']}/>}></Route>
      </Routes>
      </BrowserRouter>
  );
}

export default App;



