import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Employeereport.css'
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';

export default function Employeereport() {
  const [searchResults, setSearchResults] = useState([]);
  const [active, setActive] = useState([]);
  const [designations, setDesignation] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState ('');
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');

   

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const active = new Set();

        const designationSet = new Set();
        const monthSet = new Set();
        const yearSet = new Set();
        const companySet =new Set();
        const empnoset = new Set();

        res.data.forEach((employee) => {
          const joinedDate = new Date(employee.joinedDate);
          const monthName = joinedDate.toLocaleString('default', { month: 'long' });
          const year = joinedDate.getFullYear().toString();

          active.add(employee.employeeActive);
          designationSet.add(employee.designation);
          monthSet.add(monthName);
          yearSet.add(year);
          companySet.add(employee.workLocation);
          empnoset.add(employee.empNo);
        });
        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          // Check if both `a` and `b` are numbers (employee numbers)
          if (!isNaN(a) && !isNaN(b)) {
            return a - b; // Sort numbers numerically
          }
          return a.localeCompare(b); // Sort strings alphabetically
        });

        setEmpnos(sortedEmpnos);
        setActive(Array.from(active));

        setDesignation(Array.from(designationSet));
        setCompanyNames(Array.from(companySet));
        const monthsOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        setMonths(Array.from(monthSet).sort((a, b) => {
          return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
        }));
        setYears(Array.from(yearSet));
         
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployees();
  }, []);

  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/employees',{
        headers: {
          Authorization: token,
        },
      });
      const employees = response.data;

      let filteredResults = employees;

      if (selectedMonth) {
        filteredResults = filteredResults.filter(
          (employee) => new Date(employee.joinedDate).toLocaleString('default', { month: 'long' }) === selectedMonth
        );
      }

      if (selectedYear) {
        filteredResults = filteredResults.filter(
          (employee) => new Date(employee.joinedDate).getFullYear().toString() === selectedYear
        );
      }

      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (employee) => employee.employeeActive === selectedActive
        );
      }
      if(selectedDesignation) {
        filteredResults = filteredResults.filter(
          (employee) => employee.designation === selectedDesignation
        )
      }
      if(selectedCompany) {
        filteredResults = filteredResults.filter(
          (employee) => employee.workLocation === selectedCompany
        )
      }
      if (selectedEmployeeNo) {
        filteredResults = filteredResults.filter(
          (employee) => employee.empNo === selectedEmployeeNo
        );
      }

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error retrieving employee data:', error);
    }
  };

  const GeneratePDF = async () => {
    const PDF = new jsPDF('landscape', 'px', 'a3'); // Set page orientation to landscape
    PDF.setFontSize(7);

    try {
      const headers = [
        'Emp No',
  'Epf No',
  'Title',
  'Display Name',
  'Full Name',
  'Designation',
  'Joined Date',
  'Employee Type',
  'Contarct Start Date',
  'Contract End Date',
  'Work Location',
  'Basic Salary',
  'NIC',
  'Gender',
  'DOB',
  'Email',
  'Contact No',
  'Address',
  'Bank Name',
  'Bank Code',
  'Branch Name',
  'Branch Code',
  'Account No',

      ];
      const data = [];

      const tableRows = document.querySelectorAll('.Dts');
      tableRows.forEach((row) => {
        const rowData = [];
        const columns = row.querySelectorAll('td');
        columns.forEach((column) => {
          rowData.push(column.innerText);
        });
        data.push(rowData);
      });
      PDF.setFontSize(14);
      const customTitle = 'Employees Details';
      const headerMargin = 13; // Set the top margin for the header
      const titleTopMargin = 20; // Set the top margin for the title

      PDF.text(customTitle, 10, titleTopMargin + headerMargin);

      PDF.setFontSize(12);
      let headtext = '';
      if ( selectedDesignation && selectedMonth && selectedYear) {
        headtext = `(${selectedDesignation}) - ${selectedYear} / ${selectedMonth}`;
      } 
      else if ( selectedCompany) {
        headtext = `${selectedCompany}`;
      }
      else if ( selectedDesignation && selectedMonth) {
        headtext = `(${selectedDesignation}) - ${selectedMonth}`;
      }
      else if ( selectedDesignation && selectedYear) {
        headtext = `${selectedDesignation} / ${selectedYear}`;
      }
      else if ( selectedMonth && selectedYear) {
        headtext = `  ${selectedMonth}/${selectedYear}`;
      }
      else if ( selectedMonth) {
        headtext = `  ${selectedMonth}`;
      }
      else if (selectedDesignation && selectedMonth && selectedYear) {
        headtext = `${selectedDesignation} - ${selectedMonth}/${selectedYear}`;
      }
      else if (selectedDesignation && selectedMonth) {
        headtext = `${selectedDesignation} - ${selectedMonth}`;
      }
      else if (selectedDesignation && selectedYear) {
        headtext = `${selectedDesignation} - ${selectedYear}`;
      }
      else if (selectedMonth && selectedYear) {
        headtext = `${selectedMonth}/${selectedYear}`;
      }
     
      else if (selectedMonth) {
        headtext = `${selectedMonth}`;
      }
      else if (selectedYear) {
        headtext = `${selectedYear}`;
      }
     
      else if (selectedDesignation) {
        headtext = `${selectedDesignation}`;
      }
      else {
        headtext = ' ';
      }
      PDF.text(headtext, 15, titleTopMargin + headerMargin + 15);
      PDF.autoTable({
        startY: titleTopMargin + headerMargin + 30, // Adjust startY to include the top margin
        head: [headers],
        body: data,
        margin: { top: headerMargin }, // Set the top margin for the header
      });

      PDF.save('Employee_details_report.pdf');
    } catch (error) {
      console.error('Error retrieving employee data:', error);
    }
  };

  const handleMonthFilter = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearFilter = (event) => {
    setSelectedYear(event.target.value);
  };
 

  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };

const handleDesignationFilter = (event) => {
  setSelectedDesignation(event.target.value)
}

const handleCompanyFilter = (event) => {
  setSelectedCompany(event.target.value)
}
const handleEmployeeNoFilter = (event) => {
  setSelectedEmployeeNo(event.target.value);
};

const prepareCSVData = () => {
  const csvData = searchResults.map((employee) => [
    employee.empNo,
    employee.epfNo,
    employee.displayName,
    employee.workLocation,
    employee.nic,
    employee.dob,
    employee.currentAddress,
    employee.dep_name,
    employee.dep_fullName,
    employee.relationship,
    employee.dep_nic,
    employee.dep_dob,
    employee.dep_currentAddress, 

    employee.dep_name1,
    employee.dep_fullName1,
    employee.relationship1,
    employee.dep_nic1,
    employee.dep_dob1,
    employee.dep_currentAddress1,

    employee.dep_name2,
    employee.dep_fullName2,
    employee.relationship2,
    employee.dep_nic2,
    employee.dep_dob2,
    employee.dep_currentAddress2,

    employee.dep_name3,
    employee.dep_fullName3,
    employee.relationship3,
    employee.dep_nic3,
    employee.dep_dob3,
    employee.dep_currentAddress3, 


  ]);

  const header1 = [
    // Employee details headers
    'Employee details',
    '',
    '',
    '',
    '',
    '',
    '',
    'Dependent details',
  ];
  const header2 = [
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    'Dependent 1',
    '',
    '',
    '',
    '',
    '',
    'Dependent 2',
    '',
    '',
    '',
    '',
    '',
    'Dependent 3',
    '',
    '',
    '',
    '',
    '',
    'Dependent 4',
    '',
    '',
    '',
    '',
    '',

  ]

 // Add the header row for the CSV data
    const headers = [
        // Employee details headers
        'Emp No',
        'Epf No',
        'Display Name',
        'Work Location',
        'NIC',
        'DOB',
        'Address',
        
        'Name',
        'Full Name',
        'Relationship',
        'NIC',
        'DOB',
        'Address',
        
        'Name',
        'Full Name',
        'Relationship',
        'NIC',
        'DOB',
        'Address',
        
        'Name',
        'Full Name',
        'Relationship',
        'NIC',
        'DOB',
        'Address',
        
        'Name',
        'Full Name',
        'Relationship',
        'NIC',
        'DOB',
        'Address',
        // ... (add other headers as needed)
      ];
const emptyRow = Array(headers.length).fill('');

return [header1,header2,headers,emptyRow, ...csvData];// Concatenate headers with the data rows
};


  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Dependent Report</h1>
<div className='filter_section'>
<div className="row mb-3 ">
          <div className="col-md-4">
              <label htmlFor="companyFilter">Work Location:</label>
              <select
                id="companyFilter"
                value={selectedCompany}
                className="form-select"
                onChange={handleCompanyFilter}
                >
                <option value="">All</option>
                {companyNames.map((company) =>
                  <option key={company} value={company}>
                    {company}
                  </option>
                  )}
                  </select>
                  </div>

          <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
              <div className="col-md-4">
              <label htmlFor="designationFilter">Designation:</label>
              <select
                id="designationFilter"
                value={selectedDesignation}
                className="form-select"
                onChange={handleDesignationFilter}
              >
                <option value="">All</option>
                {designations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
            </div>
            </div>
            <div className="row mb-3">
            <div className="col-md-4">
              <div className="month-filter">
                <label htmlFor="monthFilter">Filter by Joined Month:</label>
                <select
                  id="monthFilter"
                  value={selectedMonth}
                  className="form-select"
                  onChange={handleMonthFilter}
                >
                  <option value="">All</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="year-filter">
                <label htmlFor="yearFilter">Filter by Joined Year:</label>
                <select
                  id="yearFilter"
                  value={selectedYear}
                  className="form-select"
                  onChange={handleYearFilter}
                >
                  <option value="">All</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
                <label htmlFor="employeeNoFilter">Employee No:</label>
                <select
                  id="empNo"
                  value={selectedEmployeeNo}
                  className="form-control"
                  onChange={handleEmployeeNoFilter}
                >
                  <option value="">All</option>
                  {empnos.map((empno, index) => (
                    <option key={index} value={empno}>
                      {empno}
                    </option>
                  ))}
                </select>
              
            </div>
            </div>
        
       

        <button className='filter' onClick={handleFilter}>Apply Filters</button>
</div>
        

        <div>
          <table className="table">
            <thead>
            <tr>
            <th rowSpan={2} colSpan={7} style={{ textAlign: 'center' }}>Employee Details</th>
            <th colSpan={24} style={{ textAlign: 'center' }}>Dependent Details</th>
    </tr>
    <tr>
            <th colSpan={6} style={{ textAlign: 'center' }}>Dependent1</th>
            <th colSpan={6} style={{ textAlign: 'center' }}>Dependent2</th>
            <th colSpan={6} style={{ textAlign: 'center' }}>Dependent3</th>
            <th colSpan={6} style={{ textAlign: 'center' }}>Dependent4</th>
    </tr>

              <tr>
              <th>Emp No</th>
              <th>Epf No</th>
                <th>Display Name</th>
                <th>Work Location</th>
                <th>NIC</th>
                <th>DOB</th>
                <th>Address</th>


                <th>Name with ini.</th>
                <th>Full Name</th>
                <th>Relationship</th>
                <th>NIC</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Name with ini.</th>
                <th>Full Name</th>
                <th>Relationship</th>
                <th>NIC</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Name with ini.</th>
                <th>Full Name</th>
                <th>Relationship</th>
                <th>NIC</th>
                <th>DOB</th>
                <th>Address</th>
                <th>Name with ini.</th>
                <th>Full Name</th>
                <th>Relationship</th>
                <th>NIC</th>
                <th>DOB</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              {searchResults.map((employee) => (
                <tr className="Dts" key={employee.id}>
                  <td>{employee.empNo}</td>
                  <td>{employee.epfNo}</td>
                  <td>{employee.displayName}</td>
                  <td>{employee.workLocation}</td>
                  <td>{employee.nic}</td>
                  <td>{employee.dob}</td>
                  <td>{employee.currentAddress}</td>
                
<td>{employee.dep_name}</td>
<td>{employee.dep_fullName}</td>
<td>{employee.relationship}</td>
<td>{employee.dep_nic}</td>
<td>{employee.dep_dob}</td>
<td>{employee.dep_currentAddress}</td> 

<td>{employee.dep_name1}</td>
<td>{employee.dep_fullName1}</td>
<td>{employee.relationship1}</td>
<td>{employee.dep_nic1}</td>
<td>{employee.dep_dob1}</td>
<td>{employee.dep_currentAddress1}</td>

<td>{employee.dep_name2}</td>
<td>{employee.dep_fullName2}</td>
<td>{employee.relationship2}</td>
<td>{employee.dep_nic2}</td>
<td>{employee.dep_dob2}</td>
<td>{employee.dep_currentAddress2}</td>

<td>{employee.dep_name3}</td>
<td>{employee.dep_fullName3}</td>
<td>{employee.relationship3}</td>
<td>{employee.dep_nic3}</td>
<td>{employee.dep_dob3}</td>
<td>{employee.dep_currentAddress3}</td> 


                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='repos'>

<div>
  <button className='pdf' onClick={GeneratePDF}>Generate PDF</button>
  </div>
  <div>
  <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename="Employee_dependent_details.csv"
    className="csv"
    target="_blank"
  >
    Genarate CSV
  </CSVLink>
  </div>
     </div>

        
      </div>
      
      </div>
  );
}
