import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import './Paymentreport.css'
import Sidebar from '../component/Sidebar'
import Cookies from 'js-cookie';
import Select from 'react-select';

export default function Paymentreport() {
  const [searchResults, setSearchResults] = useState([]);
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [active, setActive] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/payments/report',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const empnoset = new Set();
        const monthSet = new Set();
        const yearSet = new Set();
        const companyNames = new Set();
        const active = new Set();
        res.data.forEach((payment) => {
      
          empnoset.add(payment.empNo);
          monthSet.add(payment.uploadMonth);
          yearSet.add(payment.uploadYear);
          companyNames.add(payment.workLocation);
          active.add(payment.paymentType);
        });
        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          // Check if both `a` and `b` are numbers (employee numbers)
          if (!isNaN(a) && !isNaN(b)) {
            return a - b; // Sort numbers numerically
          }
          return a.localeCompare(b); // Sort strings alphabetically
        });

        setEmpnos(
          sortedEmpnos.map((empno) => ({ value: empno, label: empno }))
        );

        const monthsOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        setMonths(Array.from(monthSet).sort((a, b) => {
          return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
        }));
        setYears(Array.from(yearSet));
        setCompanyNames(Array.from(companyNames));
        setActive(Array.from(active));

      } catch (error) {
        console.log(error);
      }
    };
    fetchPayments();
  }, []);




  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/payments/report',{
        headers: {
          Authorization: token,
        },
      });
      const payments = res.data;

      let filteredResults = payments;
      if (selectedEmployeeNo) {
        filteredResults = filteredResults.filter(
          (payment) => payment.empNo === selectedEmployeeNo
        );
      }

      if (selectedMonth) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadMonth  === selectedMonth
        );
      }

      if (selectedYear) {
        filteredResults = filteredResults.filter(
          (payment) => payment.uploadYear === selectedYear
        );
      }
   
      if (selectedCompany) {
        filteredResults = filteredResults.filter(
          (payment) => payment.workLocation === selectedCompany
        );
      }
      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (payment) => payment.paymentType === selectedActive
        );
      }
      setSearchResults(filteredResults);
    } catch (error) {
      console.log(error);
    }
  };
  const handleMonthFilter = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearFilter = (event) => {
    setSelectedYear(event.target.value);
  };
  const handleCompanyFilter = (event) => {
    setSelectedCompany(event.target.value);
  }
  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };
  const handleEmployeeNoFilter = (selectedOption) => {
    setSelectedEmployeeNo(selectedOption ? selectedOption.value : '');
  };

  const prepareCSVData = () => {
    // Define common headers
    const commonHeaders = [
      'Emp No',
      'Epf No',
      'Emp Name',
      'Bank Code',
      'Branch Code',
      'Account Number',
      
     
      
    ];
  
    // Define headers specific to each company along with their corresponding backend API values
    const headersMapping = {
      'Emp No': 'empNo',
      'Epf No': 'epfNo',
      'Emp Name': 'fullName',
      'Basic Salary': 'salary',
      'Budgetary Allowance': 'budAllowance',
      'Nopay Amount': 'nopayAmount',
      'Consolidated Salary': 'consolidateSalary',
      'Working Days' : 'workingDays',
      'EPF(12%)': 'epf12',
      'EPF(8%)': 'epf8',
      'ETF(3%)': 'etf3',

      'Motivation Alllowance': 'motivationAllowance',
      'Salary Arrears': 'salaryArrears',
      'OT Allowance': 'overtimeAllowance',
      'DOT Arrears': 'dotArrears',
      'Extraworking Payment': 'extraworkingPayment',
      'Actin Allowance': 'actinAllowance',
      'Transport Allowance': 'transportAllowance',
      'Performance Allowance': 'performanceAllowance',
      'Sales Commision': 'salesCommission',
      'Coveringup Allowance': 'coveringupAllowance',
      'OT Arrears': 'overtimeArrears',
      'DOT Allowance': 'dotAllowance',
      'Salary Advance': 'advance',
      'Account Number': 'accountNo',
      'Bank Code': 'bankCode',
      'Salary Advance Deduction': 'advanceDeduction',
      'Branch Code': 'branchCode',
      'Nopay Hours': 'nopay',
      'Nopay': 'nopayAmount',
      'Nopay Rate': 'nopayRate',
      'Gross Salary': 'grossAmount',
      'Total Allowance': 'totalAllowance',
      'Total Earning': 'totalEarning',
      'Stamp Duty': 'stampDuty',
      'Payee Tax': 'payeetax',
      'Total Deduction': 'totalDeduction',
      'Net Salary': 'netSalary',
      'Experience Allowance': 'experienceAllowance',
      'Festival Advance': 'festivalAdvance',
      'Admin Allowance': 'adminAllowance',
      'Mobile Allowance': 'mobileAllowance',
      'Fix Allowance': 'fixAllowance',
      'Loan' : 'loan',
      'Other Allowance' : 'otherAllowance',
      'Fes Advance Deduction' : 'festivaladvanceDeduction',
      'Loan Deduction' : 'loanDeduction',
      'Other Deduction' : 'otherDeduction',
      'Sales Incentive' : 'salesIncentive',
      'Sales Driver Inc1' : 'salesdriveIncentive1',
      'Sales Driver Inc2' : 'salesdriveIncentive2',
      'Sales Driver Inc3' : 'salesdriveIncentive3',
      'Welfare' : 'welfare',
      'Welfare Deduction' : 'welfareDeduction',
      'Mobile Deduction': 'mobileDeduction',
      'BD Commission': 'bdCommission',
      'Fuel Allowance':'fuelAllowance',
      'Mng Supervisor Allowance': 'mngSupervisorAllowance',
      'Supervisor Commission': 'supervisorCommission',
      'Sala Commission': 'salaCommission',
      'KLM Commission': 'klmCommission',
      'Mobitel Commission': 'mobitelCommission',
      'Peo Tv Remote Commission': 'peoTvRemoteCommission',
      'Memory Tax Commission': 'memoryTaxCommission',
      'Grooming Training Deduction': 'groomingTrainingDeduction',
      'AJ Commission': 'ajCommission',
      'Cpe Arrears Collecting Commission': 'cpeArrearsCollectingCommission',
      'Cpe Collecting Commission': 'cpeCollectingCommission',
      'commission Arrears': 'commissionArrears',
      'Stamp Dutyplus': 'stampDutyplus',
      'Previous Tax': 'previousTax',
      'Other Reimbursement': 'otherReimbursement',
      'Goya Payment':'goyaPayment',
      'InternAllowance':'internAllowance',
      'Intern Allowance Arrears':'internallowanceArrears',
      'Other Reimbursement Arrears':'otherreimbursementArrears',
      'Sales Commission Arrears':'salescommissionArrears',
      'Food Allowance':'foodAllowance',
      'Special Allowance':'specialAllowance',
      'Transport Allowance Arrears':'transportallowanceArrears',
      'Food Arrears':'foodArrears',
      'Night Alllowance':'nightAlllowance',
      'Data Allowance':'dataAllowance',
      'Performance Allowance 2':'performanceAllowance2',
      'Attendence Allowance':'attendenceAllowance',
      'Holiday Payment':'holidayPayment',
      'Transport Allowance2':'transportAllowance2',
      'Full Bata Allowance': 'fullbataAllowance',
      "Other Arrears" : 'otherArrears',
      "Other Additions": 'otherAddition' ,
      // Add more mappings as needed
    };
  
    // Define headers specific to each company
    const companySpecificHeaders = {
      Direct: [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Hours',
      'Nopay Rate',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',

        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,

        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'direct' company
      ],
      'Edinborough Products (Pvt) Ltd': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Hours',
        'Nopay Rate',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Sales Incentive',
        'Sales Driver Inc1',
        'Sales Driver Inc2',
        'Sales Driver Inc3',
        'Extraworking Payment',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Salary Advance',
        'Festival Advance',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Welfare',
        'Welfare Deduction',
        'Mobile Deduction',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
      
        // ... other headers for the 'edingburugh' company
      ],
      'RDB Call Centre': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Hours',
      'Nopay Rate',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Loan',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,
        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Water Board Call Centre': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Hours',
      'Nopay Rate',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Loan',
        'Admin Allowance',
        'Mobile Allowance',
        'Loan' ,
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'Loan Deduction' ,
        'EPF(8%)',
        'Other Deduction' ,
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Sales Team': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Hours',
        'Nopay Rate',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Talentfort Franchise Operation': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Hours',
        'Nopay Rate',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Regional Business Development Manager': [
        'Basic Salary',
        'Budgetary Allowance',
        'Fix Allowance',
        'Consolidated Salary',
        'Nopay Hours',
        'Nopay Rate',
        'Nopay Amount',
        'Salary Arrears',
        'Gross Salary',
        'Working Days',
        'Transport Allowance',
        'Performance Allowance',
        'Sales Commision',
        'Festival Advance',
        'Mobile Allowance',
        'Mobitel Commission',
        'Fuel Allowance',
        'Mng Supervisor Allowance',
        'Supervisor Commission',
        'Sala Commission',
        'KLM Commission',
        'Peo Tv Remote Commission',
        'Memory Tax Commission',
        'AJ Commission',
        'Cpe Arrears Collecting Commission',
        'Cpe Collecting Commission',
        'commission Arrears',
        'Stamp Dutyplus',
        'Other Allowance',
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Grooming Training Deduction',
        'Stamp Duty',
        'Payee Tax',
        'Other Deduction',
        'Previous Tax',
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'edingburugh' company
      ],
      'Ayathi Trust': [
        'Basic Salary',
      'Budgetary Allowance',
      'Fix Allowance',
      'Consolidated Salary',
      'Nopay Hours',
      'Nopay Rate',
      'Nopay Amount',
      'Salary Arrears',
      'Gross Salary',
      'Working Days',
        'OT Allowance',
        'OT Arrears',
        'DOT Arrears',
        'DOT Allowance',
        'Extraworking Payment',
        'Actin Allowance',
        'Transport Allowance',
        'Performance Allowance',
        'Motivation Alllowance',
        'Experience Allowance',
        'Sales Commision',
        'Coveringup Allowance',
        'Salary Advance',
        'Festival Advance',
        'BD Commission',
        'Total Allowance',
        'Total Earning',
        'Stamp Duty',
        'Payee Tax',
        'Salary Advance Deduction',
        'Fes Advance Deduction' ,
        'EPF(8%)',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)',
        // ... other headers for the 'direct' company
      ],
      'Hemas SR':[
    'Basic Salary',
    'Budgetary Allowance',
    'Nopay Rate',
    'Nopay Hours',
    'Nopay Amount',
    'Consolidated Salary',
    'Gross Salary',
    'Working Days' ,
    'Salary Arrears',
    'Sales Commision',
    'Other Reimbursement',
    'Goya Payment',
    'Fuel Allowance',
    'Festival Advance',
    'Salary Advance',
    "Other Arrears",
    "Other Additions",
    'Total Allowance',
    'Total Earning',
    'Fes Advance Deduction',
    'Salary Advance Deduction',
    'EPF(8%)',
    'Stamp Duty',
    'Payee Tax',
    'Total Deduction',
    'Net Salary',
    'EPF(12%)',
    'ETF(3%)',
      ],
    'Hemas SR-Intern':
    [
      'Basic Salary',
      'Budgetary Allowance',
      'Nopay Rate',
      'Nopay Hours',
      'Nopay Amount',
      'Consolidated Salary',
      'Gross Salary',
      'Working Days',
      'Salary Arrears',
      'Sales Commision',
      'InternAllowance',
      'Intern Allowance Arrears',
      'Other Reimbursement',
      'Sales Commission Arrears',
      'Other Reimbursement Arrears',
      'Fuel Allowance',
      'Festival Advance',
      'Salary Advance',
      "Other Arrears",
      "Other Additions",
      'Total Allowance',
      'Total Earning',
      'Fes Advance Deduction',
      'Salary Advance Deduction',
      'EPF(8%)',
      'Stamp Duty',
      'Payee Tax',
      'Total Deduction',
      'Net Salary',
      'EPF(12%)',
      'ETF(3%)'
      ],
      'Hemas MA':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Performance Allowance',
        'Transport Allowance',
        'Food Allowance',
        'Special Allowance',
        'Festival Advance',
        'Salary Advance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MA-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Intern Allowance Arrears',
        'Performance Allowance',
        'Transport Allowance',
        'Food Allowance',
        'Special Allowance',
        'Transport Allowance Arrears',
        'Food Arrears',
        'Festival Advance',
        'Salary Advance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MC':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Attendence Allowance',
        'Performance Allowance',
        'Holiday Payment',
        'Transport Allowance',
        'Other Reimbursement',
        'Special Allowance',
        'Sales Commision',
        'Festival Advance',
        'Salary Advance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'Fes Advance Deduction',
        'Salary Advance Deduction',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas MC-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Attendence Allowance',
        'Performance Allowance',
        'Holiday Payment',
        'Transport Allowance',
        'Transport Allowance2',
        'Other Reimbursement',
        'Sales Commision',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas SPA':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Attendence Allowance',
        'Performance Allowance',
        'Performance Allowance 2',
        'Transport Allowance',
        'Other Reimbursement',
        'Special Allowance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas SPA-Intern':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'InternAllowance',
        'Performance Allowance',
        'Performance Allowance 2',
        'Transport Allowance',
        'Special Allowance',
        'Attendence Allowance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas FMCG':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'Data Allowance',
        'Mobile Allowance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
      'Hemas Driver':[
        'Basic Salary',
        'Budgetary Allowance',
        'Nopay Rate',
        'Nopay Hours',
        'Nopay Amount',
        'Consolidated Salary',
        'Gross Salary',
        'Working Days' ,
        'Salary Arrears',
        'OT Allowance',
        'DOT Allowance',
        'Full Bata Allowance',
        'Night Alllowance',
        'Mobile Allowance',
        "Other Arrears",
        "Other Additions",
        'Total Allowance',
        'Total Earning',
        'EPF(8%)',
        'Stamp Duty',
        'Payee Tax',
        'Total Deduction',
        'Net Salary',
        'EPF(12%)',
        'ETF(3%)'
      ],
    };
  
    
    



    const selectedCompanyHeaders = companySpecificHeaders[selectedCompany] || [];
  
    // Combine common headers with company-specific headers
    const headers = [...commonHeaders, ...selectedCompanyHeaders];
  
    // Map headers to backend API values
    const apiValues = headers.map((header) => headersMapping[header]);
  
    const csvData = searchResults.map((payment) => [
      ...apiValues.map((apiValue) => payment[apiValue] || ''),
    ]);
  
    return [headers, ...csvData];
  };
  


  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Monthly Payment Report</h1>
        <div className="filter_section">
          <div className="row mb-3">
          <div className="col-md-4">
              <div className="company-filter">
                <label htmlFor="companyFilter">Filter by Work Location:</label>
                <select
                  id="companyFilter"
                  value={selectedCompany}
                  className="form-select"
                  onChange={handleCompanyFilter}
                  >
                    <option value="">All</option>
                    {companyNames.map((company, index) =>
                      <option key={index} value={company}>
                        {company}
                      </option>
                      )}
                  </select>
                  </div>
                  </div>
            <div className="col-md-4">
              <div className="month-filter">
                <label htmlFor="monthFilter">Filter by Month:</label>
                <select
                  id="monthFilter"
                  value={selectedMonth}
                  className="form-select"
                  onChange={handleMonthFilter}
                >
                  <option value="">All</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="year-filter">
                <label htmlFor="yearFilter">Filter by Year:</label>
                <select
                  id="yearFilter"
                  value={selectedYear}
                  className="form-select"
                  onChange={handleYearFilter}
                >
                  <option value="">All</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="row mb-3">
          <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((activestatus) => (
                  <option key={activestatus} value={activestatus}>
                    {activestatus}
                  </option>
                ))}
              </select>
            </div>
            <div className='col-md-4'>
              <label htmlFor='employeeNoFilter'>Employee No:</label>
              <Select
                id='empNo'
                value={empnos.find(
                  (option) => option.value === selectedEmployeeNo
                )}
                options={empnos}
                isClearable
                onChange={handleEmployeeNoFilter}
                placeholder='Select or type Employee No'
                isSearchable
              />
            </div>
          </div>
          
          <button className="filter" onClick={handleFilter}>
            Apply Filters
          </button>
        </div>

        <div>
          <table className="table">
            <thead>
              <tr>
              <th>Emp No</th>
              <th>Epf No</th>
                <th>Emp Name</th>
                <th>Basic Salary</th>
                <th>Bud. Allowance</th>
                <th>Consolidate Salary</th>
                <th>Nopay Rate</th>
                <th>Nopay Hours</th>
                <th>Nopay Amount</th>
                <th>Salary Arrears</th>
                <th>Gross Salary</th>
                <th>Total Allowance</th>
                <th>Total Earning</th>
                <th>Total Deduction</th>
                <th>Net Salary</th>
                <th>Bank Code</th>
                <th>Branch Code</th>
                <th>Account No</th>
                
              </tr>
            </thead>
            <tbody>
              {searchResults.map((payment) => (
                <tr key={payment.id}>
                  <td>{payment.empNo}</td>
                  <td>{payment.epfNo}</td>
                  <td>{payment.fullName}</td>
                  <td className="right-aligned">{payment.salary}</td>
                  <td className="right-aligned">{payment.budAllowance}</td>
                  <td className="right-aligned">{payment.consolidateSalary}</td>
                  <td className="right-aligned">{payment.nopayRate}</td>
                  <td className="right-aligned">{payment.nopay}</td>
                  <td className="right-aligned">{payment.nopayAmount}</td>
                  <td className="right-aligned">{payment.salaryArrears}</td>
                  <td className="right-aligned">{payment.grossAmount}</td>
                  <td className="right-aligned">{payment.totalAllowance}</td>
                  <td className="right-aligned">{payment.totalEarning}</td>
                  <td className="right-aligned">{payment.totalDeduction}</td>
                  <td className="right-aligned">{payment.netSalary}</td>
                  <td className="right-aligned">{payment.bankCode}</td>
                  <td className="right-aligned">{payment.branchCode}</td>
                  <td className="right-aligned">{payment.accountNo}</td>
                </tr>
                
              ))}
            </tbody>
          </table>
        </div>
        <div className='repos'>

  <div>
  <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename={`Monthly_payment_${selectedMonth}_${selectedYear}_${selectedCompany}.csv`}
    className="csv" // Add a className for styling
    target="_blank"
  >
    Genarate CSV
  </CSVLink>
  </div>
     </div>
      </div>

    </div>
  );
}
