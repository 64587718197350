
import React, { useState , useEffect} from 'react';import Cookies from 'js-cookie';
import axios from 'axios';
import './Upload.css';
import Sidebar from '../component/Sidebar'

export default function Uploademployeedetails() {
    const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
      const fetchEmployees = async () => {
        try {
          const token =Cookies.get('token');
          const res = await axios.get('https://backhris.talentfort.live/employees',{
            headers: {
              Authorization: token,
            },
          });
          setData(res.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchEmployees();
    }, []);
  
    const handleFileChange = (event) => {
      setFile(event.target.files[0]);
      setError(null);
      setSuccessMessage(null);
    };
  
    const handleUpload = async () => {
      setError(null);
      setSuccessMessage(null);
  
      if (!file) {
        window.alert('Please select a CSV file to upload.');
        return;
      }
  
      const confirmUpload = window.confirm('Are you sure you want to upload this file?');
  
      if (!confirmUpload) {
        setFile(null);
        return;
      }
  
  
      try {
        const formData = new FormData();
        formData.append('csvFile', file);
        const token =Cookies.get('token');
        const response = await axios.post('https://backhris.talentfort.live/upload/employees', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',Authorization: token,
          },
        });
  
        if (response.data.error === 'Data for this month and year already exists.') {
          window.alert('Data for this month and year already exists.');
        } else {
          setFile(null);
          window.alert('CSV file uploaded successfully.');
          window.location.reload();
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        window.alert('Error uploading file. Please try again later.');
      }
    };


  
    return (
      <div className='main-body'>
      <div>
      <Sidebar />
      </div>
  
      <div className="uploadcsv">
           <div className='top'>
        <h1>Employee Details Upload</h1>
        <input
          id="file-input"
          type="file"
          accept=".csv"
          onChange={handleFileChange}
          className="input" 
        />
         <button onClick={handleUpload} className="btn btn-primary"> 
        Upload
      </button>
        {error && <div className="alert">{error}</div>} 
        {successMessage && <div className="alert">{successMessage}</div>} 
        </div>
        <div>
        <table className="table">
            <thead>
              <tr>
                <th>Emp No</th>
                <th>Full Name</th>
                <th>Gender</th>
                <th>Residence Address</th>
                <th>NIC</th>
                <th>Contact No</th>
                <th>Joined Date</th>
                <th>Designation</th>
                
              </tr>
            </thead>
            <tbody>
              {data.map((employee) => (
                <tr className="Dts" key={employee.id}>
                  <td>{employee.empNo}</td>
                  <td>{employee.fullName}</td>
                  <td>{employee.gender}</td>
                  <td>{employee.currentAddress}</td>
                  <td>{employee.nic}</td>
                  <td>{employee.phone}</td>
                  <td>{employee.joinedDate}</td>
                  <td>{employee.designation}</td>
                  
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </div>
        </div>
    );
  }