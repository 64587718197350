import React, { useState } from "react";
import axios from "axios";
import "./TruncateTables.css";
import Sidebar from "../component/Sidebar";

const TruncateTables = () => {
  const [selectedTables, setSelectedTables] = useState([]);
  const [isTruncating, setIsTruncating] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const tables = [
    "employee_allowance_ayathitrust",
    "employee_allowance_edinborough",
    "employee_allowance_rdbcallcenter",
    "employee_allowance_sales",
    "employee_allowance_wbcallcenter",
    "employee_allowances",
    "hemas_drivers_payment",
    "hemas_fmcg_payment",
    "hemas_ma_payment",
    "hemas_maintern_payment",
    "hemas_mc_payment",
    "hemas_mcintern_payment",
    "hemas_spa_payment",
    "hemas_spaintern_payment",
    "hemas_sr_payment",
    "hemas_srintern_payment",
    // ... other tables
  ];

  const handleCheckboxChange = (table) => {
    setSelectedTables((prev) => {
      if (prev.includes(table)) {
        return prev.filter((t) => t !== table);
      } else {
        return [...prev, table];
      }
    });
  };

  const handleSubmit = async () => {
    setIsTruncating(true);
    try {
      const response = await axios.post(
        "https://backhris.talentfort.live/truncate-tables",
        {
          tables: selectedTables,
        }
      );
      console.log(response.data);
      alert("Tables truncated successfully!");
    } catch (error) {
      console.error("Error truncating tables:", error);
      alert("Error truncating tables.");
    }
    setIsTruncating(false);
    setShowModal(false);
  };

  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  return (
    <div>
      <Sidebar />
      <div className="truncate-tables-container"style={{ maxWidth: '600px', margin: '0 auto' }}>
        <h2>Clear Payroll Tables</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            openModal();
          }}
        >
          {tables.map((table, index) => (
            <div key={index}>
              <label>
                <input
                  type="checkbox"
                  value={table}
                  onChange={() => handleCheckboxChange(table)}
                />
                {table}
              </label>
            </div>
          ))}
          <button type="submit" disabled={isTruncating}>
          Clear Selected Tables
          </button>
        </form>
        {showModal && (
          <div className="modal">
            <div className="modal-contenttrunk">
              <h3>Are you sure you want to Clear the selected tables?</h3>
              <button
                onClick={handleSubmit}
                disabled={isTruncating}
                className="truncate-button"
              >
                Yes, Clear
              </button>
              <button
                onClick={closeModal}
                disabled={isTruncating}
                className="cancel-button"
              >
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TruncateTables;
