import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import './Updatedesignation.css';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';


export default function UpdateDesignation() {
  const [designationData, setDesignationData] = useState({
    desCode: '',
    desName: '',
    desDescription: '',
  });

  const location = useLocation();

  const designationId = location.pathname.split('/')[2];

  useEffect(() => {
    const fetchDesignation = async () => {
      try {
        const token =Cookies.get('token');
        const response = await axios.get(`https://backhris.talentfort.live/designations/${designationId}`,{
          headers: {
            Authorization: token,
          },
        });
        const { desCode, desName, desDescription } = response.data;
        setDesignationData({
          desCode,
          desName,
          desDescription,
        });
      } catch (error) {
        console.log(error);
      }
    };

    fetchDesignation();
  }, [designationId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDesignationData((prevDesignationData) => ({
      ...prevDesignationData,
      [name]: value,
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    try {
      const token =Cookies.get('token');
      await axios.put(`https://backhris.talentfort.live/designations/${designationId}`, designationData,{
        headers: {
          Authorization: token,
        },
      });
      alert('Designation updated successfully.');
      // Navigate to the previous page or handle redirection as needed
      window.history.back();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className='main-body'>
      <div>
        <Sidebar />
      </div>
      <div className="updatedesignations">
        <h1>Update Designation</h1>
        <form>
          <div className="mb-3">
            <label htmlFor="desCode" className="form-label">
              Designation Code
            </label>
            <input
              type='text'
              className="form-control"
              id="desCode"
              name="desCode"
              value={designationData.desCode}
              readOnly
            />
          </div>
          <div className="mb-3">
            <label htmlFor="desName" className="form-label">
              Designation Name
            </label>
            <input
              type="text"
              className="form-control"
              id="desName"
              name="desName"
              value={designationData.desName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="desDescription" className="form-label">
              Designation Description
            </label>
            <input
              type="text"
              className="form-control"
              id="desDescription"
              name="desDescription"
              value={designationData.desDescription}
              onChange={handleChange}
            />
          </div>

          <div className="buttonsupdatedesignation">
            <button
              type="submit"
              className="btn btn-primary update-button"
              onClick={handleClick}
            >
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
