import React, { useState } from 'react';
import { FaTachometerAlt, FaIndustry, FaUserCircle, FaUserTie, FaUserCog, FaUserFriends, FaUserEdit, FaFileAlt, FaBars, FaUsers, FaUserPlus, FaAngleDown, FaFileCsv, FaCogs, FaFileInvoiceDollar, FaTimesCircle, FaRegFile, FaRegFileAlt, FaRegClone } from "react-icons/fa";
import { NavLink, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import Cookies from 'js-cookie';
import { IoLogOutOutline, IoSettingsSharp } from "react-icons/io5";

const Sidebar = () => {
  const navigate = useNavigate();
  const [dropdownState, setDropdownState] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isUserOpen, setIsUserOpen] = useState(false);
  const [openDropdown, setOpenDropdown] = useState('');

  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? '' : dropdownName);
  };
  const toggle = () => setIsOpen(!isOpen);

  const menuItem = [
    {
      path: "/dash",
      name: "Dashboard",
      icon: <FaTachometerAlt />,
    },
    {
      name: "Custom Setting",
      icon: <FaCogs />,
      submenu: [
        { path: "/worklocations", name: "Work Locations", icon: <FaIndustry /> },
        { path: "/designation", name: "Designations", icon: <FaUserTie /> },
        { path: "/users", name: "Users", icon: <FaUserCog /> },
      ],
    },
    {
      name: "Employees",
      icon: <FaUsers />,
      submenu: [
        { path: "/addemployee", name: "Employee Quick Onboard", icon: <FaUserPlus /> },
        { path: "/uploademployeedetails", name: "Employee Details Upload", icon: <FaUserFriends /> },
        { path: "/employee", name: "Employee Details Update", icon: <FaUserEdit /> },
      ],
    },
    {
      name: "Direct",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrears", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadovertime", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploadotarrears", name: "OT Arrears", icon: <FaRegClone /> },
        { path: "/uploaddotallowance", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotarrears", name: "DOT Arrears", icon: <FaRegClone /> },
        { path: "/uploadextraworkingpayment", name: "Extra Working Payment", icon: <FaRegClone /> },
        { path: "/uploadactinallowance", name: "Actin Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransport", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformance", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadmotivation", name: "Motivation Allowance", icon: <FaRegClone /> },
        { path: "/uploadexperienceallowance", name: "Experience Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommission", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadcoveringupallowance", name: "Covering up Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvance", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvance", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadbdallowance", name: "BD Commission", icon: <FaRegClone /> },
        { path: "/uploadloans", name: "Loans", icon: <FaRegClone /> },
        { path: "/uploadadminallowance", name: "Admin Allowance", icon: <FaRegClone /> },
        { path: "/uploadmobileallowance", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadworkingdays", name: "Working Days", icon: <FaRegClone /> },
        { path: "/uploadotherallowance", name: "Other Allowance", icon: <FaRegClone /> },
        { path: "/uploadnopay", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancededuction", name: "Salary Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancededuction", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadloandeduction", name: "Loan Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetax", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadsalaryOverpayment", name: "Salary Overpayment", icon: <FaRegClone /> },
        { path: "/uploadotherdeduction", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/paymentdetails", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "RDB Call Center",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearsrdbcallcenter", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadovertimerdbcallcenter", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploadotarrearsrdbcallcenter", name: "OT Arrears", icon: <FaRegClone /> },
        { path: "/uploaddotallowancerdbcallcenter", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotarrearsrdbcallcenter", name: "DOT Arrears", icon: <FaRegClone /> },
        { path: "/uploadextraworkingpaymentrdbcallcenter", name: "Extra Working Payment", icon: <FaRegClone /> },
        { path: "/uploadactinallowancerdbcallcenter", name: "Actin Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransportrdbcallcenter", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformancerdbcallcenter", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadmotivationrdbcallcenter", name: "Motivation Allowance", icon: <FaRegClone /> },
        { path: "/uploadexperienceallowancerdbcallcenter", name: "Experience Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionrdbcallcenter", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadcoveringupallowancerdbcallcenter", name: "Covering up Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvancerdbcallcenter", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancerdbcallcenter", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadbdallowancerdbcallcenter", name: "BD Commission", icon: <FaRegClone /> },
        { path: "/uploadloansrdbcallcenter", name: "Loans", icon: <FaRegClone /> },
        { path: "/uploadadminallowancerdbcallcenter", name: "Admin Allowance", icon: <FaRegClone /> },
        { path: "/uploadmobileallowancerdbcallcenter", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadworkingdaysrdbcallcenter", name: "Working Days", icon: <FaRegClone /> },
        { path: "/uploadotherallowancerdbcallcenter", name: "Other Allowance", icon: <FaRegClone /> },
        { path: "/uploadnopayrdbcallcenter", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionrdbcallcenter", name: "Salary Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionrdbcallcenter", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadloandeductionrdbcallcenter", name: "Loan Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxrdbcallcenter", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadotherdeductionrdbcallcenter", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/paymentdetailsrdbcallcenter", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "WB Call Center",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearswbcallcenter", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadovertimewbcallcenter", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploadotarrearswbcallcenter", name: "OT Arrears", icon: <FaRegClone /> },
        { path: "/uploaddotallowancewbcallcenter", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotarrearswbcallcenter", name: "DOT Arrears", icon: <FaRegClone /> },
        { path: "/uploadextraworkingpaymentwbcallcenter", name: "Extra Working Payment", icon: <FaRegClone /> },
        { path: "/uploadactinallowancewbcallcenter", name: "Actin Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransportwbcallcenter", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformancewbcallcenter", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadmotivationwbcallcenter", name: "Motivation Allowance", icon: <FaRegClone /> },
        { path: "/uploadexperienceallowancewbcallcenter", name: "Experience Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionwbcallcenter", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadcoveringupallowancewbcallcenter", name: "Covering up Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvancewbcallcenter", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancewbcallcenter", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadbdallowancewbcallcenter", name: "BD Commission", icon: <FaRegClone /> },
        { path: "/uploadloanswbcallcenter", name: "Loans", icon: <FaRegClone /> },
        { path: "/uploadadminallowancewbcallcenter", name: "Admin Allowance", icon: <FaRegClone /> },
        { path: "/uploadmobileallowancewbcallcenter", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadworkingdayswbcallcenter", name: "Working Days", icon: <FaRegClone /> },
        { path: "/uploadotherallowancewbcallcenter", name: "Other Allowance", icon: <FaRegClone /> },
        { path: "/uploadnopaywbcallcenter", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionwbcallcenter", name: "Salary Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionwbcallcenter", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadloandeductionwbcallcenter", name: "Loan Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxwbcallcenter", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadotherdeductionwbcallcenter", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/paymentdetailswbcallcenter", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Edinborough",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearsedinborough", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadasalesincentiveedinborough", name: "Sales Incentive", icon: <FaRegClone /> },
        { path: "/uploadsalesdriveincentive1edinborough", name: "Sales Drive Incentive 1", icon: <FaRegClone /> },
        { path: "/uploadsalesdriveincentive2edinborough", name: "Sales Drive Incentive 2", icon: <FaRegClone /> },
        { path: "/uploadsalesdriveincentive3edinborough", name: "Sales Drive Incentive 3", icon: <FaRegClone /> },
        { path: "/uploadextraworkingpaymentedinborough", name: "Extra Working Payment", icon: <FaRegClone /> },
        { path: "/uploadtransportedinborough", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformanceedinborough", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadmotivationedinborough", name: "Motivation Allowance", icon: <FaRegClone /> },
        { path: "/uploadexperienceallowanceedinborough", name: "Experience Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionedinborough", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadadvanceedinborough", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvanceedinborough", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadworkingdaysedinborough", name: "Working Days", icon: <FaRegClone /> },
        { path: "/uploadotherallowanceedinborough", name: "Other Allowance", icon: <FaRegClone /> },
        { path: "/uploadnopayedinborough", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionedinborough", name: "Salary Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionedinborough", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadwalfareedinborough", name: "Welfare Deduction", icon: <FaRegClone /> },
        { path: "/uploadwelfarearrearsedinborough", name: "Welfare Arrears Deduction", icon: <FaRegClone /> },
        { path: "/uploadmobiledeductionedinborough", name: "Mobile Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxedinborough", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadotherdeductionedinborough", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/paymentdetailsedinborough", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Sales",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearssales", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionsales", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadmobileallowancesales", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadfuelallowancesales", name: "Fuel Allowance", icon: <FaRegClone /> },
        { path: "/uploadmngsupervisorallowancesales", name: "Mng. Supervisor Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransportsales", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformancesales", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadsupervisorcommissionsales", name: "Supervisor Commission", icon: <FaRegClone /> },
        { path: "/uploadsalacommissionsales", name: "SALA Commission", icon: <FaRegClone /> },
        { path: "/uploadklmcommissionsales", name: "KLM Commission", icon: <FaRegClone /> },
        { path: "/uploadmobitelcommissionsales", name: "Mobitel Commission", icon: <FaRegClone /> },
        { path: "/uploadpeotvremotecommissionsales", name: "Peo Tv Remotes Commission", icon: <FaRegClone /> },
        { path: "/uploadmemorytaxcommissionsales", name: "memoryTax Commission", icon: <FaRegClone /> },
        { path: "/uploadajcommissionsales", name: "AJ Commission", icon: <FaRegClone /> },
        { path: "/uploadcpearrearscollectingcommissionsales", name: "CPE Arrears Collecting Commission", icon: <FaRegClone /> },
        { path: "/uploadcpecollectingcommissionsales", name: "CPE Collecting Commission", icon: <FaRegClone /> },
        { path: "/uploadcommissionarrearssales", name: "Commission arrears", icon: <FaRegClone /> },
        { path: "/uploadstampdutysales", name: "Stamp Duty +", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancesales", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherallowancesales", name: "Other Allowance", icon: <FaRegClone /> },
        { path: "/uploadworkingdayssales", name: "Working Days", icon: <FaRegClone /> },
        { path: "/uploadnopaysales", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionsales", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadgroomingtrainingdeductionsales", name: "Grooming Training Deduction", icon: <FaRegClone /> },
        { path: "/uploadpreviousmonthtaxsales", name: "Previous Month TAX Decuction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxsales", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadotherdeductionsales", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/paymentsetailssales", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "AyathiTrust",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearsayathitrust", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadovertimeayathitrust", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploadotarrearsayathitrust", name: "OT Arrears", icon: <FaRegClone /> },
        { path: "/uploaddotallowanceayathitrust", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotarrearsayathitrust", name: "DOT Arrears", icon: <FaRegClone /> },
        { path: "/uploadextraworkingpaymentayathitrust", name: "Extra Working Payment", icon: <FaRegClone /> },
        { path: "/uploadactinallowanceayathitrust", name: "Actin Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransportayathitrust", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformanceayathitrust", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadmotivationayathitrust", name: "Motivation Allowance", icon: <FaRegClone /> },
        { path: "/uploadexperienceallowanceayathitrust", name: "Experience Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionayathitrust", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadcoveringupallowanceayathitrust", name: "Covering up Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvanceayathitrust", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvanceayathitrust", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadbdallowanceayathitrust", name: "BD Commission", icon: <FaRegClone /> },
        { path: "/uploadnopayayathitrust", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionayathitrust", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvanceadeductionyathitrust", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxayathitrust", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadworkingdaysayathitrust", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailsayathitrust", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas SR",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemassr", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadgoyapaymenthemassr", name: "Goya Payment", icon: <FaRegClone /> },
        { path: "/uploadfuelallowancehemassr", name: "Fuel Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemassr", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommisionhemassr", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursementhemassr", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "/uploadadvancehemassr", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemassr", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemassr", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadothradditionhemassr", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemassr", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemassr", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemassr", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemassr", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemassr", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemassr", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemassr", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas SR Intern",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemassrintern", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadinternallowancehemassrintern", name: "Intern Allowance", icon: <FaRegClone /> },
        { path: "/uploadinternallowancearrearshemassrintern", name: "Intern Allowance Arrears", icon: <FaRegClone /> },
        { path: "/uploadsalescommisionhemassrintern", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadsalescommissionarrearshemassrintern", name: "Sales Commission Arrears", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursement", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "uploadotherreimbursementarrearshemassrintern", name: "Other Reimbursement Arrears", icon: <FaRegClone /> },
        { path: "/uploadadvancehemassrintern", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemassrintern", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemassrintern", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemassrintern", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemassrintern", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemassrintern", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemassrintern", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemassrintern", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemassrintern", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemassrintern", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemassrintern", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas MA",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasma", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemasma", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasma", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadfoodallowancehemasma", name: "Food Allowance", icon: <FaRegClone /> },
        { path: "/uploadspecialallowancehemasma", name: "Special Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvancehemasma", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemasma", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasma", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasma", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasma", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemasma", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemasma", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasma", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasma", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasma", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasma", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas MA Intern",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasmaintern", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadinternallowancehemasmaintern", name: "Intern Allowance", icon: <FaRegClone /> },
        { path: "/uploadinternallowancearrearshemasmaintern", name: "Intern Allowance Arrears", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemasmaintern", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasmaintern", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadfoodallowancehemasmaintern", name: "Food Allowance", icon: <FaRegClone /> },
        { path: "/uploadspecialallowancehemasmaintern", name: "Special Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransportallowancearrearshemasmaintern", name: "Transport Allowance Arrears", icon: <FaRegClone /> },
        { path: "/uploadfoodallowancearrearshemasmaintern", name: "Food Allowance Arrears", icon: <FaRegClone /> },
        { path: "/uploadadvancehemasmaintern", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemasmaintern", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasmaintern", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasmaintern", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasmaintern", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemasmaintern", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemasmaintern", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasmaintern", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasmaintern", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasmaintern", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasmaintern", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas MC",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasmc", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadsalescommisionhemasmc", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursementmc", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemasmc", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasmc", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadspecialallowancehemasmc", name: "Special Allowance", icon: <FaRegClone /> },
        { path: "/uploadattendenceallowancehemasmc", name: "Attendance Allowance", icon: <FaRegClone /> },
        { path: "/uploadholidaypaymenthemasmc", name: "Holiday Payment", icon: <FaRegClone /> },
        { path: "/uploadadvancehemasmc", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemasmc", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasmc", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasmc", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasmc", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemasmc", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemasmc", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasmc", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasmc", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasmc", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasmc", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas MC Intern",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasmcintern", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadinternallowancehemasmcintern", name: "Intern Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalescommisionhemasmcintern", name: "Sales Commission", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursement", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "/uploadattendenceallowancehemasmcintern", name: "Attendance Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformmanceallownacehemasmcintern", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasmcintern", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadtransport2hemasmcintern", name: "Transport Allowance II", icon: <FaRegClone /> },
        { path: "/uploadholidaypaymenthemasmcintern", name: "Holiday Payment", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasmcintern", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasmcintern", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasmcintern", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasmcintern", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasmcintern", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasmcintern", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasmcintern", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas SPA",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasspa", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursementhemasspa", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemasspa", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowance2hemasspa", name: "Performance Allowance II", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasspa", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadspecialallowancehemasspa", name: "Special Allowance", icon: <FaRegClone /> },
        { path: "/uploadattendenceallowancehemasspa", name: "Attendance Allowance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasspa", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasspa", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasspa", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasspa", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasspa", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasspa", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasspa", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas SPA Intern",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadsalaryarrearshemasspaintern", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploadinternallowancehemasspaintern", name: "Intern Allowance", icon: <FaRegClone /> },
        { path: "/uploadotherreimbursementhemasspaintern", name: "Other Reimbursement", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowancehemasspaintern", name: "Performance Allowance", icon: <FaRegClone /> },
        { path: "/uploadperformanceallowance2hemasspaintern", name: "Performance Allowance II", icon: <FaRegClone /> },
        { path: "/uploadtransporthemasspaintern", name: "Transport Allowance", icon: <FaRegClone /> },
        { path: "/uploadspecialallowancehemasspaintern", name: "Special Allowance", icon: <FaRegClone /> },
        { path: "/uploadattendenceallowancehemasspaintern", name: "Attendance Allowance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasspaintern", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasspaintern", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasspaintern", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasspaintern", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasspaintern", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasspaintern", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasspaintern", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas FMCG",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadotallowancehemasfmcg", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotallowancehemasfmcg", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploadsalaryarrearshemasfmcg", name: "Salary Arrears", icon: <FaRegClone /> },
        { path: "/uploaddataallowancehemasfmcg", name: "Data Allowance", icon: <FaRegClone /> },
        { path: "/uploadmobileallowancehemasfmcg", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasfmcg", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasfmcg", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasfmcg", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasfmcg", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasfmcg", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasfmcg", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasfmcg", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Hemas Drivers",
      icon: <FaFileInvoiceDollar />,
      submenu: [
        { path: "/uploadovertimehemasdriver", name: "OT Allowance", icon: <FaRegClone /> },
        { path: "/uploaddotallowancehemasdriver", name: "DOT Allowance", icon: <FaRegClone /> },
        { path: "/uploadfullbataallowancehemasdriver", name: "Full Bata Allowance", icon: <FaRegClone /> },
        { path: "/uploadnightallowancehemasdriver", name: "Night Allowance", icon: <FaRegClone /> },
        { path: "/uploadmobileallowancehemasdriver", name: "Mobile Allowance", icon: <FaRegClone /> },
        { path: "/uploadadvancehemasdriver", name: "Salary Advance", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancehemasdriver", name: "Festival Advance", icon: <FaRegClone /> },
        { path: "/uploadotherarrearshemasdriver", name: "Other Arrears", icon: <FaRegClone /> },
        { path: "/uploadotheradditionhemasdriver", name: "Other Addition", icon: <FaRegClone /> },
        { path: "/uploadnopayhemasdriver", name: "Nopay Deduction", icon: <FaRegClone /> },
        { path: "/uploadadvancedeductionhemasdriver", name: "Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadfestivaladvancedeductionhemasdriver", name: "Festival Advance Deduction", icon: <FaRegClone /> },
        { path: "/uploadpayeetaxhemasdriver", name: "Income Tax", icon: <FaRegClone /> },
        { path: "/uploadothrdeductionhemasdriver", name: "Other Deduction", icon: <FaRegClone /> },
        { path: "/uploadworkingdayshemasdriver", name: "Working Days", icon: <FaRegClone /> },
        { path: "/paymentdetailshemasdriver", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Terminate Inprogress",
      icon: <FaRegFile />,
      submenu: [
        { path: "/uploadterminatecsv", name: "Upload Payment Details", icon: <FaRegClone /> },
        { path: "/paymentdetailsterminate", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Intermediate Payments",
      icon: <FaRegFileAlt />,
      submenu: [
        { path: "/uploadimmediatecsv", name: "Upload Payment Details", icon: <FaRegClone /> },
        { path: "/paymentdetailsintermediate", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Payment Close",
      icon: <FaTimesCircle />,
      submenu: [
        { path: "/closepaymentdetails", name: "Payment Details", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Reports",
      icon: <FaFileAlt />,
      submenu: [
        { path: "/employeereport", name: "Employees Report", icon: <FaRegClone /> },
        { path: "/paymentreport", name: "Monthly Payment Report", icon: <FaRegClone /> },
        { path: "/employeepaymentreport", name: "Employee Payslip Report", icon: <FaRegClone /> },
        { path: "/employeecformreport", name: "C Form Report", icon: <FaRegClone /> },
        { path: "/bankslip", name: "Bank slip Report", icon: <FaRegClone /> },
        { path: "/bcardreport", name: "B card Report", icon: <FaRegClone /> },
        { path: "/dependentreport", name: "Dependent Report", icon: <FaRegClone /> },
        { path: "/bankdetailsreport", name: "Bank Details Report", icon: <FaRegClone /> },
        { path: "/epfreport", name: "EPF Report", icon: <FaRegClone /> },
        { path: "/attendence", name: "Attendance Report", icon: <FaRegClone /> },
      ],
    },
    {
      name: "CSV Template",
      icon: <FaFileCsv />,
      submenu: [
        { path: "/terminatecsvtemplate", name: "Terminate process", icon: <FaRegClone /> },
        { path: "/immediatecsvtemplate", name: "Intermediate process", icon: <FaRegClone /> },
        { path: "/allowancecsvtemplate", name: "Allowances", icon: <FaRegClone /> },
        { path: "/employeecsvtemplate", name: "Employees", icon: <FaRegClone /> },
      ],
    },
    {
      name: "Pay Roll Changes",
      icon: <FaFileCsv />,
      submenu: [
        { path: "/clearpayroll", name: "Clear Payroll", icon: <FaRegClone /> },
        { path: "/terminatecsvtemplate", name: "Add Payroll", icon: <FaRegClone /> },
      ],
    },
  ];
  const clearCookie = (name) => {
    Cookies.remove(name);
  };

  const handleLogout = () => {
    const shouldLogout = window.confirm("Are you sure you want to logout?");
    if (shouldLogout) {
      clearCookie("token");
      clearCookie("role");
      clearCookie("username");
      window.location.href = "/";
    }
  };

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div
      className="sidebar"
      style={{ width: isOpen ? "235px" : "50px" }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="top_section">
        <h4 style={{ display: isOpen ? "block" : "none" }} className="logo">
          TALENTFORT
        </h4>
        <div
          style={{ marginLeft: isOpen ? "52px" : "3px" }}
          className="bars"
        >
          <FaBars onClick={toggle} />
        </div>
      </div>

      <div className="bottom_section">
        {menuItem.map((item, index) => (
          <div key={index} className="menu_item">
            {item.submenu ? (
              <div className="dropdown">
                <div
                  className={`link ${isOpen && "pointer"}`}
                  onClick={() =>
                    setDropdownState((prev) => ({
                      ...prev,
                      [item.name]: !prev[item.name],
                    }))
                  }
                >
                  <div className="icon1">{item.icon}</div>
                  <div
                    className="link_text"
                    style={{ display: isOpen ? "block" : "none" }}
                  >
                    {item.name}
                  </div>
                  <div
                    className="icon2"
                    style={{ marginRight: "10px", justifyContent: "flex-end" }}
                  >
                    <FaAngleDown />
                  </div>
                </div>
                {isOpen && dropdownState[item.name] && (
                  <div className="dropdown-content">
                    {item.submenu.map((submenuItem, submenuIndex) => (
                      <NavLink
                        key={submenuIndex}
                        to={submenuItem.path}
                        className="submenu-link"
                      >
                        <div
                          className="submenu_link_text"
                          style={{
                            display: "flex",
                            marginLeft: "10px",
                          }}
                        >
                          <div className="icon3">{submenuItem.icon}</div>
                          <div className="itemname">{submenuItem.name}</div>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <NavLink
                to={item.path}
                className={`link ${isOpen && "pointer"}`}
              >
                <div className="icon1">{item.icon}</div>
                <div
                  className="link_text"
                  style={{ display: isOpen ? "block" : "none" }}
                >
                  {item.name}
                </div>
              </NavLink>
            )}
          </div>
        ))}
      </div>
      <div className="logout_section">
        <div style={{ display: "flex" }}>
          <div
            className="adminicon"
            style={{
              display: isOpen ? "flex" : "block",
              alignItems: "center",
              marginLeft: isOpen ? "8px" : "5px",
              fontSize: isOpen ? "36px" : "26px",
              marginBottom: "4px",
            }}
          >
            <FaUserCircle style={{ marginRight: "8px" }} />
          </div>
          <div>
            <p
              className="user"
              style={{
                display: isOpen ? "flex" : "none",
                fontSize: "12px",
                marginRight: "0px",
                marginBottom: "0.1px",
                marginTop: "14px",
              }}
            >
              {Cookies.get("username")}
            </p>
            <p
              className="user"
              style={{
                display: isOpen ? "flex" : "none",
                fontSize: "14px",
                marginRight: "0px",
                marginTop: "0.1px",
              }}
            >
              {Cookies.get("role")}
            </p>
          </div>
          <div
            style={{
              marginTop: isOpen ? "22px" : "20px",
              marginLeft: isOpen ? "32px" : "0px",
              fontSize: isOpen ? "16px" : "1px",
            }}
          >
            <FaAngleDown onClick={() => setIsUserOpen(!isUserOpen)} />
          </div>
        </div>

        {isOpen && isUserOpen && (
          <div className="dropdown-content" style={{ marginBottom: "10px" }}>
            <div
              className="dropdown-item"
              onClick={() => navigate("/passwordreset")}
            >
              <IoSettingsSharp
                style={{ marginRight: "3px", marginTop: "3px" }}
              />
              <span style={{ marginLeft: "1px" }}>Password Reset</span>
            </div>
            <div className="dropdown-item" onClick={handleLogout}>
              <IoLogOutOutline
                style={{ marginRight: "3px", marginTop: "3px" }}
              />
              <span style={{ marginLeft: "1px" }}>Logout</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;