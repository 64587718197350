import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import Sidebar from '../component/Sidebar';
import { CSVLink } from 'react-csv';
import './csv.css';

export default function Csv() {
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');

  useEffect(() => {
    fetchPayments();
  }, []);

  const fetchPayments = async () => {
    try {
      const token = Cookies.get('token');
      const res = await axios.get('https://backhris.talentfort.live/employees', {
        headers: {
          Authorization: token,
        },
      });
      
      const uniqueCompanyNames = Array.from(new Set(res.data.map(payment => payment.workLocation)));
      setCompanyNames(uniqueCompanyNames);
    } catch (error) {
      console.log('Error fetching payments:', error);  
    }
  };
  const prepareCSVData = () => {
    // Define headers specific to each company along with their corresponding backend API values
    const Header = [
      'EmpNo',
      'EPFNo',
      'Title',
      'FullName',
      'DisplayName',
      'Designation',
      'JoinDate',
      'EmployeeType',
      'ContractStartDate',
      'ContractEndDate',
      'Gender',
      'DOB',
      'EmailAddress',
      'WorkLocation',
      'Location',
      'Salary',
      'BudAllowance',
      'FixAllowance',
      'GrossSalary',
      'NIC',
      'ContactNo',
      'Address',
      'BankName',
      'BankCode',
      'BranchName',
      'BranchCode',
      'AccountNo',
    ];

        return [Header];
  };

  return (
    <div className='main-body'>
      <Sidebar />
      <div className="uploadcsv">
        <div className="top">
          <h1>Upload Employee Details CSV Template</h1>
        </div>
        <div className="row mb-3">
            <div className="col-md-6">
            <select value={selectedCompany} className="form-select" onChange={(e) => setSelectedCompany(e.target.value)}>
              <option value="">Select Company</option>
              {companyNames.map((companyName, index) => (
                <option key={index} value={companyName}>{companyName}</option>
              ))}
            </select>
            </div>
            <div className="col-md-4">
            <CSVLink
              data={prepareCSVData()} 
              filename={`Employee_data_upload_${selectedCompany}.csv`}
              className="csv1"
              target="_blank"
            >
              CSV Template
            </CSVLink>
            </div>
          </div>
        </div>
        </div>
  );
              }