import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import 'jspdf-autotable';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './Employeereport.css';
import Select from 'react-select';
import Sidebar from '../component/Sidebar';
import Cookies from 'js-cookie';

export default function Employeereport() {
  const [searchResults, setSearchResults] = useState([]);
  const [active, setActive] = useState([]);
  const [designations, setDesignation] = useState([]);
  const [selectedActive, setSelectedActive] = useState('');
  const [selectedDesignation, setSelectedDesignation] = useState ('');
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState('');
  const [empnos, setEmpnos] = useState([]);
  const [selectedEmployeeNo, setSelectedEmployeeNo] = useState('');

   

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const token =Cookies.get('token');
        const res = await axios.get('https://backhris.talentfort.live/employees',{
          headers: {
            Authorization: token,
          },
        });
        setSearchResults(res.data);
        const active = new Set();

        const designationSet = new Set();
        const monthSet = new Set();
        const yearSet = new Set();
        const companySet =new Set();
        const empnoset = new Set();

        res.data.forEach((employee) => {
          const joinedDate = new Date(employee.joinedDate);
          const monthName = joinedDate.toLocaleString('default', { month: 'long' });
          const year = joinedDate.getFullYear().toString();

          active.add(employee.employeeActive);
          designationSet.add(employee.designation);
          monthSet.add(monthName);
          yearSet.add(year);
          companySet.add(employee.workLocation);
          empnoset.add(employee.empNo);
        });
        const sortedEmpnos = Array.from(empnoset).sort((a, b) => {
          // Check if both `a` and `b` are numbers (employee numbers)
          if (!isNaN(a) && !isNaN(b)) {
            return a - b; // Sort numbers numerically
          }
          return a.localeCompare(b); // Sort strings alphabetically
        });

        setEmpnos(
          sortedEmpnos.map((empno) => ({ value: empno, label: empno }))
        );
        setActive(Array.from(active));

        setDesignation(Array.from(designationSet));
        setCompanyNames(Array.from(companySet));
        const monthsOrder = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        setMonths(Array.from(monthSet).sort((a, b) => {
          return monthsOrder.indexOf(a) - monthsOrder.indexOf(b);
        }));
        setYears(Array.from(yearSet));
         
      } catch (error) {
        console.log(error);
      }
    };
    fetchEmployees();
  }, []);

  const handleFilter = async () => {
    try {
      const token =Cookies.get('token');
      const response = await axios.get('https://backhris.talentfort.live/employees',{
        headers: {
          Authorization: token,
        },
      });
      const employees = response.data;

      let filteredResults = employees;

      if (selectedMonth) {
        filteredResults = filteredResults.filter(
          (employee) => new Date(employee.joinedDate).toLocaleString('default', { month: 'long' }) === selectedMonth
        );
      }

      if (selectedYear) {
        filteredResults = filteredResults.filter(
          (employee) => new Date(employee.joinedDate).getFullYear().toString() === selectedYear
        );
      }

      if (selectedActive) {
        filteredResults = filteredResults.filter(
          (employee) => employee.employeeActive === selectedActive
        );
      }
      if(selectedDesignation) {
        filteredResults = filteredResults.filter(
          (employee) => employee.designation === selectedDesignation
        )
      }
      if(selectedCompany) {
        filteredResults = filteredResults.filter(
          (employee) => employee.workLocation === selectedCompany
        )
      }
      if (selectedEmployeeNo) {
        filteredResults = filteredResults.filter(
          (employee) => employee.empNo === selectedEmployeeNo
        );
      }

      setSearchResults(filteredResults);
    } catch (error) {
      console.error('Error retrieving employee data:', error);
    }
  };

  const handleMonthFilter = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleYearFilter = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleactiveFilter = (event) => {
    setSelectedActive(event.target.value);
  };

const handleDesignationFilter = (event) => {
  setSelectedDesignation(event.target.value)
}

const handleCompanyFilter = (event) => {
  setSelectedCompany(event.target.value)
}
const handleEmployeeNoFilter = (selectedOption) => {
  setSelectedEmployeeNo(selectedOption ? selectedOption.value : '');
};


const prepareCSVData = () => {
  const csvData = searchResults.map((employee) => [
    employee.empNo,
    employee.epfNo,
    employee.title,
    employee.displayName,
    employee.fullName,
    employee.designation,
    employee.joinedDate,
    employee.employeeType,
    employee.contractstartDate,
    employee.contractendDate,
    employee.workLocation,
    employee.salary,
    employee.nic,
    employee.gender,
    employee.dob,
    employee.email,
    employee.phone,
    employee.currentAddress,
    employee.accountNo,


  ]);
 // Add the header row for the CSV data
 const headers = [
  'Emp No',
  'Epf No',
  'Title',
  'Display Name',
  'Full Name',
  'Designation',
  'Joined Date',
  'Employee Type',
  'Contarct Start Date',
  'Contract End Date',
  'Work Location',
  'Basic Salary',
  'NIC',
  'Gender',
  'DOB',
  'Email',
  'Contact No',
  'Address',
  'Account No',
];
const emptyRow = Array(headers.length).fill('');

return [headers,emptyRow, ...csvData];// Concatenate headers with the data rows
};


  return (
    <div className='main-body'>
    <div>
    <Sidebar />
    </div>
      <div className="report">
        <h1>Employee Report</h1>
<div className='filter_section'>
<div className="row mb-3 ">
          <div className="col-md-4">
              <label htmlFor="companyFilter">Work Location:</label>
              <select
                id="companyFilter"
                value={selectedCompany}
                className="form-select"
                onChange={handleCompanyFilter}
                >
                <option value="">All</option>
                {companyNames.map((company) =>
                  <option key={company} value={company}>
                    {company}
                  </option>
                  )}
                  </select>
                  </div>

          <div className="col-md-4">
              <label htmlFor="activeFilter">Active/Inactive:</label>
              <select
                id="activeFilter"
                value={selectedActive}
                className="form-select"
                onChange={handleactiveFilter}
              >
                <option value="">All</option>
                {active.map((department) => (
                  <option key={department} value={department}>
                    {department}
                  </option>
                ))}
              </select>
            </div>
              <div className="col-md-4">
              <label htmlFor="designationFilter">Designation:</label>
              <select
                id="designationFilter"
                value={selectedDesignation}
                className="form-select"
                onChange={handleDesignationFilter}
              >
                <option value="">All</option>
                {designations.map((designation) => (
                  <option key={designation} value={designation}>
                    {designation}
                  </option>
                ))}
              </select>
            </div>
            </div>
            <div className="row mb-3">
            <div className="col-md-4">
              <div className="month-filter">
                <label htmlFor="monthFilter">Filter by Joined Month:</label>
                <select
                  id="monthFilter"
                  value={selectedMonth}
                  className="form-select"
                  onChange={handleMonthFilter}
                >
                  <option value="">All</option>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-4">
              <div className="year-filter">
                <label htmlFor="yearFilter">Filter by Joined Year:</label>
                <select
                  id="yearFilter"
                  value={selectedYear}
                  className="form-select"
                  onChange={handleYearFilter}
                >
                  <option value="">All</option>
                  {years.map((year, index) => (
                    <option key={index} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-4'>
              <label htmlFor='employeeNoFilter'>Employee No:</label>
              <Select
                id='empNo'
                value={empnos.find(
                  (option) => option.value === selectedEmployeeNo
                )}
                options={empnos}
                isClearable
                onChange={handleEmployeeNoFilter}
                placeholder='Select or type Employee No'
                isSearchable
              />
            </div>
            </div>
        
       

        <button className='filter' onClick={handleFilter}>Apply Filters</button>
</div>
        

        <div>
          <table className="table">
            <thead>
              <tr>
              <th>Emp No</th>
              <th>Epf No</th>
              <th>Title</th>
                <th>Display Name</th>
                <th>Full Name</th>
                <th>Designation</th>
                <th>Joined Date</th>
                <th>Employee Type</th>
                <th>Contract Start Date</th>
                <th>Contract End Date</th>
                <th>Work Location</th>
                <th>Basic Salary</th>
                <th>Bud.Allowance</th>
                <th>Gross Salary</th>
                <th>NIC</th>
                <th>Gender</th>
                <th>DOB</th>
                <th>Email</th>
                <th>Contact No</th>
                <th>Address</th>
                <th>Bank Name</th>
                <th>Bank Code</th>
                <th>Branch Name</th>
                <th>Branch Code</th>
                <th>Account No</th>

               
               
              </tr>
            </thead>
            <tbody>
              {searchResults.map((employee) => (
                <tr className="Dts" key={employee.id}>
                  <td>{employee.empNo}</td>
                  <td>{employee.epfNo}</td>
                  <td>{employee.title}</td>
                  <td>{employee.displayName}</td>
                  <td>{employee.fullName}</td>
                  <td>{employee.designation}</td>
                  <td>{employee.joinedDate}</td>
                  <td>{employee.employeeType}</td>
                  <td>{employee.contractstartDate}</td>
                  <td>{employee.contractendDate}</td>
                  <td>{employee.workLocation}</td>
                  <td>{employee.salary}</td>
                  <td>{employee.payGrade}</td>
                  <td>{employee.grossSalary}</td>
                  <td>{employee.nic}</td>
                  <td>{employee.gender}</td>
                  <td>{employee.dob}</td>
                  <td>{employee.email}</td>
                  <td>{employee.phone}</td>
                  <td>{employee.currentAddress}</td>
                  <td>{employee.bankName}</td>
                  <td>{employee.bankCode}</td>
                  <td>{employee.branchName}</td>
                  <td>{employee.branchCode}</td>
                  <td>{employee.accountNo}</td>



                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className='repos'>
        <div>
  <CSVLink
    data={prepareCSVData()} // Call prepareCSVData to get the formatted data
    filename={`Employee_Details_${selectedCompany}_${selectedActive}.csv`}
    className="csv" // Add a className for styling
    target="_blank"
  >
    Genarate CSV
  </CSVLink>
  </div>
     </div>

        
      </div>
      
      </div>
  );
}
